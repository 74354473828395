import React from "react";
import { connect } from "react-redux";

import {
  markVideoWatched,
  setSolved,
  toggleIntroTutorial,
} from "../../../actions/progress";
import { postDataSecure } from "../../../api";

import Tutorial from "../../tutorials/Tutorial";
import SlideShow from "../../exercises/SlideShow";
import StartButton from "./StartButton";
import caseStudies from "../../../casestudies";

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Intro = ({
  caseStudy,
  title,
  introAsset,
  markVideoWatched,
  setSolved,
  toggleTutorial,
}) => {
  const IntroText = () => (
    <section>
      <div id="introText">
        <h1>Introduction:</h1>
        <h2>{title}</h2>
        <button className="btn-link" id="tutorial" onClick={toggleTutorial}>
          <div className="btnIconLeft">
            <svg width="16px" height="16px">
              <path
                className="btnIconLeft"
                d="M7.2,12.8H8.8V11.2H7.2ZM8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.4A6.4,6.4,0,1,1,14.4,8,6.41,6.41,0,0,1,8,14.4ZM8,3.2A3.2,3.2,0,0,0,4.8,6.4H6.4a1.6,1.6,0,1,1,3.2,0c0,1.6-2.4,1.4-2.4,4H8.8c0-1.8,2.4-2,2.4-4A3.2,3.2,0,0,0,8,3.2Z"
              />
            </svg>
          </div>
          Tutorial
        </button>
        <p>
          View the injury case scenario below to find out what happened to{" "}
          {capitalize(caseStudy)}. The patient folder will update with
          observations and notes, so be sure to check it from time to time!
        </p>
      </div>
    </section>
  );

  const handlePlay = () => {
    markVideoWatched(caseStudy);
    setSolved(caseStudy, 1);
  };

  const handleSlideShowFinish = () => {
    markVideoWatched(caseStudy);
    setSolved(caseStudy, 1);
  };

  const Presentation = () => {
    if (introAsset.type === "video") {
      return (
        <section>
          <div id="introVid">
            <video onPlay={handlePlay} className="introVidTag" controls>
              <source src={introAsset.vidSrc} type="video/mp4" />
              <track
                default
                src={introAsset.captionSrc}
                label="English"
                kind="captions"
                srcLang="en-us"
              />
              Your browser does not support the video tag.
            </video>
            <StartButton />
          </div>
        </section>
      );
    } else if (introAsset.type === "slideshow") {
      return (
        <section>
          <div id="introSlideShow">
            <SlideShow
              onFinish={handleSlideShowFinish}
              slides={introAsset.slides}
            />
            <StartButton />
          </div>
        </section>
      );
    }
  };

  return (
    <main>
      <Tutorial />
      <div className="contentIntro">
        <IntroText />
        <Presentation />
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  const { caseStudy } = state.navigation;
  const { studentNumber, token } = state.authentication;
  return {
    caseStudy,
    title: caseStudies[caseStudy].title,
    introAsset: caseStudies[caseStudy].introAsset,
    studentNumber,
    token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  markVideoWatched: (caseStudy) => {
    dispatch(markVideoWatched(caseStudy));
  },
  setSolved: (caseStudy, page) => {
    dispatch(setSolved(caseStudy, page));
  },
  toggleTutorial: () => {
    dispatch(toggleIntroTutorial());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  setSolved: (caseStudy, page) => {
    let body = new FormData();
    body.append(
      "exercise",
      JSON.stringify({
        Name: stateProps.caseStudy,
        Solved: page,
        VideoSeen: true,
      })
    );
    postDataSecure(
      `exercises/${stateProps.studentNumber}`,
      body,
      stateProps.token
    );

    dispatchProps.setSolved(caseStudy, page);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Intro);

