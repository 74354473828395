import React, { Component } from 'react';

let iteratingNumberDraggable = 0;
let iteratingNumberContainer = 0;

export const draggable = (component, id) => {
    const uniqueNumber = ++iteratingNumberDraggable;
    const uniqueID = "draggable" + uniqueNumber;
    return <div id={id || uniqueID} key={id || uniqueID} className="draggable noselect">
        {component}
    </div>
}

export const dragContainer = (component, id) => {
    const uniqueNumber = ++iteratingNumberContainer;
    const uniqueID = "dragcontainer" + uniqueNumber;
    return <div id={id || uniqueID} className="dragcontainer noselect">
        {component}
    </div>
}

export class DragArea extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentlyDragged: null,
            currentlyHoveredContainer: null,
            previousContainer: null,
            isDragging: false,
            draggedX: 0,
            draggedY: 0,
        }
        this.shouldUpdateObjects = false;
        this.draggableObjects = [];
        this.containerObjects = [];

        this.getDraggableAndContainerComponents = this.getDraggableAndContainerComponents.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.endDragging = this.endDragging.bind(this);
        this.getDraggableObjectAtPosition = this.getDraggableObjectAtPosition.bind(this);
        this.getContainerObjectAtPosition = this.getContainerObjectAtPosition.bind(this);
        this.renderDraggedComponent = this.renderDraggedComponent.bind(this);

    }

    componentDidMount(){
        this.updateObjects();
    }

    componentDidUpdate(){
        if (!this.state.isDragging){
            this.updateObjects();
        }
    }

    updateObjects(){
        let draggableObjects = [];
        let containerObjects = [];
        const { draggableComponents, containerComponents } = this.getDraggableAndContainerComponents();
        for(let i = 0; i < draggableComponents.length; i++){
            const component = draggableComponents[i];
            const element = document.getElementById(component.props.id);
            const boundingBox = element.getBoundingClientRect();
            const draggableObject = { component, boundingBox };
            draggableObjects.push(draggableObject);            
        }
        for(let i = 0; i < containerComponents.length; i++){
            const component = containerComponents[i];
            const element = document.getElementById(component.props.id);
            const boundingBox = element.getBoundingClientRect();
            const containerObject = { component, boundingBox };
            containerObjects.push(containerObject);            
        }
        this.draggableObjects = draggableObjects;
        this.containerObjects = containerObjects;
    }

    getDraggableAndContainerComponents(){
        let stack = [];
        pushToStack(stack, this.props.children);
        let foundDraggableComponents = [];
        let foundContainerComponents = [];
        while(stack.length > 0){
            const currentComponent = stack.pop();
            if(hasClassName(currentComponent, "draggable")){
                foundDraggableComponents.push(currentComponent);
            } 
            if(hasClassName(currentComponent, "dragcontainer")){
                foundContainerComponents.push(currentComponent);
            }
            if(hasChildren(currentComponent)){
                const { children } = currentComponent.props;
                pushToStack(stack, children);
            }       
        }
        return { 
            draggableComponents:foundDraggableComponents, 
            containerComponents:foundContainerComponents 
        };
    }

    handleMouseDown(e){
        this.updateObjects();
        const x = e.clientX;
        const y = e.clientY;
        const draggableObject = this.getDraggableObjectAtPosition(x, y);
        const containerObject = this.getContainerObjectAtPosition(x, y);
        if(draggableObject){
            setStylePropertyByID(draggableObject.component.props.id, "visibility", "hidden");
            this.setState({
                isDragging: true,
                currentlyDragged: draggableObject,
                previousContainer: containerObject,
                draggedX: x,
                draggedY: y,
            })
        };
    }

    handleMouseMove(e){
        if(this.state.isDragging){
            const hoveredContainer = this.getContainerObjectAtPosition(e.clientX, e.clientY);
            let hoveredContainerID = "";
            if(hoveredContainer) hoveredContainerID = hoveredContainer.component.props.id;
            if(hoveredContainerID !== this.state.currentlyHoveredContainer){
                this.props.onHover(hoveredContainerID);
            }
            this.setState({
                draggedX: e.clientX,
                draggedY: e.clientY,
                currentlyHoveredContainer: hoveredContainerID,
            })
        }
    }

    handleMouseUp(e){
        if(!this.state.isDragging) return;
        const x = e.clientX;
        const y = e.clientY;
        const containerObject = this.getContainerObjectAtPosition(x, y);
        if(containerObject){
            const currentlyDraggingID = this.state.currentlyDragged.component.props.id
            const previousContainerID = this.state.previousContainer.component.props.id
            const containerID =  containerObject.component.props.id;
            this.props.onDragDrop(currentlyDraggingID, previousContainerID, containerID);
            this.shouldUpdateObjects = true;
        }
        this.endDragging();
    }

    handleMouseLeave(e){
        if(this.state.isDragging)
            this.endDragging();
    }

    endDragging(){
        const { currentlyDragged } = this.state;
        if(this.state.isDragging){
            setStylePropertyByID(currentlyDragged.component.props.id, "visibility", "visible");
            this.setState({
                currentlyDragged: null,
                isDragging: false,
            })
        }
    }

    getDraggableObjectAtPosition(x, y){
        for(let i = 0; i < this.draggableObjects.length; i++){
            const object = this.draggableObjects[i];
            if(pointIsInsideBox(x, y, object.boundingBox)){
                return object;
            }
        }
        return null;
    }

    getContainerObjectAtPosition(x, y){
        for(let i = 0; i < this.containerObjects.length; i++){
            const object = this.containerObjects[i];
            if(pointIsInsideBox(x, y, object.boundingBox)){
                return object;
            }
        }
        return null;
    }

    renderDraggedComponent(){
        const {boundingBox} = this.state.currentlyDragged;
        const xOffset = boundingBox.width / 2;
        const yOffset = boundingBox.height / 2;
        return(
            <div
            style={{
                position:'absolute',
                top: this.state.draggedY - yOffset,
                left: this.state.draggedX - xOffset,
                width: boundingBox.width,
                height: boundingBox.height,
            }}
            >
                {this.state.currentlyDragged.component}
                
            </div>
        );
    }

    render(){
        return(
            <div 
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
            onMouseLeave={this.handleMouseLeave}
            ref={id=>{this.ref = id}}
            >
                {this.props.children}
                {this.state.isDragging && this.renderDraggedComponent()}
            </div>
        )
    }
}

const hasClassName = (component, classname) => {
    return ( 
        component &&
        component.props &&
        component.props.className &&
        component.props.className.search(classname) !== -1
    );
}

const hasChildren = (component) => {
    return(
        component &&
        component.props &&
        component.props.children
    );
}

const pushToStack = (stack, object) => {
    if(Array.isArray(object))
        stack.push(...object);
    else
        stack.push(object)
}

const pointIsInsideBox = (x, y, boundingBox) => {
    return (
        x > boundingBox.left &&
        y > boundingBox.top &&
        x < boundingBox.right &&
        y < boundingBox.bottom
    );
}

const setStylePropertyByID= (id, property, value) =>{
    const element = document.getElementById(id);
    element.style[property] = value
}

