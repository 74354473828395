import React,{ Component} from 'react';

import Lottie from './Lottie';

class AutoAnimation extends Component{

  render() {
 
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
        path : this.props.path //'../bodymovin/bernie/bernie-ex3.json'
      };

    return (
            <div>
                <Lottie 
                    options={defaultOptions} 
                />
            </div>
    );
  }
}

export default AutoAnimation;