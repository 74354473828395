import { 
    CORRECT_ANSWER, 
    INCORRECT_ANSWER, 
    START_GUESSING, 
    UPDATE_SEL_COUNT,
    SHOW_TUTORIAL,
    HIDE_TUTORIAL,
    SHOW_SCENARIO_OVERLAY,
    HIDE_SCENARIO_OVERLAY,
    SHOW_REKINNECT,
    HIDE_REKINNECT,
    SET_CLEAR_FUNCTION,
} from '../actions/exercise';

import {
    SET_PAGE,
    GOTO_NEXT_PAGE,
    GOTO_PREV_PAGE,
    SET_CASE_STUDY,
} from '../actions/navigation';

import { EVENT_TYPES } from '../constants'; 
const { 
    EVENT_GUESSING, 
    EVENT_FAILED, 
    EVENT_SOLVED, 
} = EVENT_TYPES;


const initialState = {
    event: EVENT_GUESSING,
    failCount: 0,
    message: '',
    currSelCount: 0,
    showingTutorial: false,
    showingScenarioOverlay: false,
    showingRekinnect: false,
    clearFunction: ()=>{},
};

const exercise = ( state = initialState, action ) => {
    switch(action.type){
        case CORRECT_ANSWER:
            return {
                ...state,
                event: EVENT_SOLVED,
                message: action.successMessage,
            };
        case INCORRECT_ANSWER:
            return {
                ...state,
                event: EVENT_FAILED,
                message: action.failMessage,
                failCount: state.failCount + 1,
            };
        case START_GUESSING:
            return {
                ...state,
                event: EVENT_GUESSING,
            }
        case UPDATE_SEL_COUNT:
            return {
                ...state,
                currSelCount: action.selCount,
                event: EVENT_GUESSING,
            }
        case SET_CASE_STUDY:
            return initialState;
        case SET_PAGE:
            return initialState;
        case GOTO_NEXT_PAGE:
            return initialState;
        case GOTO_PREV_PAGE:
            return initialState;
        case SHOW_TUTORIAL:
            return {
                ...state,
                showingTutorial: true,
            }
        case HIDE_TUTORIAL:
            return {
                ...state,
                showingTutorial: false,
            }
        case SHOW_SCENARIO_OVERLAY:
            return {
                ...state,
                showingScenarioOverlay: true,
            }
        case HIDE_SCENARIO_OVERLAY:
            return {
                ...state,
                showingScenarioOverlay: false,
            }
        case SHOW_REKINNECT:
            return {
                ...state,
                showingRekinnect: true,
            };
        case HIDE_REKINNECT:
            return {
                ...state,
                showingRekinnect: false,
            }
        case SET_CLEAR_FUNCTION:
            return {
                ...state,
                clearFunction: action.clearFunction,
            }
        default:
            return state;
    }
}

export default exercise;