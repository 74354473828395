import React from 'react';
import CaseStudy from './views/CaseStudy';
import { setCaseStudy } from '../actions/navigation';
import { connect } from 'react-redux';

const mapStateToProp = state => ({
    caseStudyFromRedux: state.navigation.caseStudy,
})

const mapDispatchToProp = dispatch => ({
    setCaseStudy: ( caseStudyName, mode) => {
        dispatch(setCaseStudy(caseStudyName, mode ));
    }
})

const CaseStudyRouter = ({location, setCaseStudy, caseStudyFromRedux}) => {
    const parsedLocation = location.pathname.split('/');
    const caseStudyFromRouter = parsedLocation[2];
    const mode = parsedLocation[1];
    setCaseStudy(caseStudyFromRouter, mode);
    if (caseStudyFromRedux !== caseStudyFromRouter) return null;
    return <CaseStudy/>
}

export default connect(mapStateToProp, mapDispatchToProp)(CaseStudyRouter);
