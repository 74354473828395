import React, {Component} from 'react';
import { connect } from 'react-redux';
import caseStudies from '../../../casestudies';
import { gotoNextPage } from '../../../actions/navigation';

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const exerciseIndex = page - 2;
    const { maxSelCount } = caseStudies[caseStudy].exercises[exerciseIndex];
    
    const isLastExercise = exerciseIndex >= caseStudies[caseStudy].exercises.length - 1;

    const progressToCheck = state.navigation.mode === 'redo' ? 'resolved' : 'solved';
    const solved = state.progress[caseStudy][progressToCheck];

    return {
        event: state.exercise.event,
        message: state.exercise.message,
        currSelCount: state.exercise.currSelCount,
        maxSelCount,
        passText: isLastExercise ? "LET'S WRAP IT UP!" : "NEXT EXERCISE",
        reviewMode: state.navigation.mode === 'review' || page <= solved,
    }
}

const mapDispatchToProps = dispatch => ({
    gotoNextPage: () => {
        dispatch(gotoNextPage());
    },
})

class ButtonSubmit extends Component{
    constructor(props){
        super(props);

        this.buttonStyle = this.buttonStyle.bind(this);
        this.buttonText = this.buttonText.bind(this);

    }


    buttonStyle(){
        if (this.props.event === 'solved' || this.props.reviewMode) {return "btn-ghost-correct"}
        if (this.props.event === 'failed') {return "btn-ghost disabled"}


        if (this.props.currSelCount === this.props.maxSelCount) {
            return "btn-ghost-ready";
        } else {
            return "btn-ghost disabled";
        }
    }

    buttonText(){
        if (this.props.event === 'solved' || this.props.reviewMode) {return this.props.passText}


        if (this.props.currSelCount < this.props.maxSelCount) {
            return this.props.currSelCount + " of " + this.props.maxSelCount + " answers";
        } else {
            return "SUBMIT ANSWER";
        }
    }




    render(){
        const { event, gotoNextPage, onSubmissionClick } = this.props;
        return(
            <button 
            className={this.buttonStyle()} 
            onClick={event === "solved" || this.props.reviewMode ? gotoNextPage : onSubmissionClick}    
            id="submit">
            
            {this.buttonText()}
            
            </button>
        );
    }
}

ButtonSubmit.defaultProps = {
    passText : "NEXT EXERCISE"
}


export default connect(mapStateToProps, mapDispatchToProps)( ButtonSubmit );
