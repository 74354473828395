import React from 'react';
import AutoAnimation from '../animations/AutoAnimation';
import { hideTutorial } from '../../actions/exercise';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    showingTutorial: state.exercise.showingTutorial,
})

const mapDispatchToProps = dispatch => ({
    handleClick: () => dispatch(hideTutorial()),
})

const TutorialCB = ({showingTutorial, handleClick}) => {
    
    if(!showingTutorial) return null;
    return(
        <div id='overlay-tut'>  
            <div className='overlay-cont'>
                <div className="overlay-tut-exercise">

                    <div className="tut-header">
                        <button className="btnClose" aria-label="Close" title="Close" onClick={handleClick}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="tut-body">
                        <p>
                            <b>This is a checkbox exercise</b>.
                            <br />
                            <br /> Click on the box to select it.
                            <br />
                            <br /> If you change your mind, click on the box again to deselect it. But remember to select the correct number of boxes!</p>

                        <div className="tut-anim">
                            <AutoAnimation path="../../bodymovin/tutorials/tut-cb.json"/>
                        </div>
                    </div>

                    <div className="tut-button" onClick={handleClick}>
                        <a className="btn-ghost">Got it!</a>
                    </div>

                </div>
            </div>
        </div>
    );
    


}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialCB);