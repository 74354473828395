import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const VanConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Torn Posterior Cruciate Ligament
              </h2>

              <p>
                When Van Diesel’s knee hit the dashboard, the tibia was pushed
                posteriorly relative to the femur. Because the PCL limits the
                posterior translation of the tibia relative to the femur, Van
                Diesel’s PCL was torn.
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Ligaments are connective tissue that stabilize joints by
                  limiting motion{" "}
                </li>
                <li>Form and function are interrelated!</li>
                <li>
                  The attachment points and the orientation of each cruciate
                  ligament is connected to its function{" "}
                </li>
                <li>
                  Regardless of relative movement, direction of resistance is
                  the same (posterior translation of the tibia relative to the
                  femur = the anterior translation of the femur relative to the
                  tibia)
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img
                src="../img/van/conc.svg"
                alt="diagram of torn pcl due to anterior impact"
              />
              <figcaption>
                Figure 1. Diagram of Van's PCL tearing due to impact from the
                dashboard
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Anatomy overview</b>
                          <br />
                          Considering the plane in which the injury occurs,
                          choose the three most likely structures that could be
                          damaged by the impact.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../img/van/ex-1.svg"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about the structures that limit the movement of
                          the knee joint in the plane of the accident.{" "}
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a correct">
                          Post. cruciate lig.
                        </li>
                        <li className="flo-box6 correct">Ant. cruciate lig.</li>
                        <li className="flo-box6">Lat. cruciate lig.</li>
                        <li className="flo-box6">Med. cruciate lig.</li>
                        <li className="flo-box6 correct">Patellar lig.</li>
                        <li className="flo-box6">Menisci</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Cruciate ligaments</b>
                          <br />
                          Cruciate ligaments were most likely involved. Let’s
                          identify the attachment points for the ACL and PCL.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../img/van/flowchart-2.png"
                            alt="Visual asset for Exercise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Remember that the name of the ligament is the biggest
                          hint to the origin.{" "}
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">
                          ACL origin:
                          <br /> anterior tibial plateau
                        </li>
                        <li className="flo-box4 correct">
                          ACL insertion: lateral femoral condyle
                        </li>
                        <li className="flo-box4 correct">
                          PCL origin:
                          <br /> posterior tibial eminence
                        </li>
                        <li className="flo-box4 correct">
                          PCL insertion: medial femoral condyle
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Kinnect the dots!</b>
                          <br />
                          Based on the direction his knee hit the dashboard,
                          which structure must have been damaged?
                        </a>
                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../img/van/ex-3.svg"
                            alt="Visual asset for Exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          The orientation of each ligament affects how it limits
                          movement anteriorly or posteriorly.{" "}
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw2"></div>
                      <div className="lh-2a"></div>
                      <div className="lh-2"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box2a correct">
                          Post. cruciate lig.
                        </li>
                        <li className="flo-box2">Ant. cruciate lig.</li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default VanConc;
