import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reset } from "../../../actions/progress.js";
import { postDataSecure, sendDataSecure } from "../../../api";
import {
  getCaseStudyAvailability,
  getCaseStudyProgresses,
} from "../../../utils/progressHelpers.js";

const CaseStudiesSection = ({
  caseStudyProgresses,
  caseStudyAvailability,
  reset,
  logReviewReset,
}) => {
  const [showResetModal, setShowResetModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currCaseStudy, setCurrCaseStudy] = useState("");

  const handleResetClick = (name) => {
    setCurrCaseStudy(name);
    setShowResetModal(true);
  };

  const handleReviewClick = (name) => {
    setCurrCaseStudy(name);
    setShowReviewModal(true);
  };

  const CaseStudyCard = ({ name, description }) => {
    let ButtonSection;
    let iconClassName;
    const buttonText = `Solve ${name}'s Case`;

    if (caseStudyProgresses[name] === 100) {
      iconClassName = "csIcon-img completed";
      ButtonSection = (
        <div>
          <button
            onClick={() => handleResetClick(name)}
            style={{ margin: "0 2px" }}
            className="btn-ghost"
            title="Reset Case Study"
          >
            Reset
          </button>
          <button
            onClick={() => handleReviewClick(name)}
            style={{ margin: "0 2px" }}
            className="btn-ghost"
            title="Review Case Study"
          >
            Review
          </button>
        </div>
      );
    } else if (caseStudyAvailability[name]) {
      iconClassName = "csIcon-img";
      ButtonSection = (
        <Link to={"attempt/" + name} className="btn-ghost" title={buttonText}>
          {buttonText}
        </Link>
      );
    } else {
      iconClassName = "csIcon-img locked";
      ButtonSection = (
        <Link
          to={"attempt/" + name}
          className="btn-ghost disabled"
          title={buttonText}
        >
          {buttonText}
        </Link>
      );
    }

    return (
      <div className="csGroup">
        <div className="csIcon">
          <div id={name + "-gif"} className={iconClassName}></div>
          {description}
        </div>
        <div className="csBadges">{ButtonSection}</div>
      </div>
    );
  };

  const renderResetModal = () => {
    return (
      <div id="overlay-tut">
        <div className="overlay-cont">
          <div className="overlay-modal">
            <div className="modal-title">
              <p>Ready to test your knowledge?</p>
              <button
                onClick={() => setShowResetModal(false)}
                className="btnClose"
                aria-label="Close"
                title="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <p>
                <b>You're about to reset this case study.</b>
                <br />
                Do you want to:
              </p>
              <div className="modal-button">
                <Link
                  to={"redo/" + currCaseStudy}
                  onClick={() => {
                    reset(currCaseStudy);
                    logReviewReset("reset", currCaseStudy);
                  }}
                  className="btn-ghost-ready"
                >
                  Reset this case study
                </Link>
              </div>
              <div className="modal-button">
                <Link to={"redo/" + currCaseStudy} className="btn-ghost">
                  Continue where I left off
                </Link>
              </div>
              <div className="modal-button">
                <Link
                  to=""
                  onClick={() => setShowResetModal(false)}
                  className="btn-ghost"
                >
                  Nevermind, I don't want to test right now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReviewModal = () => {
    return (
      <div id="overlay-tut">
        <div className="overlay-cont">
          <div className="overlay-modal">
            <div className="modal-title">
              <p>Review Correct Answers</p>
              <button
                onClick={() => setShowReviewModal(false)}
                className="btnClose"
                aria-label="Close"
                title="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <p>
                <b>Hey there! You're about to go into review mode!</b>
                <br />
                Answer submissions will be disabled but all the correct answers
                are there for you to review. Also, feel free to hop between
                exercises using the progress bar.
              </p>
              <div className="modal-button">
                <Link
                  to={"review/" + currCaseStudy}
                  className="btn-ghost-ready"
                  onClick={() => logReviewReset("review", currCaseStudy)}
                >
                  Review this case study
                </Link>
              </div>
              <div className="modal-button">
                <Link
                  to=""
                  onClick={() => setShowReviewModal(false)}
                  className="btn-ghost"
                >
                  Nevermind, I don't want to review right now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showResetModal && renderResetModal()}
      {showReviewModal && renderReviewModal()}

      <section id="caseStudies" className="xtarget">
        <div className="section">
          <div className="sectionLeft">
            <h2>
              Case Studies
              <br />
              Lower Limb
            </h2>
            <p>
              The case studies are organized by level of difficulty. Select one
              of the available case studies to begin. You can unlock the next
              level by completing case studies.
            </p>

            <h3>Earn achievements</h3>
            <p>
              Level up and earn badges along the way! To see how you can earn
              them, check out your{" "}
              <a href="user-profile.html">
                <b>User Profile</b>
              </a>{" "}
              on the main menu.
            </p>

            <h3>Review or reset a case study</h3>
            <p>
              Once you’ve completed a case study, you can choose to{" "}
              <b>review</b> your answers or <b>reset</b> a case study and test
              your knowledge.
            </p>

            <h3>Share your feedback</h3>
            <p>
              Whether you're finish all 3 levels or on your first case study,{" "}
              <b>we'd love to hear your thoughts, ideas and suggestions!</b>{" "}
              Your input will help us make Kinundrum a better learning resource,
              so please click on the button below to share your feedback.
            </p>
            <p>
              <a
                className="btn-ghost"
                href="https://forms.gle/Fuj4nQ2canzacbdG8"
                target="_blank"
                rel="noopener noreferrer"
                title="Share Feedback"
              >
                Share Feedback
              </a>
            </p>
          </div>
          <div className="sectionRight">
            <div className="sectionLevel">
              <div className="csGroup"></div>
              <div className="csGroupHalf">
                <p>Level 3</p>
              </div>
              <CaseStudyCard name="maryem" description="The Alpine Skier" />
            </div>
            <div className="sectionLevel">
              <div className="csGroupHalf"></div>
              <div className="csGroupHalf">
                <p>Level 2</p>
              </div>
              <CaseStudyCard name="alex" description="The Soccer Player" />
              <CaseStudyCard name="fred" description="The Weightlifter" />
            </div>
            <div className="sectionLevel">
              <div className="csGroupHalf">
                <p>Level 1</p>
              </div>
              <CaseStudyCard name="bernie" description="The Tennis Mom" />
              <CaseStudyCard name="van" description="The Rebel Driver" />
              <CaseStudyCard name="jenny" description="The Volleyball Player" />
            </div>
          </div>
        </div>
      </section>
      <section id="spineModule" className="xtarget">
        <div className="section">
          <div className="sectionLeft">
            <h2>
              Case Studies
              <br />
              Spine
            </h2>
            <p>
              Let’s solve two spinal injuries cases together! What happened to{" "}
              <b>Gary Gardener</b> and <b>Ben D. Spine?</b>
            </p>
            <h3>As you go through the cases...</h3>
            <p>
              Remember to use <b>hints</b>, <b>Re-Kinnects</b>, and <b>notes</b>{" "}
              on the side panels to help you get to the answer.
              <br />
              <br />
              Even if you know the answer, try looking through the Re-Kinnects.
              You may learn something new observing the 3D models.
            </p>

            <h3>Share your feedback</h3>
            <p>
              Whether you're finish all 2 levels or on your first case study,{" "}
              <b>we'd love to hear your thoughts, ideas and suggestions!</b>{" "}
              Your input will help us make Kinundrum a better learning resource,
              so please click on the button below to share your feedback.
            </p>
            <p>
              <a
                className="btn-ghost"
                href="https://forms.gle/Fuj4nQ2canzacbdG8"
                target="_blank"
                rel="noopener noreferrer"
                title="Share Feedback"
              >
                Share Feedback
              </a>
            </p>
          </div>
          <div className="sectionRight">
            <div className="sectionLevel">
              <div className="csGroup"></div>
              <div className="csGroupHalf">
                <p>Level 2</p>
              </div>
              <CaseStudyCard name="ben" description="The Golfer" />
            </div>
            <div className="sectionLevel">
              <div className="csGroup"></div>
              <div className="csGroupHalf">
                <p>Level 1</p>
              </div>
              <CaseStudyCard name="gary" description="The Gardener" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  caseStudyProgresses: getCaseStudyProgresses(state),
  caseStudyAvailability: getCaseStudyAvailability(state),
  progress: state.progress,
  token: state.authentication.token,
  role: state.authentication.role,
  studentNumber: state.authentication.studentNumber,
});

const mapDispatchToProps = (dispatch) => ({
  reset: (caseStudy) => dispatch(reset(caseStudy)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  reset: (caseStudy) => {
    const { progress, token, studentNumber } = stateProps;
    const exercise = progress[caseStudy];

    let body = new FormData();
    body.append(
      "exercise",
      JSON.stringify({
        Name: caseStudy,
        Solved: exercise.solved,
        Resolved: 0,
      })
    );
    postDataSecure(`exercises/${studentNumber}`, body, token);

    dispatchProps.reset(caseStudy);
  },
  logReviewReset: (type, caseStudy) => {
    const { token, role, studentNumber } = stateProps;
    let body = new FormData();
    body.append("actionType", type);
    body.append("caseStudy", caseStudy);
    body.append("role", role);
    body.append("studentNumber", studentNumber);

    sendDataSecure("data/reviewReset", body, "POST", token);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CaseStudiesSection);
