import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const resetWindow = () => window.scrollTo(0,0);

    const path = window.location.pathname.slice(1);

    if (path === 'login') return null;
    return (
        <div className='sidenav' id='sidenav'>
            <ul>
                <li>
                    <Link to='' data-toggle="tooltip" data-placement="right" title="Home" onClick={resetWindow} >
                        <svg version="1.1" id="home_1_"
                            x="0px" y="0px" viewBox="0 0 26 26">
                            <path className={path === '' ? "sideActive" : "sidenav"} d="M24.6,12.5L13.8,1.4 c-0.4-0.5-1.2-0.5-1.6,0 c0,0,0,0,0,0L1.4,12.5C1,13,0.9,13.6,1.1,14.2 c0.2,0.5,0.6,1.1,1.1,1.1h1.2v7.8 c0,0.7,0.5,1.9,1.2,1.9h5v-8 c0-0.6,0.6-0.5,1.2-0.5h4.4 c0.6,0,1.2-0.2,1.2,0.5v8h5 c0.7,0,1.2-1.3,1.2-1.9v-7.8h1.2 c0.5,0,1-0.7,1.1-1.1C25.1,13.6,25,13,24.6,12.5z"></path>
                        </svg>
                    </Link>
                </li>
                <li>
                    <Link to='/user' data-toggle="tooltip" data-placement="right" title="User Profile" onClick={resetWindow}>
                        <svg version="1.1"
                            id="user-profile" x="0px" y="0px" viewBox="0 0 26 26">
                            <path className={path === 'user' ? "sideActive" : "sidenav"} d="M16.4,16.7v-3.2c2.1-0.9,2.6-4,2.6-6.5c0-3.3-2.7-6-6-6S7,3.7,7,7c0,2.5,0.6,5.7,2.6,6.5 v3.2c-4.1,0.6-8.5,2.7-8.6,6c0,0,3.3,2.4,12,2.4s12-2.4,12-2.4C25,19.4,20.6,17.3,16.4,16.7z"
                            />
                        </svg>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar