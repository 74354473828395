import React from 'react';
import { connect } from 'react-redux';
import QuestionArea from '../exercises/QuestionArea';
import ButtonTutorial from '../exercises/ButtonTutorial';
import VisualAsset from '../exercises/VisualAsset';
import IncorrectOverlayWrapper from '../exercises/IncorrectOverlayWrapper';
import caseStudies from '../../casestudies';

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const exerciseIndex = page - 2;
    const exerciseData = caseStudies[caseStudy].exercises[exerciseIndex];
    return {
        InteractiveComponent: exerciseData.interactiveArea,
        InteractiveComponentProps: exerciseData.interactiveAreaProps,
        TutorialComponent: exerciseData.tutorial,
        RekinnectComponent: exerciseData.rekinnect,
    };
}

const Exercise = ({ InteractiveComponent, InteractiveComponentProps, TutorialComponent, RekinnectComponent }) => {
    return (
        <main>
            <div className="contextEx">
                {TutorialComponent && <TutorialComponent/>}
                {RekinnectComponent && <RekinnectComponent/>}
                <QuestionArea />
                <IncorrectOverlayWrapper />
                <section>
                    <div className="interactiveArea">
                    <div className="interactiveWrap">
                        <div className="visual">
                            <ButtonTutorial/>
                            <VisualAsset />
                        </div>
                        <InteractiveComponent {...InteractiveComponentProps} />
                    </div>
                    </div>
                </section>
            </div>
        </main>
    );

}

export default connect(mapStateToProps)(Exercise);
