import React from "react";
import connectRekinnect from "./connectRekinnect";
import PNGSlider from "../animations/PNGSlider";

function GaryEx5Rekinnect(props) {
  if (!props.showingRekinnect) return null;

  return (
    <div id="contentRK">
      <section>
        <div className="questionArea">
          <div className="questionWrap">
            <div className="questionRK">
              <h1>Re-kinnect:</h1>
              <h2>Lumber flexion and extension</h2>
              <p>
                Let’s compare the size of the intervertebral foramen during
                flexion and extension.
                <br />
                <br />
                <b>Drag the slider</b> to toggle between spinal flexion and
                extension.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="interactiveArea">
          <div className="interactiveWrap">
            <div className="visualRK">
              {/*-- slider component --*/}
              <div id="bm">
                <PNGSlider
                  text={["Flexion", "Neutral", "Extension"]}
                  frames={[
                    "../img/gary/rk/rk_1/rk_1_00001.png",
                    "../img/gary/rk/rk_1/rk_1_00002.png",
                    "../img/gary/rk/rk_1/rk_1_00003.png",
                    "../img/gary/rk/rk_1/rk_1_00004.png",
                    "../img/gary/rk/rk_1/rk_1_00005.png",
                    "../img/gary/rk/rk_1/rk_1_00006.png",
                    "../img/gary/rk/rk_1/rk_1_00007.png",
                    "../img/gary/rk/rk_1/rk_1_00008.png",
                    "../img/gary/rk/rk_1/rk_1_00009.png",
                    "../img/gary/rk/rk_1/rk_1_00010.png",
                    "../img/gary/rk/rk_1/rk_1_00011.png",
                    "../img/gary/rk/rk_1/rk_1_00012.png",
                    "../img/gary/rk/rk_1/rk_1_00013.png",
                    "../img/gary/rk/rk_1/rk_1_00014.png",
                    "../img/gary/rk/rk_1/rk_1_00015.png",
                    "../img/gary/rk/rk_1/rk_1_00016.png",
                    "../img/gary/rk/rk_1/rk_1_00017.png",
                    "../img/gary/rk/rk_1/rk_1_00018.png",
                    "../img/gary/rk/rk_1/rk_1_00019.png",
                  ]}
                />
              </div>
            </div>
            <div className="submissionRK">
              <button className="btn-ghost-white" onClick={props.close}>
                Back to exercise
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connectRekinnect(GaryEx5Rekinnect);
