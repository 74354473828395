import React from 'react';

const ProgressBar = ({ percentDone }) => {

    let styles = percentDone > 0 ? {width: percentDone+"%"} : {};
    let levelBarClass = percentDone > 0 ? 'levelBar-progress' : 'levelBar-none';

    return (
        <div className="levelBar-bg">
            <div className={levelBarClass} style={styles}>{ Math.round(percentDone) }%</div>
        </div>
    );
}

export default ProgressBar;
