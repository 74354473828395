import React from "react";
import GaryConc from "../components/conclusions/GaryConc";
import ChoicesCB from "../components/interactive/ChoicesCB";
import TutorialCB from "../components/tutorials/TutorialCB";
import { VISUAL_ASSET_TYPES } from "../constants";
import ChoicesMC from "../components/interactive/ChoicesMC";
import TutorialMC from "../components/tutorials/TutorialMC";
import GaryEx5Rekinnect from "../components/rekinnect/GaryEx5Rekinnect";
import GaryEx6Rekinnect from "../components/rekinnect/GaryEx6Rekinnect";

export default {
  title: "The Case of the Gardener",
  name: "Gary",
  profilePicSrc: "../img/gary/profile.svg",
  introAsset: {
    type: "slideshow",
    slides: [
      <img src="../img/gary/intro_scene_0.png" />,
      <img src="../img/gary/intro_scene_1.png" />,
      <img src="../img/gary/intro_scene_2.png" />,
      <img src="../img/gary/intro_scene_3.png" />,
      <img src="../img/gary/intro_scene_4.png" />,
      <video controls>
        <source src="../img/gary/intro_scene_5.mp4" type="video/mp4" />
      </video>,
      <img src="../img/gary/intro_scene_6.png" />,
      <img src="../img/gary/intro_scene_7.png" />,
      <img src="../img/gary/intro_scene_8.png" />,
      <img src="../img/gary/intro_scene_9.png" />,
      <img src="../img/gary/intro_scene_10.png" />,
      <img src="../img/gary/intro_scene_11.png" />,
      <img src="../img/gary/intro_scene_12.png" />,
      <img src="../img/gary/intro_scene_13.png" />,
      <img src="../img/gary/intro_scene_14.png" />,
    ],
  },
  observations: [
    "gary's work involves lots of lifting, bending, and twisting",
    "sudden pain in his low back when picking up a laundry basket",
    "pain reduces when lying down",
    "burning pain down the back of the left leg when Gary started working again",
  ],
  conclusion: GaryConc,
  exercises: [
    // Exercise 1
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_1.png",
      },
      questionArea: {
        smallHeading: "Exercise 1:",
        largeHeading: "What is the pain?",
        paragraph: (
          <p>
            Let’s review the pain Gary is experiencing, which is down the back of his leg as shown in the animation. Choose <b>three</b> possible structures which would most likely cause the pain.
          </p>
        ),
        hint:
          "Think of the structures that are directly below the area of the pain. What is located there?",
      },
      notes: [
        "likely injured structures in posterior compartment of leg (gastrocnemius, sciatic nerve, hamstrings)",
      ],
      maxSelCount: 3,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "Hamstrings",
          "Sciatic nerve",
          "Gluteus maximus",
          "Quadriceps",
          "Femoral nerve",
          "Adductor magnus",
          "Obturator nerve",
          "Gastrocnemius",
        ],
        answers: ["Hamstrings", "Sciatic nerve", "Gastrocnemius"],
        choicesPerRow: 3,
      },
      tutorial: TutorialCB,
      rekinnect: null,
    },
    // Exercise 2
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_2.png",
      },
      questionArea: {
        smallHeading: "Exercise 2:",
        largeHeading: "What is going on?",
        paragraph:
          "What specific movement or position do all of the previous aggravating activities have in common?",
        hint:
          "Look closely at each of these aggravating moves. Is the same movements (flexion or extension) occurring at each joint?",
      },
      notes: ["spinal and hip flexion aggravates pain"],
      maxSelCount: 2,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "Hip flexion",
          "Hip extension",
          "Spinal extension",
          "Spinal flexion",
          "Knee flexion",
          "Knee extension",
        ],
        answers: ["Hip flexion", "Spinal flexion"],
        choicesPerRow: 3,
      },
      tutorial: TutorialCB,
      rekinnect: null,
    },
    // Exercise 3
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_3.png",
      },
      questionArea: {
        smallHeading: "Exercise 3:",
        largeHeading: "What is going on?",
        paragraph:
          "Observe the movements that increase/relieve his pain. Can the hamstring or gastrocnemius cause the problem by either contracting or lengthening?",
        hint:
          "Look carefully at ALL the positions or movements which aggravate and relieve the pain. Can the hamstring or gastrocnemius cause pain in all situations?",
      },
      notes: [
        "a nerve root is injured",
        "spinal and hip flexion aggravates pain",
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "This is incorrect.",
        paragraph: "Try writing down what actions these two muscles do... as well as how you would stretch them. Look at the actions again and see if they mimic the contraction or lengthen position?)",
        numberOfAppears: 2,
        isRekinnect: false
      },
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: ["Yes", "No"],
        answer: "No",
        choicesPerRow: 1,
      },
      tutorial: TutorialMC,
      rekinnect: null,
    },
    // Exercise 4
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_4.png",
      },
      questionArea: {
        smallHeading: "Exercise 4:",
        largeHeading: "Where is the nerve being compressed?",
        paragraph: (
          <p>
            So if it isn't a muscle, let's determine if it might be a nerve
            causing this pain.
            <br />
            <br />
            With the movements you chose previously in Exercise 2, where could
            the nerve be compressed or lengthened?
          </p>
        ),
        hint:
          "A nerve is usally compressed either: between two muscles or against a bony feature; or another structure might push into it... causing a 'pinch'.",
      },
      notes: [
        "the nerve root is likely to be compressed by structures in the invertebral or vertebral foramen",
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Let's think about this some more.",
        paragraph: "We determined that some of the aggravating positions actually have the knee flexed - the knee would have to be extended to lengthen the nerve. The nerve could also be lengthened with hip flexion but there isn’t really any structure compressing the nerve behind the hip.",
        numberOfAppears: 2,
        isRekinnect: false
      },
      maxSelCount: 2,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "It is being lengthened at the back of the knee",
          "It is being compressed at the back of the hip",
          "It is being compressed in the intervertebral foramen",
          "It is being compressed in the spinal foramen",
        ],
        answers: [
          "It is being compressed in the intervertebral foramen",
          "It is being compressed in the spinal foramen",
        ],
        choicesPerRow: 1,
      },
      tutorial: TutorialCB,
      rekinnect: null,
    },
    // Exercise 5
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_5.png",
      },
      questionArea: {
        smallHeading: "Exercise 5:",
        largeHeading: "What is going on?",
        paragraph: (
          <p>
            Let’s focus on the intervertebral foramen as a possibility. During
            spinal flexion, can the nerve be compressed <b>within</b> the
            intervertebral foramen?
          </p>
        ),
        hint:
          "Think about the size of the intervertebral foramen during flexion/extension.",
      },
      notes: [
        "the nerve root could be compressed in the intervertebral foramen"
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: ["Yes", "No"],
        answer: "No",
        choicesPerRow: 1,
      },
      tutorial: TutorialMC,
      rekinnect: GaryEx5Rekinnect,
    },
    // Exercise 6
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_6.png",
      },
      questionArea: {
        smallHeading: "Exercise 6:",
        largeHeading: "Getting to the 'root' of the problem",
        paragraph: (
          <p>
            The nerve is being compressed during spinal flexion but it is not
            the bony aspect of the intervertebral foramen itself that is
            pinching it.
            <br />
            <br />
            What could be compressing the nerve?
          </p>
        ),
        hint:
          "Think about the size of the intervertebral foramen during flexion/extension. Can it be pushing into the nerve when flexing?",
      },
      notes: [
        "the nerve root is compressed in the intervertebral foramen by the vertebral disc"
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: ["Bone", "Ligament", "Disc", "Spinal cord", "Air"],
        answer: "Disc",
        choicesPerRow: 3,
      },
      tutorial: TutorialMC,
      rekinnect: GaryEx6Rekinnect,
    },
    // Exercise 7
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_7.png",
      },
      questionArea: {
        smallHeading: "Exercise 7:",
        largeHeading: "How does Gary relieve his pain?",
        paragraph: (
          <p>
            Considering the movements that help relieve or decrease the pain, <b>choose</b> the correct mechanism from the list which explains how standing, walking and lying down can relieve the pain.
          </p>
        ),
        hint:
          "Review your knowledge of how the components making up the disc change during flexion/extension.",
      },
      notes: [
        "extension relieves pain by causing the vertebral disc to shift anteriorly, away from the nerve root",
      ],
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: [
          "Spinal flexion occurs and that causes the nucleus to shift anteriorly",
          "Spinal flexion occurs and that causes the nucleus to shift posteriorly",
          "Spinal extension occurs and that causes the nucleus to shift anteriorly",
          "Spinal extension occurs and that causes the nucleus to shift posteriorly",
        ],
        answer:
          "Spinal extension occurs and that causes the nucleus to shift anteriorly",
        choicesPerRow: 2,
      },
      tutorial: TutorialMC,
      rekinnect: null,
    },
    // Exercise 8
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/gary/ex_8.png",
      },
      questionArea: {
        smallHeading: "Exercise 8:",
        largeHeading: "What caused the injury?",
        paragraph: (
          <p>
            How did picking up the laundry basket cause this injury? <b>Choose</b> the most likely cause.
          </p>
        ),
        hint:
          "Would just one repetition of flexion cause the disc to damage? What is more likely to have caused the injury to Gary?",
      },
      notes: [
        "repeated flexion causes the disc to tear and bulge out, causing the nerve to be compressed by the disc",
      ],
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: [
          "It is a flexion activity and caused the disc to slip",
          "It is a flexion activity but the disc was damaged over time and this was the last straw",
          "It is a flexion activity and the laundry was so heavy it caused the disc to tear with effort",
          "Spinal extension occurs and that causes the nucleus to shift posteriorly",
        ],
        answer:
          "It is a flexion activity but the disc was damaged over time and this was the last straw",
        choicesPerRow: 2,
      },
      tutorial: TutorialMC,
      rekinnect: null,
    },
  ],
};
