import React from 'react';

const AdminNav = ({setPage}) => {
    return (
        <ul id='admin-nav'>
            <li><a onClick={()=>setPage('manage-users')}>Manage Users</a></li>
            <li><a onClick={()=>setPage('app-stats')}>Application Stats</a></li>
        </ul>
    );
}

export default AdminNav;