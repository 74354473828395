import React from 'react';
import { setPage } from '../../../actions/navigation';
import { connect } from 'react-redux';
import caseStudies from '../../../casestudies';

const mapStateToProps = state => {
    const caseStudy = state.navigation.caseStudy;
    let solved;
    if ( state.navigation.mode === 'redo' ) 
        solved = state.progress[caseStudy].resolved - 1;
    else 
        solved = state.progress[caseStudy].solved -1;

    return {
        title: caseStudies[caseStudy].title,
        total: caseStudies[caseStudy].exercises.length + 2,
        page: state.navigation.page - 1,
        solved,
    }
}

const mapDispatchToProps = dispatch => ({
    setPage: page => {
        dispatch(setPage(page));
    }
})

const ExerciseNav = ({title, total, page, solved, setPage})=>{
    /* blocks is the number of pages inbetween the intro and conclusion page including the dotted line */
    const blocks = ((total-2)*2)+1;
    const pages = Array.from(new Array(blocks), (_, ind) => ind + 1);

    /**
     * Generates the blocks inbetween the intro block and conclusion block
     * 
     * Every odd element in the array is a dotted line, the rest are page blocks
     */
    const Progress = pages.map((val) => {
        if (val%2 === 0){
            var elmClass = 'progressEx'
            if (page === val/2){
                elmClass += ' current'
            } else if (solved+1 < Math.floor(val/2)){
                elmClass += ' disabled'
            }


            return <li className={elmClass} onClick={() => setPage(val/2+1)} key={val}> {val/2} </li>
        } 

        if (Math.floor(val/2) <= solved){
            return <li className="dotline" key={val}></li>;
        }

        return null;
    })

    /* Determine the class of the conclusion block on the top nav */
    var concClass = 'progressEnds';
    if (page === total-1){
        concClass += ' current'
    } else if (solved+1 < total-1){
        concClass += ' disabled'
    }

    return (
        <div>
            <p>{title}</p>
            <div id="progressBar">
                <ul>
                    <li className={ page === 0 ? "progressEnds current" : "progressEnds"} onClick={() => setPage(1)} id="progressIntro">
                        Intro.
                    </li>
                    {solved >= 0 ? <li className="dotline"></li> : null}
                    {Progress}
                    <li className={concClass} onClick={() => setPage(total)} id="progressConc">Conc.</li>
                </ul>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseNav);

