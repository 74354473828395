import React from 'react';
import ExerciseNav from './ExerciseNav';
import AdminNav from './adminNav';


const home = (
    <ul>
        <li><a href="#learningGoals" title="link to Learning Goals">Learning Goals</a></li>
        <li><a href="#caseStudies" title="link to Case Studies">Case Studies</a></li>
        <li><a href="#about" title="link to About the Project">About the Project</a></li>
    </ul>
);

const library = (
    <p>Library</p>
);

const user = (
    <p>User Profile</p>
);


const Topnav = ({page, setPage}) => {

    const CurrentNav = () => {
        switch (page) {
            case 'home':
                return home;
            case 'library':
                return library;
            case 'user':
                return user;
            case 'admin':
                return <AdminNav {...{setPage}}/>;
            case 'exercise':
                return <ExerciseNav />
            default:
                return <p>I'm broken :(</p>
        }
    }

    return (
        <div id='topnav'>
            <CurrentNav />
        </div>
    );
    
}

export default Topnav;