import React,{ Component} from 'react';

class PNGSlider extends Component{
     
  constructor(props) {
    super(props);
    this.state = {
      frame:0,
      show: false,
    };

    this.updateSlider = this.updateSlider.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.svgRef = React.createRef() 

  }


  updateSlider(evt){
    this.setState({
      frame:evt.target.value,
      show: false,
    })
  }

  handleClick(){
    this.setState({show: !this.state.show});
  }

  render() {
    const currentText = Math.floor(this.state.frame / this.props.frames.length * 3)
    return (
            <div>
                {/* Preload images by rendering all of them hidden */}
                <div style={{'visibility': 'hidden', 'width': 0, 'height': 0, 'overflow': 'hidden'}}>
                  {this.props.frames.map(frameSrc => <img src={frameSrc} alt="Fred lowering and lifting his left hip"></img>)}
                </div>

                <img style={{width:'100%'}} src={this.props.frames[this.state.frame]} alt="Fred lowering and lifting his left hip"></img>
                <div className="sliderCont">
                    <input id="slider" type="range" value={this.state.frame} min="0" max={this.props.frames.length-1} onChange={ evt => this.updateSlider(evt)} />
                    <div className='slidertext-container'>
                        <div>
                          {this.props.text[0]}
                          {this.props.description && <div className="div-btn-explain">
                            <button 
                            className={currentText === 0 ? 'btn-ghost-rkwhite focused' : 'btn-ghost-rkwhite disabled'}
                            onClick={this.handleClick}
                            >
                              explain
                            </button>
                            <span 
                            className={currentText === 0 && this.state.show ? 'popuptext active' : 'popuptext'}
                            onClick={this.handleClick}
                            >
                              {this.props.description[0]}
                            </span>
                          </div>}
                        </div>
                        <div>
                          {this.props.text[1]}
                          {this.props.description && <div className="div-btn-explain">
                          <button 
                            className={currentText === 1 ? 'btn-ghost-rkwhite focused' : 'btn-ghost-rkwhite disabled'}
                            onClick={this.handleClick}
                            >
                              explain
                            </button>
                            <span 
                            className={currentText === 1 && this.state.show ? 'popuptext active' : 'popuptext'}
                            onClick={this.handleClick}
                            >
                              {this.props.description[1]}
                            </span>
                          </div>}
                        </div>
                        <div>
                          {this.props.text[2]}
                          {this.props.description && <div className="div-btn-explain">
                          <button 
                            className={currentText === 2 ? 'btn-ghost-rkwhite focused' : 'btn-ghost-rkwhite disabled'}
                            onClick={this.handleClick}
                            >
                              explain
                            </button>
                            <span 
                            className={currentText === 2 && this.state.show ? 'popuptext active' : 'popuptext'}
                            onClick={this.handleClick}
                            >
                              {this.props.description[2]}
                            </span>
                          </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
  }
}

export default PNGSlider;
