const URL = window.location.port === '3000' ? 'https://www.kinundrum.ca' : window.location.origin;

export const postData = async (route, body) => {
  const url = `${URL}/${route}`;
  return fetch(url, {
      method: 'POST',
      body,
    });
}

export const postDataSecure = async (route, body, token) => {
  const url = `${URL}/${route}`;
  return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'X-Access-Token': token,
      }),
      body,
    });
}

export const sendData = async (route, body, method) => {
  const url = `${URL}/${route}`;
  return fetch(url, {
    method: method,
    body,
  });
}

export const sendDataSecure = async(route, body, method, token) => {
  const url = `${URL}/${route}`;
  return fetch(url, {
      method: method,
      headers: new Headers({
        'X-Access-Token': token,
      }),
      body,
    });
}
