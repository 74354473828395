import authentication from './authentication';
import exercise from './exercise';
import navigation from './navigation';
import progress from './progress';

const kinundrumApp = (state = {}, action) => {
    return {
        authentication: authentication(state.authentication, action),
        exercise:       exercise(state.exercise, action),
        navigation:     navigation(state.navigation, action, state.progress),//The navigation reducer needs to know the state of the progress 
        progress:       progress(state.progress, action, state.navigation), //The progress reducer needs to know the state of the navigation
    }    
}

export default kinundrumApp;

