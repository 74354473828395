import React from 'react';
import BernieConc from '../components/conclusions/BernieConc';
import ChoicesMC from '../components/interactive/ChoicesMC'
import ChoicesCB from '../components/interactive/ChoicesCB';
import TutorialMC from '../components/tutorials/TutorialMC';
import TutorialCB from '../components/tutorials/TutorialCB';
import { VISUAL_ASSET_TYPES } from '../constants';

export default {
    title: 'The Case of the Tennis Mom',
    name: 'Bernie',
    profilePicSrc: '../../img/bernadette/profile.svg    ',
    introAsset: {
        type: 'video',
        vidSrc: "../vid/bernie-intro.mp4",
        captionSrc: "../vid/bernie-captions.vtt",
    },
    observations: [
        "injured when lunging to reach tennis ball", 
        "sudden and severe pain in the posterior ankle and calf",
        "loud pop",
        "unable to push off on injured foot during walk"  
    ],
    conclusion: BernieConc,
    exercises: [
        
        // Exercise 1: Affected Anatomy

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/bernie-ex1.mp4",
                visualAlt: "",
                sliderText: []
            },
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Affected anatomy',
                paragraph: <p>
                    Considering the mechanism of injury, list <b>three</b> structures that most likely cause Bernie's pain.
                    <br />
                    <br />Watch the video to see her injury again.
                    </p>,
                hint: 'Watch the animation… REALLY closely!',
            },
            incorrectOverlayConfig: {
                show: true,
                strong: "Not quite!",
                paragraph: "Remember! Muscle, tendons and ligaments can be injured when a joint is taken forcefully to the end of range or beyond. (Also consider in which compartment of the leg the injured structures are located.)",
                numberOfAppears: 2,
                isRekinnect: false
            },
            notes: [
                "likely injured structures in posterior compartment of leg"
            ],
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps: {
                options:[
                    "Gastrocnemius", "Soleus",
                    "Tibialis ant.", "Tibialis post.",
                    "Flex. digit. long", "Ext. digit. long",
                    "Calcaneal tendon", "Post. talonav. lig.",
                    "Plantar fascia"
                ],
                answers:[
                    "Gastrocnemius",
                    "Soleus",
                    "Calcaneal tendon"
                ],
                choicesPerRow:3,
            },
            tutorial: TutorialCB,
        },

        // Exercise 2: Affected movement

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/bernie-ex2.mp4",
                visualAlt: "",
                sliderText: []
            },
            questionArea: {
                smallHeading: 'Exercise 2:',
                largeHeading: 'Affected movement',
                paragraph: <p>
                    Based on Bernie's limp, what type of movement is she unable to perform?
                    <br />
                    <br />Watch the video to observe her limp.
                    </p>,
                hint: 'Think about how Bernie’s gait cycle compares to a normal gait cycle. What component looks different?',
            },
            notes: [
                "unable to plantarflex"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's not quite right.",
                paragraph: "Look closely at the push-off phase of her affected gait cycle, and compare it to a normal gait cycle.",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                    options:[
                        "Dorsiflexion",
                        "Inversion",
                        "Eversion",
                        "Plantarflexion"
                    ],
                    answer:"Plantarflexion",
                    choicesPerRow:2,
            },
            tutorial: TutorialMC,
        },

        // Exercise 3: Dominant plantarflexors

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.SLIDER,
                visualSource: "../bodymovin/bernie/bernie-ex3.json",
                visualAlt: "",
                sliderText: ['|','Superficial','Deep']
            },
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Dominant plantarflexors',
                paragraph: <p>
                    Out of these five muscles involved in plantarflexion, which are the <b>two</b> dominant ones?
                    <br />
                    <br /><b>Click and drag the slider</b> to reveal each muscle layer.
                    </p>,
                hint: 'Remember the ‘rule’ of muscles: the more joints that a muscle passes over, the less powerful they are for each action. Consider all the actions of each muscle and this time, choose the two that are the strongest plantarflexors!',
            },
            notes: [
                "gastrocnemius and soleus are the strongest plantarflexors"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "Are you sure?",
                paragraph: "Almost all these muscles are plantarflexors. Think about what other actions they do and figure out which ones have the least joints to act upon. These muscles would be the strongest plantarflexors.",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 2,
            interactiveArea: ChoicesCB,
            interactiveAreaProps: {
                options:[
                    "Gastrocnemius",
                    "Soleus",
                    "Tibialis post.",
                    "Flex. digit. long",
                    "Flex. hall. long."
                ],
                answers:[
                    "Gastrocnemius", 
                    "Soleus"
                ],
                choicesPerRow:3,
            },
            tutorial: TutorialCB,
        },

        // Exercise 4: Kinnect the dots

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.SLIDER,
                visualSource: "../bodymovin/bernie/bernie-ex4.json",
                visualAlt: "",
                sliderText: ['|','Superficial','Deep']
            },
            questionArea: {
                smallHeading: 'Exercise 4:',
                largeHeading: 'Kinnect the dots!',
                paragraph: <p>
                    Since Bernie can’t plantarflex, which tendon has likely been injured?
                    <br />
                    <br /><b>Click and drag the slider</b> to reveal each muscle layer.
                    </p>,
                hint: 'Think about the location of the pain and which tendon would most likely be in that region.',
            },
            notes: [
                "calcaneal tendon was most likely injured"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "",
                paragraph: "",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Calcaneal tendon",
                    "Tib. post. tendon",
                    "FDL tendon",
                    "FHL tendon"
                ],
                answer:"Calcaneal tendon",
                choicesPerRow:2,
            },
            tutorial: TutorialMC,
        },

    ]
};
