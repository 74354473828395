import React, { Component } from 'react';
import connectRekinnect from './connectRekinnect';

class JennyEx1Rekinnect extends Component{
    constructor(props){
        super(props);
        this.inverted = false;

        this.CurrentImage = this.CurrentImage.bind(this);

        this.images = [
            "../../img/jenny/rk-0.svg",
            "../../img/jenny/rk-1.svg",
            "../../img/jenny/rk-2.svg",
            "../../img/jenny/rk-3.svg",
            "../../img/jenny/rk-4.svg",
            "../../img/jenny/rk-5.svg",
        ]

        this.state = {
            'image': 0,
            'inverted': false
        }

    }

    CurrentImage(){
        if (this.state.inverted)
            return this.images[this.state.image+1];
        else
            return this.images[this.state.image];
    }

    SetImage(val){
        this.setState({ 'image':val, 
                        'inverted': false
                    });
    }

    Invert(){
        if (!this.state.inverted) {
            this.setState({'inverted': !this.state.inverted});
        }
    }

    ButtonStyle(val){
        if (val === this.state.image)
            return "btn-ghost-rkwhite inline-m focused";
        return "btn-ghost-rkwhite inline-m";

    }

    InvertStyle(){
        if (this.state.inverted)
            return "btn-ghost-ready";
        else
            return "btn-ghost-notready";

    }


    render(){
        if (!this.props.showingRekinnect) return null;
        return(
            <div id="contentRK">
                <section>
                    <div className="questionArea">
                        <div className="questionWrap">

                            <div className="questionRK">
                                <h1>Re-kinnect:</h1>
                                <h2>Review of ankle ligaments</h2>
                                <p>Let’s review how ankle ligaments can become stretched during inversion ankle sprains. 
                                    <br />
                                    <br />1. Click on <b>plantarflex</b>, <b>neutral</b> and <b>dorsiflex</b> buttons to move the ankle. 
                                    <br />
                                    <br />2. Click on the <b>INVERT</b> button to see which ligament is stretched when the foot is inverted.</p>
                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className="interactiveArea">
                        <div className="interactiveWrap">
                            <div className="visualRK">

                                <img id="jennyrk" src={this.CurrentImage()} className="rkasset" alt="Jenny's ankle in different positions" />
                                <button className={this.ButtonStyle(0)} onClick={() => this.SetImage(0)}>Plantarflex</button>
                                <button className={this.ButtonStyle(2)} onClick={() => this.SetImage(2)}>Neutral</button>
                                <button className={this.ButtonStyle(4)} onClick={() => this.SetImage(4)}>Dorsiflex</button>
                                <button className={this.InvertStyle()} onClick={()=>this.Invert()}>Invert</button>
                            </div>
                            <div className="submissionRK">
                                <button className="btn-ghost-white" onClick={this.props.close}>Back to exercise</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connectRekinnect(JennyEx1Rekinnect);
