import React from 'react';
import JennyConc from '../../components/conclusions/JennyConc';
import JennyEx1 from '../../components/interactive/JennyEx1';
import ChoicesMC from '../../components/interactive/ChoicesMC';
import Choices3D from '../../components/interactive/Choices3D';
import TutorialMC from '../../components/tutorials/TutorialMC';
import { VISUAL_ASSET_TYPES } from '../../constants';
import JennyEx1Rekinnect from '../../components/rekinnect/JennyEx1Rekinnect';
import ex3ModelObjects from './ex3ModelObjects';
import TutorialDrag from '../../components/tutorials/TutorialDrag';
import Tutorial3dselect from '../../components/tutorials/Tutorial3dSelect';

export default {
    title: 'The Case of the Volleyball Player',
    name: 'Jenny',
    profilePicSrc: '../img/jenny/profile.svg',
    introAsset: {
        type: 'video',
        vidSrc: "../vid/jenny-intro.mp4",
        captionSrc: "../vid/jenny-captions.vtt",
    },
    observations: [
        "landed on an inverted ankle", 
    ],
    conclusion: JennyConc,
    exercises: [
        
        // Exercise 1: Affected Anatomy

        {
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Ankle ligament strains vs. positions',
                paragraph: <p>We know that Jenny landed on an inverted ankle. Depending on whether her ankle was positioned in dorsiflexion, neutral or plantarflexion, different ankle ligaments could have been strained. 
                    <br />
                    <br /><b>Drag</b> the ligament that would most likely be injured given each position.</p>,
                hint: 'Think about where the ligaments are attaching around the ankle and foot. The location of each ligament’s attachment point informs what type of movement it restricts.',
            },
            notes: [
                "dorsiflexion + inversion → post. talofibular lig.",
                "neutral + inversion → calcaneofibular lig.",
                "plantarflexion + inversion → ant. talofibular lig."
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "Not quite!",
                paragraph: "Before you can move on, complete the Re-kinnect exercise.",
                numberOfAppears: 1,
                isRekinnect: true
            },
            maxSelCount: 3,
            interactiveArea: JennyEx1,
            rekinnect: JennyEx1Rekinnect,
            
            tutorial: TutorialDrag,
        },

        // Exercise 2: Injured ankle ligament

        {
            questionArea: {
                smallHeading: 'Exercise 2:',
                largeHeading: 'Injured ankle ligament',
                paragraph: <p>
                    Based on Jenny’s ankle position when she fell, which ligament was most likely damaged?
                    <br />
                    <br />Watch the video to observe her ankle position.
                    </p>,
                hint: 'Identify the positioning of her injury first. Then, review each ligament\'s role in resisting combined positions of the ankle and subtalar joints.',
            },
            notes: [
                "sprained her anterior talofibular ligament (ATFL)"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "",
                paragraph: "",
                numberOfAppears: 0,
                isRekinnect: false
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/jenny-ex2.mp4",
                visualAlt: "",
                sliderText: []
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Ant. talofibular lig.",
                    "Post. talofibular lig.",
                    "Calcaneofibular lig."
                ],
                answer:"Ant. talofibular lig.",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 3: Additional muscular injuries

        {
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Additional muscular injuries',
                paragraph: <p>
                    On the 3D model, <b>click and select</b> the leg muscles that are most likely to also be damaged from this ankle sprain.
                    <br />
                    <br />You can <b>zoom</b>, <b>pan</b> or <b>rotate</b> using the buttons on the right, or click and drag directly on the model.
                    </p>,
                hint: 'Think about agonist vs. antagonist relationships. When agonist muscles contract and shorten, antagonist lengthen.',
            },
            notes: [
                "also injured her fibularis longus and brevis muscles"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "Are you sure?",
                paragraph: "Her foot is inverted but not actively. Which muscles are antagonistic for inversion?",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 2,
            interactiveArea: Choices3D,
            interactiveAreaProps: {
                objects: ex3ModelObjects,
                answers : [
                    'fibularisLongus',
                    'fibularisBrevis',
                ],
                xOffset: 0,
                yOffset: 10,
                zOffset: 0,
                zRotOffset: 0,
                cameraDistance: 30,
                frontCubeFaceSrc: 'models/ui/CubeFaces_Anterior.jpg',
                backCubeFaceSrc: 'models/ui/CubeFaces_Posterior.jpg',
                rightCubeFaceSrc: 'models/ui/CubeFaces_Medial.jpg',
                leftCubeFaceSrc: 'models/ui/CubeFaces_Lateral.jpg',
                topCubeFaceSrc: 'models/ui/CubeFaces_Superior.jpg',
                bottomCubeFaceSrc: 'models/ui/CubeFaces_Inferior.jpg',
            },
            tutorial: Tutorial3dselect,
        },

    ]
};