import React, { Fragment } from "react";
import PNGSliderWithToggle from "../animations/PNGSliderWithToggle";
import connectRekinnect from "./connectRekinnect";

function BenEx4Rekinnect(props) {
  if (!props.showingRekinnect) return null;

  return (
    <div id="contentRK">
      <section>
        <div className="questionArea">
          <div className="questionWrap">
            <div className="questionRK">
              <h1>Re-kinnect:</h1>
              <h2>Arm Lift</h2>
              <p>
                Let's observe arm swing is more limited in the hyperkyphotic
                spine compared to the aligned spine.
                <br />
                <br />
                1. <b>Click</b> the <b>trapezius and pectoralis major</b> and{" "}
                <b>supraspinatus</b>
                buttons to observe the muscular excursion during arm raising.
                <br />
                <br />
                2. <b>Drag the slider</b> to toggle and observe how muscles, the
                scapula, and tendon are impacted during arm lift in the
                different spinal alignments.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="interactiveArea">
          <div className="interactiveWrap">
            <div className="visualRK">
              <div id="bm" style={{ marginBottom: "16px" }}>
                <PNGSliderWithToggle
                  text={["Netural arm", "", "Lifted arm"]}
                  toggleText={[
                    "Trapezius and pectoralis major",
                    "Supraspinatus",
                  ]}
                  toggleDescription={[
                    "Compare how the back and chest muscle anatomy are changed in a hyperkyphotic posture.",
                    <Fragment>
                      In the hyperkyphotic spine, the scapula is more protracted
                      to start with. This will limit upward rotation. This
                      prevents the humerus from reaching full elevation.
                      <br />
                      <br />
                      The space between the scapula and humerus also is reduced
                      in the hyperkyphotic spine at the end of the movement and
                      the supraspinatus tendon is compressed in the reduced
                      space.
                    </Fragment>,
                  ]}
                  frames={[
                    [
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00001.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00002.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00003.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00004.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00005.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00006.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00007.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00008.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00009.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00010.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00011.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00012.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00013.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00014.png",
                      "../img/ben/rk/rk_4_PectoralisMajorAndTrapezius/rk_4_PectoralisMajorAndTrapezius_00015.png",
                    ],
                    [
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00001.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00002.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00003.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00004.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00005.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00006.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00007.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00008.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00009.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00010.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00011.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00012.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00013.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00014.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00015.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00016.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00017.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00018.png",
                      "../img/ben/rk/rk_4_supraspinatus/rk_4_supraspinatus_00019.png",
                    ],
                  ]}
                />
              </div>
            </div>
            <div className="submissionRK" style={{ marginTop: "16px" }}>
              <button className="btn-ghost-white" onClick={props.close}>
                Back to exercise
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connectRekinnect(BenEx4Rekinnect);
