import React from 'react';
import ChoicesMC from '../../components/interactive/ChoicesMC';
import Choices3D from '../../components/interactive/Choices3D';
import FredConc from  '../../components/conclusions/FredConc';
import exercise3obj from './exercise3obj';
import FredEx4Rekinnect from '../../components/rekinnect/FredEx4Rekinnect';
import { VISUAL_ASSET_TYPES } from '../../constants';
import TutorialMC from '../../components/tutorials/TutorialMC';
import Tutorial3dSelect from '../../components/tutorials/Tutorial3dSelect';


export default {
    title: 'The Case of the Weightlifter',
    name: 'Fred',
    profilePicSrc: '../img/fred/profile.svg',
    introAsset: {
        type: 'video',
        vidSrc: "../vid/fred-intro.mp4",
        captionSrc: "../vid/fred-captions.vtt",
    },
    observations: [
        "lifts heavy objects all the time",
        "chronic back pain and limp",
    ],
    conclusion: FredConc,
    exercises: [

        // Exercise 1: Muscles for wind-up vs. kick

        {
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Affected joint',
                paragraph: <p>Referring to Fred’s gait cycle in the coronal plane, select the joint where the limp is occurring.
                    <br />
                    <br /> Watch the video to observe his gait cycle.
                    </p>,
                hint: 'The affected joint should look unbalanced and asymmetric.',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/fred-ex1.mp4",
                visualAlt: "",
            },
            notes: [
                "limp occurs at right hip joint",
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Left hip joint",
                    "Right hip joint",
                    "Left knee joint",
                    "Right knee joint",
                    "Left ankle joint",
                    "Right ankle joint"
                ],
                answer:"Right hip joint",
                choicesPerRow:3,
            },
            tutorial: TutorialMC,
        },

        // Exercise 2: Gait cycle

        {
            questionArea: {
                smallHeading: 'Exercise 2:',
                largeHeading: 'Gait cycle',
                paragraph: <p>By comparing Fred’s affected gait to an unaffected one, which phase of the gait cycle is affected?
                    <br />
                    <br />Watch the video to observe both gait cycles.
                    </p>,
                hint: 'Does the hip drop occur when you have one or both feet on the ground?',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/fred-ex2.mp4",
                visualAlt: "",
            },
            notes: [
                "limp occurs at right hip joint during midstance period",
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's incorrect.",
                paragraph: "What is the gait phase during which the hip joint is the most active?",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Initial contact",
                    "Foot flat",
                    "Midstance period",
                    "Toe off",
                    "Pre-swing",
                    "Mid-swing",
                    "Terminal swing"
                ],
                answer:"Midstance period",
                choicesPerRow:3,
            },
            tutorial: TutorialMC,
        },

        // Exercise 3: Affected muscles
        {
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Affected muscles',
                paragraph: <p>During the midstance period of Fred’s gait cycle, his right hip drops abnormally. 
                    In a normal midstance period, the hips remain level.
                    <br /> 
                    <br /><b>Click and select</b> the <b>two</b> muscles that control this action.
                    <br />
                    <br /><b>Zoom</b>, <b>pan</b> and <b>rotate</b> using the buttons on the right, or click and drag directly on the model.
                    </p>,
                hint: 'Stand up and try this movement out yourself! Which muscles would you contract to maintain your right hip level?',
            },
            notes: [
                "left gluteus medius and minimus aren’t contracting to abduct the right hip"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's incorrect.",
                paragraph: "Remember, muscles on the contralateral side need to contract to abduct the hip in order to keep the pelvis level. There are two muscles involved in this movement.",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 2,
            interactiveArea: Choices3D,
            interactiveAreaProps: {
                objects: exercise3obj,
                answers: [
                    'lGluteusMinimus',
                    'lGluteusMedius',
                ],
                xOffset: 0,
                yOffset: -4.6,
                zOffset: 0,
                zRotOffset: 0,
                cameraDistance: 23,
                frontCubeFaceSrc:'models/ui/CubeFaces_Anterior.jpg',
                backCubeFaceSrc:'models/ui/CubeFaces_Posterior.jpg',
                rightCubeFaceSrc:'models/ui/CubeFaces_Left.jpg',
                leftCubeFaceSrc:'models/ui/CubeFaces_Right.jpg',
                topCubeFaceSrc:'models/ui/CubeFaces_Superior.jpg',
                bottomCubeFaceSrc:'models/ui/CubeFaces_Inferior.jpg',
            },
            tutorial: Tutorial3dSelect,
        },

        // Exercise 4: Muscles for wind-up vs. kick

        {
            questionArea: {
                smallHeading: 'Exercise 4:',
                largeHeading: 'Contraction type',
                paragraph: <p>When gluteus medius and minimus are unaffected, what type of contraction normally occurs during the midstance period of the gait cycle?
                    <br />
                    <br />Watch the video to observe the gait cycle.
                    </p>,
                hint: 'What kind of contraction of any muscle is typical when you need to resist gravity?',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/fred-ex4.mp4",
                visualAlt: "",
            },
            notes: [
                "left gluteus medius and minimus aren’t contracting eccentrically to abduct the right hip",
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's not quite right!",
                paragraph: "Before you can move on, complete the Re-kinnect exercise.",
                numberOfAppears: 1,
                isRekinnect: true
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Eccentric",
                    "Concentric",
                    "Isometric",
                ],
                answer:"Eccentric",
                choicesPerRow:3,
            },
            rekinnect: FredEx4Rekinnect,
            tutorial: TutorialMC,
        },

        // Exercise 5: Muscles for wind-up vs. kick

        {
            questionArea: {
                smallHeading: 'Exercise 5:',
                largeHeading: 'Nerve Supply',
                paragraph: 'If the gluteus medius and minimus are not contracting eccentrically, perhaps the issue is neural. Which nerve innervates these muscles?',
                hint: 'Refer to your notes. Pretty sure you know this.',
            },
            notes: [
                "superior gluteal nerve innervates gluteus medius and minimus",
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Superior gluteal n.",
                    "Sciatic n.",
                    "Inferior gluteal n.",
                    "Pudendal n."
                ],
                answer:"Superior gluteal n.",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

    ]
};
