import React,{ Component} from 'react';

import Lottie from './Lottie';

class Slider extends Component{
     
  constructor(props) {
    super(props);
    this.state = {frame:0};

    this.updateSlider = this.updateSlider.bind(this);

    this.svgRef = React.createRef() 

  }

  componentDidUpdate(){
    this.svgRef.current.goToAndStop(this.state.frame*40);
  }

  updateSlider(evt){
        this.setState({'frame':evt.target.value})
  }

  render() {
    const defaultOptions = {
        loop: false,
        autoplay: false, 
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
        path : this.props.path //'../bodymovin/bernie/bernie-ex3.json'

      };
    
    return (
            <div>
                <Lottie 
                    ref={this.svgRef} 
                    options={defaultOptions} 
                    isStopped={this.state.isStopped} 
                    isPaused={this.state.isPaused}
                />
                <div className="sliderCont">
                    <input id="slider" type="range" value={this.state.frame} min="0" max="24" onChange={ evt => this.updateSlider(evt)} />
                    <div className="sliderfull">
                        <p>{this.props.Text[0]}</p>
                    </div>
                    <div className="slidertext-left">
                        <p>{this.props.Text[1]}</p>
                    </div>
                    <div className="slidertext-right">
                        <p>{this.props.Text[2]}</p>
                    </div>
                </div>
            </div>
        );
  }
}

export default Slider;