import React from 'react';
import './css/App.css';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import kinundrumApp from './reducers';

import Sidebar from './components/navigation/Sidebar';
import Home from './components/views/Home';
import User from './components/views/User';
import CaseStudyRouter from './components/CaseStudyRouter';
import Admin from './components/views/Admin';
import PrivateRoute from './components/authentication/PrivateRoute';
import LoginRoute from './components/authentication/LoginRoute';
import Login from './components/views/Login';

const store = createStore(
    kinundrumApp,
);

const InvalidPage = ()=> <Redirect to='/login' />;

const App = () => {
    return(
        <Provider store={store}>
            <div className="App">
                <Sidebar/>
                <Switch>
                    <PrivateRoute exact path='/' component={Home} />
                    <PrivateRoute exact path='/attempt/:casestudy' component={CaseStudyRouter}/>
                    <PrivateRoute exact path='/review/:casestudy' component={CaseStudyRouter}/>
                    <PrivateRoute exact path='/redo/:casestudy' component={CaseStudyRouter}/>
                    <PrivateRoute path='/user' component={User}/>
                    <PrivateRoute path='/admin' component={Admin}/>
                    <LoginRoute path='/login' component={Login}/>
                    <Route component={InvalidPage} />
                </Switch>
            </div>
        </Provider>
    );
};
            
export default withRouter(App);
