import React, { useState } from "react";
import caseStudies from "../../casestudies";
export const HeadingInput = ({ heading, id, handleKeyUp = () => {} }) => {
  return (
    <div className="heading-input">
      <h4>{heading}</h4>
      <input type="text" id={id} onKeyUp={handleKeyUp} />
    </div>
  );
};

export const RoleInput = ({ heading, id }) => {
  return (
    <div className="heading-input">
      <h4>{heading}</h4>
      <select id={id} style={{ marginBottom: "16px" }}>
        <option value="student">Student</option>
        <option value="seniorstudent">Senior Student</option>
        <option value="admin">Admin</option>
      </select>
    </div>
  );
};

export const SetProgressInput = () => {
  const caseStudyNames = Object.keys(caseStudies);

  const [exerciseCount, setExerciseCount] = useState(
    caseStudies[caseStudyNames[0]].exercises.length
  );

  const handleCaseStudyChange = (e) => {
    setExerciseCount(caseStudies[e.target.value].exercises.length);
  };

  return (
    <div className="heading-input" style={{ marginBottom: "16px" }}>
      <h4>Set Exercise Progress</h4>
      <select
        id="caseStudySelect"
        onChange={handleCaseStudyChange}
        style={{ marginRight: "8px" }}
      >
        {caseStudyNames.map((name) => (
          <option value={name}>{name}</option>
        ))}
      </select>
      <select id="progressSelect">
        {[...Array(exerciseCount + 2).keys()].map((value) => (
          <option value={value}>{value}</option>
        ))}
      </select>
    </div>
  );
};
