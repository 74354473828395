import React, {Component} from 'react';

export default class IncorrectOverlayRK extends Component {
    render(){
        return(
            <div id="contentEX">
                <div className="overlay-white"/>
                <div id="incorrectRK">
                    <div className="incorrectText">
                        <p>
                            <strong>{this.props.strong}</strong>
                        </p>
                        <p>{this.props.paragraph}</p>
                        <button className="btn-ghost-ready" onClick={this.props.onRekinnectClick}>Re-kinnect</button>
                    </div>

                </div>
            </div>
        );
    }

}