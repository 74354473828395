import React, {Component} from 'react';
import { connect } from 'react-redux';

class ButtonClear extends Component{
    constructor(props){
        super(props);

        this.buttonStyle = this.buttonStyle.bind(this);
    }


    buttonStyle(){
        if (this.props.event === 'solved' || this.props.reviewMode) {return "btn-ghost disabled"}
        if (this.props.event ==='failed') { return "btn-ghost-incorrect"}


        if (this.props.canClear) {
            return "btn-ghost";
        } else {
            return "btn-ghost disabled";
        }
    }


    render(){
        const { onClearClick } = this.props;
        return(
            <button className={this.buttonStyle()} id="clear" onClick={onClearClick}>Clear answer</button>
        );
    }
}

const mapStateToProps = state => {

    const { caseStudy, page } = state.navigation;
    const progressToCheck = state.navigation.mode === 'redo' ? 'resolved' : 'solved';
    const solved = state.progress[caseStudy][progressToCheck];
    return {
        event: state.exercise.event,
        canClear: state.exercise.currSelCount > 0,
        reviewMode: state.navigation.mode === 'review' || page <= solved,
    }
}

export default connect(mapStateToProps)(ButtonClear);
