import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { setPage as setPageAction } from "../../actions/navigation";
import { useDispatch } from "react-redux";

const BernieConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Calcaneal Tendon Rupture
              </h2>

              <p>
                When Bernie lunged to reach the tennis ball, she forcefully
                pushed off with her foot while her knee was straightened. This
                caused her calcaneal tendon to become stretched and torn. As
                with most calcaneal tendon rupture injuries, there was the sound
                of a loud pop and she felt sudden and severe pain at the back of
                the ankle and/or calf. Since the calceneal tendon connects the
                dominant plantar flexors, the soleus and gastrocnemius, to the
                calcaneus (heel) bone, Bernie is unable to push off (plantar
                flex) while walking. The calcaneal tendon may become weak and
                thin with age.
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Calcaneal tendon (Achilles tendon) connects the soleus,
                  gastrocnemius and plantaris muscles to the calcaneus (heel)
                  bone. These muscles act via the tendon to plantar flex the
                  foot at the ankle joint, and flex the knee (except soleus)
                </li>
                <li>
                  Rule of muscles: the fewer joints a muscles crosses, the
                  stronger the muscle will act on that joint
                </li>
                <li>
                  When considering injury with multiple muscles that perform the
                  same actions, the muscles that act most dominantly on that
                  joint are more likely to be injured
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img
                src="../../img/bernadette/conc.svg"
                alt="diagram of torn calcaneal tendon"
              />
              <figcaption>
                Figure 1. Diagram of Bernie's calcaneal tendon tearing during
                plantarflexion push-off
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Affected anatomy</b>
                          <br />
                          Considering the mechanism of injury, list three
                          structures that most likely caused Bernie's pain.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/bernadette/flowchart-1.png"
                            alt="exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row-answers">
                      <ul>
                        <li className="flo-box9a correct">
                          Gastrocnemius
                          <br />
                          &nbsp;
                        </li>
                        <li className="flo-box9 correct">
                          Soleus
                          <br />
                          &nbsp;
                        </li>
                        <li className="flo-box9 incorrect tooltip">
                          Tibialis ant.
                          <br />
                          <i>(incorrect)</i>
                          <span className="tooltiptext">
                            Reconsider which compartment of the leg the injured
                            structures would be located.
                          </span>
                        </li>
                        <li className="flo-box9">
                          Tibialis
                          <br />
                          post.
                        </li>
                        <li className="flo-box9">
                          Flexor digit.
                          <br />
                          long.
                        </li>
                        <li className="flo-box9 incorrect tooltip">
                          Extensor digit. long. <i>(incorrect)</i>
                          <span className="tooltiptext">
                            Reconsider which compartment of the leg the injured
                            structures would be located.
                          </span>
                        </li>
                        <li className="flo-box9 correct">
                          Calcaneal
                          <br />
                          tendon
                        </li>
                        <li className="flo-box9">Post. talonavicular lig.</li>
                        <li className="flo-box9">
                          Plantar
                          <br />
                          fascia
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Affected movement</b>
                          <br />
                          Based on Bernie's limp, what type of movement is she
                          unable to perform?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/bernadette/flowchart-2.png"
                            alt="exrecise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about how Bernie’s gait cycle compares to a
                          normal gait cycle. What component looks different?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a">Dorsiflexion</li>
                        <li className="flo-box4 correct">Plantarflexion</li>
                        <li className="flo-box4">Inversion</li>
                        <li className="flo-box4">Eversion</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Dominant plantarflexors</b>
                          <br />
                          Out of these five muscles involved in plantarflexion,
                          which are the two dominant ones?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/bernadette/flowchart-3.svg"
                            alt="exrecise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Remember the ‘rule’ of muscles: the more joints that a
                          muscle passes over, the less powerful they are for
                          each action.{" "}
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">Gastrocnemius</li>
                        <li className="flo-box5 correct">Soleus</li>
                        <li className="flo-box5">Tibialis post.</li>
                        <li className="flo-box5">FHL</li>
                        <li className="flo-box5">FDL</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Kinnect the dots!</b>
                          <br />
                          Since Bernie can’t plantarflex, which tendon has
                          likely been injured?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/bernadette/flowchart-4.svg"
                            alt="exrecise 4"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about the location of the pain and which tendon
                          would most likely be in that region.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">Calcaneal tendon</li>
                        <li className="flo-box4">Tibialis post. tendon</li>
                        <li className="flo-box4">FDL tendon</li>
                        <li className="flo-box4">FHL tendon</li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default BernieConc;
