import { LOAD_USER } from '../actions/authentication';

const initialState = {
    isAuthorized: false,
    token: "",
    studentNumber: "",
    role: "",
};

const auth =  (state = initialState, action) => {
    switch(action.type) {
        case LOAD_USER:
            const { token, studentNumber, role } = action.serverRes;
            return {
                ...state,
                isAuthorized: token && studentNumber,
                token,
                studentNumber,
                role,
            };
        default:
            return state;
    }
}

export default auth;