import { connect } from 'react-redux';
import { correctAnswer, incorrectAnswer, startGuessing, updateSelCount } from '../../actions/exercise';
import caseStudies from '../../casestudies';

import { postDataSecure, sendDataSecure } from '../../api';

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const { studentNumber, token, role } = state.authentication;

    const exerciseIndex = page - 2;
    const exerciseData = caseStudies[caseStudy].exercises[exerciseIndex];
    const { maxSelCount } = exerciseData;

    const progressToCheck = state.navigation.mode === 'redo' ? 'resolved' : 'solved';
    const solved = state.progress[caseStudy]['solved'];
    const resolved = state.progress[caseStudy]['resolved'];
    const currentSolved = state.progress[caseStudy][progressToCheck];
    return {
        event: state.exercise.event,
        maxSelCount,
        studentNumber,
        token,
        role,
        page,
        caseStudy,
        reviewMode: state.navigation.mode === 'review' || page <= currentSolved,
        solved,
        resolved,
        mode: state.navigation.mode,
    }
}

const mapDispatchToProps = (dispatch) => ({
    correctAnswer: successMessage => {
        dispatch(correctAnswer(successMessage));
    },
    incorrectAnswer: failureMessage => {
        dispatch(incorrectAnswer(failureMessage));
    },
    startGuessing: () => {
        dispatch(startGuessing());
    },
    updateSelCount: selCount => {
        dispatch(updateSelCount(selCount));
    }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        correctAnswer: (successMessage) => {
            let body = new FormData();
            body.append("exercise", JSON.stringify({
                "Name": stateProps.caseStudy,
                "Solved": stateProps.mode === 'redo' ? stateProps.solved : stateProps.page,
                "Resolved": stateProps.mode === 'redo' ? stateProps.page : stateProps.resolved,
                "VideoSeen": true,
            }));
            postDataSecure(`exercises/${stateProps.studentNumber}`, body, stateProps.token);

            dispatchProps.correctAnswer(successMessage)
        },
        incorrectAnswer: (failureMessage) => {
            const { studentNumber, role, token, caseStudy, page } = stateProps;

            let body = new FormData();
            body.append('role', role);
            body.append('studentNumber', studentNumber);
            body.append('exerciseName', caseStudy);
            body.append('exerciseNumber', page - 1);
            body.append('mode', stateProps.mode === 'redo' ? 'resolve' : 'normal');

            sendDataSecure('data/incorrect', body, 'POST', token);
            dispatchProps.incorrectAnswer(failureMessage);
        }
    }
}

const connectInteractive = interactiveComponent => {
    return connect(mapStateToProps, mapDispatchToProps, mergeProps)(interactiveComponent);
};

export default connectInteractive;
