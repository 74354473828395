import React from 'react';
import AutoAnimation from '../animations/AutoAnimation';
import { hideTutorial } from '../../actions/exercise';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    showingTutorial: state.exercise.showingTutorial,
})

const mapDispatchToProps = dispatch => ({
    handleClick: () => dispatch(hideTutorial()),
})

const TutorialMC = ({showingTutorial, handleClick}) => {
    if (!showingTutorial) return null;

    return(
        <div id='overlay-tut'>  
            <div className='overlay-cont'>
            <div className="overlay-tut-exercise">

                <div className="tut-header">
                    <button className="btnClose" aria-label="Close" title="Close" onClick={handleClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="tut-body">
                    <p>
                        <b>This is a multiple choice exercise</b>.
                        <br />
                        <br /> Click on a radio button to select it.
                        <br />
                        <br /> If you change your mind, click on another radio button. You can only select one option!</p>

                    <div className="tut-anim">
                        <AutoAnimation path="../../bodymovin/tutorials/tut-mc.json"/>
                    </div>
                </div>

                <div className="tut-button" onClick={handleClick}>
                    <a className="btn-ghost">Got it!</a>
                </div>

                </div>
            </div>
        </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(TutorialMC);