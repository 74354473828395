import React, {Component} from 'react';
import { EVENT_TYPES } from '../../constants';
import { DragArea, draggable, dragContainer } from '../exercises/DragAndDrop'
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';

const { EVENT_GUESSING } = EVENT_TYPES;

const CHOICES = [
    'Hamstrings',
    'Quadriceps',
    'Gluteus maximus',
    'Iliopsoas',
    'Rectus femoris',
];

const ANSWER = {
    windUp:[
        'Hamstrings',
        'Gluteus maximus',
    ],
    kick:[
        'Quadriceps',
        'Iliopsoas',
        'Rectus femoris',
    ],
};

class AlexEx1 extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            unselected: CHOICES,
            windUp: [],
            kick: [],
            numberOfWrongChoices: 0,
            currentlyHoveredContainer: "",
        }

        this.getCurrSelCount = this.getCurrSelCount.bind(this);
        this.handleSubmissionClick = this.handleSubmissionClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleDragDrop = this.handleDragDrop.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.getWrongChoices = this.getWrongChoices.bind(this);
    }

    getCurrSelCount(){
        return CHOICES.length - this.state.unselected.length;
    }

    handleSubmissionClick(){
        const containerNames = Object.keys(ANSWER);
        let numberOfWrongChoices = 0;
        for(let i = 0; i < containerNames.length; i++){
            const containerName = containerNames[i];
            numberOfWrongChoices += this.getWrongChoices(containerName).length;
        }
        if (numberOfWrongChoices > 0){
            this.props.incorrectAnswer('Try again. ✕');
        } else {
            this.props.correctAnswer('That\'s correct! ✔');
        }
    }

    handleClearClick(){
        if(this.props.event === EVENT_GUESSING){
            this.setState({
                unselected: CHOICES,
                windUp: [],
                kick: [],
            })
            return;
        }
        let wrongChoices = {};
        let allWrongChoices = [];
        let nextContainers = {};
        const containerNames = Object.keys(ANSWER);
        for(let i = 0; i < containerNames.length; i++){
            const containerName = containerNames[i];
            nextContainers[containerName] = [...this.state[containerName]];
            wrongChoices[containerName] = this.getWrongChoices(containerName);
            allWrongChoices.push(...this.getWrongChoices(containerName))
            for(let j = 0; j < wrongChoices[containerName].length; j++){
                const wrongChoice = wrongChoices[containerName][j];
                const indexOfWrongChoice = nextContainers[containerName].indexOf(wrongChoice);
                nextContainers[containerName].splice(indexOfWrongChoice, 1);
            }
        }
        this.setState({
            event: EVENT_GUESSING,
            unselected: allWrongChoices,
            windUp: nextContainers['windUp'],
            kick: nextContainers['kick'],

        })
        this.props.updateSelCount(nextContainers['windUp'].length + nextContainers['kick'].length);

    }

    getWrongChoices(containerName){
        let wrongChoices = [];
        const container = this.state[containerName];
        const answerContainer = ANSWER[containerName];
        for(let i = 0; i < container.length; i++){
            const choice = container[i];
            const answerIndex = answerContainer.indexOf(choice);
            if(answerIndex === -1){
                wrongChoices.push(choice);
            }
        }
        return wrongChoices;
    }

    handleDragDrop(choice, prevContainer, nextContainer){
        if (prevContainer === nextContainer) return;
        let prevContainerObjects = [...this.state[prevContainer]];
        let nextContainerObjects = [...this.state[nextContainer]];
        const choiceIndex = prevContainerObjects.indexOf(choice);
        prevContainerObjects.splice(choiceIndex, 1);
        nextContainerObjects.push(choice);

        let currSelCount = this.getCurrSelCount();
        if (prevContainer === 'unselected') currSelCount ++;
        if (nextContainer === 'unselected') currSelCount --;
        this.props.updateSelCount(currSelCount);    

        this.setState({
            event: EVENT_GUESSING,
            [prevContainer]: prevContainerObjects,
            [nextContainer]: nextContainerObjects,
            currentlyHoveredContainer: "",
        })
    }

    handleHover(containerID){
        this.setState({currentlyHoveredContainer: containerID})
    }

    render(){

        const windUpClass = this.state.currentlyHoveredContainer === 'windUp' ? "hovered" : "unhovered";
        const kickClass = this.state.currentlyHoveredContainer === 'kick' ? "hovered" : "unhovered";

        let windUpRender;
        let kickRender;
        let unselectedRender;
        if (this.props.reviewMode){
            windUpRender = ANSWER['windUp'];
            kickRender = ANSWER['kick']
            unselectedRender = [];
        } else {
            windUpRender = this.state.windUp;
            kickRender = this.state.kick;
            unselectedRender = this.state.unselected;
        }

        return (
            <div>
                <DragArea 
                onDragDrop={this.handleDragDrop}
                onHover={this.handleHover}
                >
                    <div className="choicediv-alex1">
                        <div className="dragtocol">
                            <p>Wind-up</p>
                            {dragContainer(
                                <div id="dragto-windup" className={windUpClass}>
                                    {windUpRender.map(choice=>renderChoice(choice))}
                                </div>,
                                'windUp'
                            )}
                        </div>
                        <div className="dragfromcol">
                        {dragContainer(
                            unselectedRender.map(choice=>renderChoice(choice)),
                            'unselected'
                        )}
                        </div>
                        
                        <div className="dragtocol">
                        <p>Kick</p>
                            {dragContainer(<div id="dragto-kick" className={kickClass}>
                                {kickRender.map(choice=>renderChoice(choice))}
                            </div>,
                            'kick')}
                        </div>
                    </div>
                </DragArea>
                <SubmissionArea 
                onSubmissionClick={this.handleSubmissionClick}
                onClearClick={this.handleClearClick}
                />
            </div>
        )
    }

}

const renderChoice = (choice, choiceType)=>{
    if(choice.search(choiceType) !== -1){
        return draggable(
            <div className="dragchoice">{choice}</div>,
            choice
        );
    } else {
        return null
    } 

}

export default connectInteractive(AlexEx1);
