import React, { useState } from 'react';
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';


const ChoicesCB = props => {

    const {
        maxSelCount,
        updateSelCount,
        reviewMode,
        answers,
        correctAnswer,
        incorrectAnswer,
        options,
        choicesPerRow,
        event,
        images,
    } = props;

    const [ selected, setSelected ] = useState([]);
    const [ correct, setCorrect ] = useState([]);

    const handleChoiceClick = str => {
        let copyOfSelected = [...selected];
        const selectedInd = selected.indexOf(str);
        const alreadySelected = selectedInd > -1;
        if (alreadySelected){
            copyOfSelected.splice(selectedInd, 1);
        } else if(copyOfSelected.length < maxSelCount) {
            copyOfSelected.push(str);
        }
        updateSelCount(copyOfSelected.length);
        setSelected(copyOfSelected);
    }

    const handleSubmissionClick = () => {
        let correct = [];
        for (let answer of answers) {
            if (selected.indexOf(answer) > -1) {
                correct.push(answer);
            }
        }

        if (correct.length === answers.length) {
            correctAnswer('That\'s correct! ✔');
        } else {
            const numIncorrect = maxSelCount - correct.length;
            incorrectAnswer(numIncorrect + ' of ' + maxSelCount + ' answers is incorrect. Try again. ✕');
            setCorrect(correct);
        }
    }

    const handleClearClick = () => {
        updateSelCount(correct.length);
        setSelected(correct)
    }

    const boxClass = index => {
        if (isChecked(index) && correct.indexOf(options[index]) === -1 && event === 'failed') {
            return "choice inc-check";
        }
        return "choice def-check";
    }

    const isDisabled = index => {
        let ind = selected.indexOf(options[index]);
        return (selected.length === maxSelCount && ind === -1) || (event === 'solved');
    }

    const isChecked = index => {
        if ( reviewMode ) return answers.indexOf(options[index]) !== -1;
        return selected.indexOf(options[index]) !== -1;
    }

    const renderRows = () => {
        const numRows = Math.ceil(options.length / choicesPerRow);
        let rows = [];
        for(let i = 0; i < numRows; i++){
            rows.push(renderRow(i))
        }
        return rows;
    }

    const renderRow = rowIndex => {
        const startIndex = rowIndex * choicesPerRow;
        let choices = [];
        for(let i = startIndex; i < startIndex + choicesPerRow; i++){
            const str = options[i];
            choices.push(renderChoice(str, i));
        }
        return(
            <div className="choiceRow" key={"col" + rowIndex}>
                {choices}
            </div>
        )
    }

    const renderChoice = (str, ind) => {
        let visibility = "visible";
        if (!str) visibility = "hidden";
        return(
            <li className={boxClass(ind)} key={"choice" + ind} style={{visibility}}>
                
                <span>
                    {renderImage(ind)}
                    <input type="checkbox"
                        disabled={isDisabled(ind)}
                        checked={isChecked(ind)}
                        id={"option" + ind} name="checkbox"
                        onChange={() => handleChoiceClick(str)} 
                    />
            
                    <label htmlFor={"option" + ind}>
                        <span></span>
                        {str}
                    </label>
                </span>      
                
            </li>
        );
    }

    const renderImage = ind => {
        if(!images) return null;
        return(
            <div>
                <div className="img">
                    <img src={images[ind]} alt={options[ind]}></img>
                </div>
            </div>
        );
    }

    return (
        <div>
            <ul id="choices">
                {renderRows()}
            </ul>
            <SubmissionArea 
            onSubmissionClick={handleSubmissionClick} 
            onClearClick={handleClearClick}
            />
        </div>
        
    )
    
}

export default connectInteractive(ChoicesCB);
