import React from 'react';
import ChoicesMC from '../../components/interactive/ChoicesMC';
import VanConc from '../../components/conclusions/VanConc';
import ChoicesCB from '../../components/interactive/ChoicesCB';
import Draw3D from '../../components/interactive/Draw3D';
import TutorialCB from '../../components/tutorials/TutorialCB';
import TutorialMC from '../../components/tutorials/TutorialMC';
import Tutorial3dDraw from '../../components/tutorials/Tutorial3dDraw';
import { VISUAL_ASSET_TYPES } from '../../constants';
import ex2ModelObjects from './ex2ModelObjects';


export default {
    title: 'The Case of the Rebel Driver',
    name: 'Van Diesel',
    profilePicSrc: '../../img/van/profile.svg',
    introAsset: {
        type: 'video',
        vidSrc: "../vid/van-intro.mp4",
        captionSrc: "../vid/van-captions.vtt",
    },
    observations: [
        "knee hit the dashboard at high velocity", 
    ],
    conclusion: VanConc,
    exercises: [

        // Exercise 1: Affected Anatomy

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.IMAGE,
                visualSource: "../img/van/ex-1.svg",
                visualAlt: "Van's knee hitting the dashboard with arrows indicating plane of displacement",
                sliderText: []
            },
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Anatomy overview',
                paragraph: <p>Considering the plane in which the injury occurs, choose the <b>three</b> most likely structures that could be damaged by the impact.</p>,
                hint: 'Think about the structures that limit the movement of the knee joint in the plane of the accident.',
            },
            notes: [
                "anterior-posterior direction of injury → ACL/PCL most likely injured"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's not quite right!",
                paragraph: "The direction of impact informs which structures could be injured.",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps: {
                options:[
                    "Post. cruciate lig.", 
                    "Ant. cruciate lig.",
                    "Lat. cruciate lig.", 
                    "Med. cruciate lig.",
                    "Patellar lig.", 
                    "Menisci"
                ],
                answers:[
                    "Post. cruciate lig.",
                    "Ant. cruciate lig.",
                    "Patellar lig.",
                ],
                choicesPerRow:3,
            },
            tutorial: TutorialCB,
            rekinnect: null
        },

        // Exercise 2: Cruciate ligaments

        {
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Cruciate ligaments',
                paragraph: <p>
                        Cruciate ligaments were most likely involved. 
                        Let’s identify the attachment points for the ACL and PCL. 
                        <br />
                        <br />
                        You can <b>zoom</b>, <b>pan</b> and <b>rotate</b> the 3D model using the buttons on the right, or click and drag directly on the model.
                        <br />
                        <br />
                        <b>1.</b> Select the ligament you want to draw using the ACL/PCL toggle 
                        <br />
                        <br />
                        <b>2.</b> Click on the origin and insertion points on the 3D model
                        <br />
                        <br />
                        <b>3</b>. Select the other ligament and repeat step 2
                        <br />
                        <br />
                        <b>4</b>. It'll be easier with your atlas open
                    </p>,
                hint: 'Remember that the name of the ligament is the biggest hint to the origin.',
            },
            notes: [
                "ACL origin: lateral femoral condyle",
                "ACL insertion: anterior tibial plateau",
                "PCL origin: medial femoral condyle",
                "PCL insertion: posterior tibial eminence"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "",
                paragraph: "",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 2,
            interactiveArea: Draw3D,
            interactiveAreaProps: {
                objects:ex2ModelObjects,
                answers:{
                    acl:{
                        from:'attach_tib_2',
                        to:'attach_fem_3',
                    },
                    pcl:{
                        from: 'attach_fem_1',
                        to: 'attach_tib_4',
                    },
                },
                xOffset:0,
                yOffset:-16.35,
                zOffset:-5,
                zRotOffset:-0.55,
                cameraDistance:10,
                frontCubeFaceSrc:'models/ui/CubeFaces_Anterior.jpg',
                backCubeFaceSrc:'models/ui/CubeFaces_Posterior.jpg',
                rightCubeFaceSrc:'models/ui/CubeFaces_Medial.jpg',
                leftCubeFaceSrc:'models/ui/CubeFaces_Lateral.jpg',
                topCubeFaceSrc:'models/ui/CubeFaces_Superior.jpg',
                bottomCubeFaceSrc:'models/ui/CubeFaces_Inferior.jpg',
            },
            tutorial: Tutorial3dDraw,
            rekinnect: null
        },

        // Exercise 3: Kinnect the dots

        {
            visualAsset: {
                type: VISUAL_ASSET_TYPES.SLIDER,
                visualSource: "../bodymovin/van/van-ex3.json",
                visualAlt: "Van's knee hitting the dashboard with arrows indicating the direction of impact",
                sliderText: ['Neutral', 'Posterior', 'Anterior']
            },
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Kinnect the dots',
                paragraph: "Based on the direction his knee hit the dashboard, which structure must have been damaged?",
                hint: 'The orientation of each ligament affects how it limits movement anteriorly or posteriorly.',
            },
            notes: [
                "PCL limits posterior translation of tibia relative to femur → PCL most likely injured"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "That's not quite right!",
                paragraph: "Before you can move on, complete the Re-kinnect exercise.",
                numberOfAppears: 1,
                isRekinnect: false
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Post. cruciate lig.",
                    "Ant. cruciate lig."
                ],
                answer:"Post. cruciate lig.",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

    ]
};