import React from 'react';
import { connect } from 'react-redux';
import caseStudies from '../../casestudies';
import Slider from '../animations/Slider';
import { VISUAL_ASSET_TYPES } from '../../constants';

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const exerciseIndex = page - 2;
    const exerciseData = caseStudies[caseStudy].exercises[exerciseIndex];

    return {
        VisualAssetConfig: exerciseData.visualAsset,
    }
}

const VisualAsset = ({ VisualAssetConfig}) => {
    if (!VisualAssetConfig) return <div/>;
    switch (VisualAssetConfig.type) {
        case VISUAL_ASSET_TYPES.IMAGE:
            return (
                <div id="visualAsset">
                    <img src={VisualAssetConfig.visualSource} alt={VisualAssetConfig.visualAlt} />
                </div>
            )
        case VISUAL_ASSET_TYPES.VIDEO:
            return (
                <div id="visualAsset">
                    <video className="introVidTag" controls>
                        <source src={VisualAssetConfig.visualSource} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        case VISUAL_ASSET_TYPES.SLIDER:
            return (
                <div id="visualAsset">
                    <Slider path={VisualAssetConfig.visualSource} Text={VisualAssetConfig.sliderText}/>
                </div>
            );
        default:
            return null;
    }
}

export default connect(mapStateToProps)(VisualAsset);