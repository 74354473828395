import React, { useState } from 'react';
import {connect} from 'react-redux';

import Topnav from '../navigation/Topnav';

import ManageUsers from '../admin/manageUsers';
import AppStats from '../admin/appStats';

const Admin = ({token}) => {
    const [page, setPage] = useState('manage-users');

    return (
        <div className='Admin'>
            <Topnav page='admin' {...{setPage}}/>
            <div className='content-wrapper'>
                {page === 'manage-users' && <ManageUsers {...{token}} />}
                {page === 'app-stats' && <AppStats {...{token}} />}
            </div>
        </div> 
    );
}

const mapStateToProps = state => ({
    'token': state.authentication.token
});

export default connect(mapStateToProps)(Admin);