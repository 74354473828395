import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const LoginRoute = (props) => {
    if (props.isAuthorized) {
        return props.role === 'admin' ? <Redirect to='/admin'/> : <Redirect to='/'/>;
    }
    return <Route  {...props} />
}

const mapStateToProp = ({ authentication }) => {
    const { isAuthorized, role } = authentication;  
    return {
        isAuthorized,
        role,
    }
}

export default connect(mapStateToProp)(LoginRoute);
