import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { setPage as setPageAction } from "../../actions/navigation";
import { useDispatch } from "react-redux";

const FredConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) =>
    dispatch(setPageAction(page + 1), [dispatch])
  );

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Trendelenberg gait
              </h2>

              <p>
                After several months of repetitive heavy lifting from
                powerlifting and construction work, Fred has been experiencing
                chronic back pain and limping. Specifically, Fred’s right hip
                drops abnormally in the midstance period. Normally, the left
                gluteus medius and minimus contract eccentrically to abduct the
                right hip. Since the superior gluteal nerve supplies these
                muscles, dysfunction of this nerve can explain why these muscles
                are weakened.{" "}
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Phases of the gait cycle, eccentric contraction, nerve
                  dysfunction
                </li>
                <li>
                  Activities that involve repetitive bending and heavy lifting
                  (flexion and extension of the back), including weightlifting
                  and shovelling, can lead to disc problems. Disc protrusion can
                  occur, pushing and moving laterally to pinch nerve roots
                </li>
                <li>
                  Trendelenburg gait is an abnormal gait characterized by hip
                  dropping in the midstance period of the gait cycle. It is
                  caused by weakness of the gluteus medius and gluteus minimus
                  muscles, which can result from superior gluteal nerve
                  dysfunction
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img
                src="../img/fred/conc.svg"
                alt="diagram of posterior view of gluteus medius and minimus unable to eccentrically contract due to pinched superior gluteal nerve"
              />
              <figcaption>
                Figure 1. Diagram of Fred's abnormal right hip drop during the
                midstance phase. The left gluteus medius and minimus are unable
                to contract and become weakened due to nerve dysfunction
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Affected joint</b>
                          <br />
                          Referring to Fred’s gait cycle in the coronal plane,
                          select the joint where the limp is occurring.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/fred/flowchart-1.png"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          The affected joint should look unbalanced and
                          asymmetric.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a">Left hip joint</li>
                        <li className="flo-box6 correct">Right hip joint</li>
                        <li className="flo-box6">Left knee joint</li>
                        <li className="flo-box6">Right knee joint</li>
                        <li className="flo-box6">Left ankle joint</li>
                        <li className="flo-box6">Right ankle joint</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Gait cycle</b>
                          <br />
                          By comparing Fred’s affected gait to an unaffected
                          one, which phase of the gait cycle is affected?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/fred/flowchart-2.png"
                            alt="Visual asset for Exercise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Does the hip drop occur when you have one or both feet
                          on the ground?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box7a">Initial contact</li>
                        <li className="flo-box7">Foot flat</li>
                        <li className="flo-box7 correct">Midstance period</li>
                        <li className="flo-box7">Toe off</li>
                        <li className="flo-box7">Pre-swing</li>
                        <li className="flo-box7">Mid-swing</li>
                        <li className="flo-box7">Terminal swing</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Affected muscles</b>
                          <br />
                          During the midstance period of Fred’s gait cycle, his
                          right hip drops abnormally. In a normal midstance
                          period, the hips remain level. Click and select the
                          two muscles that control this action.
                        </a>
                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/fred/flowchart-3.png"
                            alt="Visual asset for Exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Stand up and try this movement out yourself! Which
                          muscles would you contract to maintain your right hip
                          level?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a">Right gluteus maximus</li>
                        <li className="flo-box6">Right gluteus medius</li>
                        <li className="flo-box6">Right gluteus minimus</li>
                        <li className="flo-box6">
                          Right <br />
                          iliopsoas
                        </li>
                        <li className="flo-box6">
                          Right <br />
                          rectus femoris
                        </li>
                        <li className="flo-box6">
                          Right <br />
                          hip adductors
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a">Left gluteus maximus</li>
                        <li className="flo-box6 correct">
                          Left gluteus medius
                        </li>
                        <li className="flo-box6 correct">
                          Left gluteus minimus
                        </li>
                        <li className="flo-box6">
                          Left <br />
                          iliopsoas
                        </li>
                        <li className="flo-box6">
                          Left <br />
                          rectus femoris
                        </li>
                        <li className="flo-box6">
                          Left <br />
                          hip adductors
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Contraction type</b>
                          <br />
                          When gluteus medius and minimus are unaffected, what
                          type of contraction normally occurs during the
                          midstance period of the gait cycle?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/fred/flowchart-4.png"
                            alt="Visual asset for Exercise 4"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          What kind of contraction of any muscle is typical when
                          you need to resist gravity?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box3a correct">Eccentric</li>
                        <li className="flo-box3">Concentric</li>
                        <li className="flo-box3">Isometric</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 5" onClick={() => setPage(5)}>
                          <b>Exercise 5: Nerve supply</b>
                          <br />
                          If the gluteus medius and minimus are not contracting
                          eccentrically, perhaps the issue is neural. Which
                          nerve innervates these muscles?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Refer to your notes. Pretty sure you know this.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">Sup. gluteal n.</li>
                        <li className="flo-box4">Sciatic n.</li>
                        <li className="flo-box4">Inf. gluteal n.</li>
                        <li className="flo-box4">Pudendal n.</li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default FredConc;
