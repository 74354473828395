import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Browser Router is used for routing between pages
 */
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render((
    <BrowserRouter >
        <App />
    </BrowserRouter>
), document.getElementById('root'));
unregister();
