import React from "react";
import PNGSliderWithToggle from "../animations/PNGSliderWithToggle";
import connectRekinnect from "./connectRekinnect";

function BenEx2Rekinnect(props) {
  if (!props.showingRekinnect) return null;

  return (
    <div id="contentRK">
      <section>
        <div className="questionArea">
          <div className="questionWrap">
            <div className="questionRK">
              <h1>Re-kinnect:</h1>
              <h2>Stretched VS. Compressed Structures</h2>
              <p>
                Observe how spinal muscles and ligaments are changing their
                original configuration during spinal flexion. Are they
                lengthening (stretching) or shortening (compressing)?
                <br />
                <br />
                1. <b>Click</b> the muscles and ligaments buttons to view them
                separately.
                <br />
                <br />
                2. <b>Drag the slider</b> to toggle and compare structures in the
                neutral and flexed spine.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="interactiveArea">
          <div className="interactiveWrap">
            <div className="visualRK">
              <div id="bm">
                <PNGSliderWithToggle
                  text={["Neutral", "", "Hyperkyphotic"]}
                  toggleText={["Muscles", "Ligaments"]}
                  frames={[
                    [
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00001.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00002.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00003.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00004.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00005.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00006.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00007.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00008.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00009.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00010.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00011.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00012.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00013.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00014.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00015.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00016.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00017.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00018.png",
                      "../img/ben/rk/rk_2_muscle/rk_2_muscle_00019.png",
                    ],
                    [
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00001.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00002.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00003.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00004.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00005.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00006.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00007.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00008.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00009.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00010.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00011.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00012.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00013.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00014.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00015.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00016.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00017.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00018.png",
                      "../img/ben/rk/rk_2_ligament/rk_2_ligament_00019.png",
                    ],
                  ]}
                />
              </div>
            </div>
            <div className="submissionRK">
              <button className="btn-ghost-white" onClick={props.close}>
                Back to exercise
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connectRekinnect(BenEx2Rekinnect);
