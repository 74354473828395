import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const JennyConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Injured antero-talofibular ligament
              </h2>

              <p>
                When Jenny landed, her foot was inverted and plantarflexed.
                Thus, her anterior talofibular ligament (ATFL) was torn. Because
                this ligament limits inversion, Jenny would experience pain when
                she actively and passively inverts her ankle. Since fibularis
                longus and brevis act to evert the ankle, their tendons lengthen
                during inversion. Therefore, they are also susceptible to injury
                when the ankle is inverted too much. If this happens, Jenny
                would also experience pain when she actively everts her ankle.
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Ligaments are connective tissue that act to stabilize joints
                  and limit movement
                </li>
                <li>
                  When ligaments are stretched beyond their anatomical limit,
                  they can be torn. However, ligaments are not the only
                  structures that may be damaged. As dynamic movers of the joint
                  that may also be overly stretched
                </li>
                <li>
                  It is important to not only consider the main affected
                  structure but also the surrounding anatomy such as muscle
                  tendons, vessels and nerves that could also be affected by the
                  injury
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img src="../img/jenny/conc.svg" alt="diagram of torn ATF" />
              <figcaption>
                Figure 1. Diagram of Jenny's ATFL tearing when she lands on an
                inverted foot
                <br />
                during plantarflexion
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>
                            Exercise 1: Ankle ligament strains vs. positions
                          </b>
                          <br />
                          We know that Jenny landed on an inverted ankle.
                          Depending on whether her ankle was positioned in
                          either dorsiflexion, neutral or plantarflexion,
                          different ankle ligaments could have been strained.
                          Drag the ligaments to match the correct ankle
                          position.{" "}
                        </a>

                        <span className="tooltiptext">
                          <img
                            src="../img/jenny/flowchart-1.svg"
                            className="flo-img"
                            alt="Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          The location of each ligament’s attachment points
                          inform what type of movement it restricts.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box3a correct">
                          Plantarflexed + inverted:
                          <br /> ATFL
                        </li>
                        <li className="flo-box3 correct">
                          Neutral + inverted:
                          <br /> CFL
                        </li>
                        <li className="flo-box3 correct">
                          Dorsiflexed + inverted:
                          <br /> PTFL
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Injured ankle ligament</b>
                          <br />
                          Based on Jenny’s ankle position when she fell, which
                          ligament was most likely damaged?
                        </a>

                        <span className="tooltiptext">
                          <img
                            src="../../img/jenny/flowchart-2.png"
                            className="flo-img"
                            alt="exercise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Identify the positioning of her injury first. Review
                          each ligament's role in resisting combined positions
                          of ankle and subtalar joints.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box3a correct">ATFL</li>
                        <li className="flo-box3">PTFL</li>
                        <li className="flo-box3">CFL</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-3a"></div>
                      <div className="lh-3"></div>
                      <div className="lh-3"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw3"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Additional muscular injuries</b>
                          <br />
                          On the 3D model, click and select the leg muscles that
                          are most likely to also be damaged from this ankle
                          sprain.
                        </a>
                        <span className="tooltiptext">
                          <img
                            src="../../img/jenny/flowchart-3.png"
                            className="flo-img"
                            alt="exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about agonist vs. antagonist relationships. When
                          agonist muscles contract and shorten, antagonist
                          lengthen.{" "}
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a correct">Fibularis longus</li>
                        <li className="flo-box6 correct">Fibularis brevis</li>
                        <li className="flo-box6">Gastrocnemius</li>
                        <li className="flo-box6">Soleus</li>
                        <li className="flo-box6">Tibialis ant.</li>
                        <li className="flo-box6">Ext. digit. long.</li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default JennyConc;

