import React from "react";
import connectRekinnect from "./connectRekinnect";

function BenEx1Rekinnect(props) {
  if (!props.showingRekinnect) return null;

  return (
    <div id="contentRK">
      <section>
        <div className="questionArea">
          <div className="questionWrap">
            <div className="questionRK">
              <h1>Re-kinnect:</h1>
              <h2>Compare X-ray</h2>
              <p>
                Let’s compare Gary's radiograph to a neutral spine to find out
                what condition Gary has.
                <br />
                <br />
                Look closely at each region of the spine in both radiographs to
                determine what looks abnormal.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="interactiveArea">
          <div className="interactiveWrap">
            <div className="visualRK">
              <img src="../img/ben/rk/rk_1.png" className="rkasset video" />
            </div>
            <div className="submissionRK">
              <button className="btn-ghost-white" onClick={props.close}>
                Back to exercise
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connectRekinnect(BenEx1Rekinnect);
