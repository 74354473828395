import React, {Component} from 'react';
import HoverAnimation from '../../components/animations/HoverAnimation';
import { connect } from 'react-redux'
import caseStudies from '../../casestudies';
import { showRekinnect } from '../../actions/exercise';
import { sendDataSecure } from '../../api';
/**
 * The Question area for exercises
 * 
 * Props:
 * smallHeading - The small heading in the question area. 
 * largeHeading - The larger heading in the question area.
 * paragraph - The paragraph in the question area.
 * hint - The hint for the question area
 * 
 * Optonial Props:
 * hasReckinnect - boolean to say whether or not you render the rekinnect button
 * ToggleReckinnect - the function called when you click the rekinnect button
 */

class ButtonRekinnect extends Component{
    render(){
        return(
            <button id="textRK" onClick={this.props.Toggle}>
                <p>Click here to try out the <br /><strong>Re-kinnect exercise</strong></p>
                <div id="bmicon">
                    <HoverAnimation path='../../bodymovin/rekinnect/rekinnect.json'/>
                </div>
            </button>
        );
    }
}

class QuestionArea extends Component{

    constructor(props){
        super(props);
        this.ToggleHint = this.ToggleHint.bind(this);
        this.state = {
            'showHint': false,
        }
    }

    ToggleHint(){

        if (!this.state.showHint) {
            this.props.logHint();
        }

        this.setState({
            'showHint': !this.state.showHint
        });
    }

    render(){
        var panelStyle = this.state.showHint ? {maxHeight:"250px"} : {maxHeight:null};
        const {
            smallHeading,
            largeHeading,
            paragraph,
            hint,
            hasRekinnect,
            showRekinnect,
        } = this.props;

        return(
                <section>
                    <div className="questionArea">
                        <div className="questionWrap">
                            <div className="question">
                                <h1>{smallHeading}</h1>
                                <h2>{largeHeading}</h2>
                                <p>{paragraph}</p>
                            </div>
                            { hint &&
                            <div id="hint">
                                <button className={this.state.showHint ? "btn-link accordion active" : "btn-link accordion"} onClick={this.ToggleHint}>
                                    <div className="triangle-right"></div>Need a hint?</button>
                                <div className="panel" style={panelStyle}>
                                    <p>{hint}</p>
                                </div>
                            </div>
                            }
                            {hasRekinnect ? <ButtonRekinnect Toggle={showRekinnect} /> : null}
                        </div>
                    </div>
                </section>
        );
    }

}

QuestionArea.defaultProps = {
    'hasRekinnect' : false,
    'ToggleRekinnect' : null
}

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const exerciseIndex = page - 2;
    const exerciseData = caseStudies[caseStudy].exercises[exerciseIndex];
    const { questionArea } = exerciseData;
    return {
        smallHeading: questionArea.smallHeading,
        largeHeading: questionArea.largeHeading,
        paragraph: questionArea.paragraph,
        hint: questionArea.hint,
        hasRekinnect: exerciseData.rekinnect !== null && exerciseData.rekinnect !== undefined,
        authentication: state.authentication,
        caseStudy,
        page,
    }
};

const mapDispatchToProps = dispatch => ({
    showRekinnect: () => dispatch(showRekinnect()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showRekinnect: () => {
        const { token, role, studentNumber } = stateProps.authentication;
        const { caseStudy } = stateProps;
        
        let body = new FormData();
        body.append('role', role);
        body.append('studentNumber', studentNumber);
        body.append('exerciseName', caseStudy);
        
        sendDataSecure('data/rekinnect', body, 'POST', token);

        dispatchProps.showRekinnect();
    },
    logHint: () => {
        const { token, role, studentNumber} = stateProps.authentication;
        const { caseStudy, page } = stateProps;

        let body = new FormData();
        body.append('role', role);
        body.append('studentNumber', studentNumber);
        body.append('exerciseName', caseStudy);
        body.append('exerciseNumber', page - 1);

        sendDataSecure('data/hint', body, 'POST', token);
    }
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(QuestionArea);
