import React, {Component} from 'react';
import PNGSlider from '../animations/PNGSlider';
import connectRekinnect from './connectRekinnect';
class FredRekinnect extends Component {
    render() {
        if (!this.props.showingRekinnect) return null;
        return (
            <div id="contentRK">
                <section>
                    <div className="questionArea">
                        <div className="questionWrap">
                            <div className="questionRK">
                                <h1>Re-kinnect:</h1>
                                <h2>Gluteus medius and minimus contraction</h2>
                                <p>Let’s compare how the gluteus medius and minimus act during concentric vs. eccentric contractions. 
                                    <br />
                                    <br /><b>Drag the slider</b> to toggle between the types of contractions.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="interactiveArea">
                        <div className="interactiveWrap">
                            <div className="visualRK">
                                
                                {/*-- slider component --*/}
                                <div id="bm">
                                    <PNGSlider
                                    text={['Lift it','Keep it level', 'Lower it']}
                                    description={[
                                        'While standing on the supporting leg, hiking up the opposite side of the pelvis and leg would require the concentric contraction of the gluteus medius/minimus on the supporting leg side. Since the supporting leg is not moving in space, the muscles will use their "normal" action but still shorten to pull the pelvis over the femur and result in the opposite side pelvis to move superiorly. (Note that the free leg muscles might also work concentrically to keep the leg away from the midline).',
                                        'While standing on the supporting leg, the opposite side of the pelvis and leg are subjected to gravity wanting to pull it down. In order to keep your pelvis level, your gluteus medius/minimus on the supporting leg side would isometrically contract as you are neither lifting nor lowering the pelvis but just fighting your own body weight and gravity. The muscle fibers are staying the same length but still creating enough tension to hold everything level.',
                                        'In order to lower the opposite side pelvis and leg, the gluteus medius/minimus of the supporting leg will work eccentrically. These muscles will control the descent of the pelvis/leg against the pull of gravity by lengthening while still creating tension. This type of tension is often the greatest of all muscle contractions, force-wise.',
                                    ]}
                                    frames={[
                                        '/img/fred/rk_00.png',
                                        '/img/fred/rk_01.png',
                                        '/img/fred/rk_02.png',
                                        '/img/fred/rk_03.png',
                                        '/img/fred/rk_04.png',
                                        '/img/fred/rk_05.png',
                                        '/img/fred/rk_06.png',
                                        '/img/fred/rk_07.png',
                                        '/img/fred/rk_08.png',
                                        '/img/fred/rk_09.png',
                                        '/img/fred/rk_10.png',
                                        '/img/fred/rk_11.png',
                                        '/img/fred/rk_12.png',
                                        '/img/fred/rk_13.png',
                                        '/img/fred/rk_14.png',
                                        '/img/fred/rk_15.png',
                                        '/img/fred/rk_16.png',
                                    ]}
                                    />
                                </div>
                            </div>
                            <div className="submissionRK">
                                <button className="btn-ghost-white" onClick={this.props.close}>Back to exercise</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connectRekinnect(FredRekinnect);
