export default {

    lAdductors:{
        url: 'models/Fred_Pelvis/LAdductors.obj',
        color: '0xe03e74',
        tooltip: 'left hip adductors',
        isChoice: true
    },

    lGluteusMaximus:{
        url: 'models/Fred_Pelvis/LGluteusMaximus.obj',
        color: '0xe03e74',
        tooltip: 'left gluteus maximus',
        isChoice: true,
    },

    lGluteusMedius:{
        url: 'models/Fred_Pelvis/LGluteusMedius.obj',
        color: '0xe03e74',
        tooltip: 'left gluteus medius',
        isChoice: true
    },

    lGluteusMinimus:{
        url: 'models/Fred_Pelvis/LGluteusMinimus.obj',
        color: '0xe03e74',
        tooltip: 'left gluteus minimus',
        isChoice: true
    },

    lIlliopsoas:{
        url: 'models/Fred_Pelvis/LIlliopsoas.obj',
        color: '0xe03e74',
        tooltip: 'left illiopsoas',
        isChoice: true
    },

    lRectusFemoris:{
        url: 'models/Fred_Pelvis/LRectusFemoris.obj',
        color: '0xe03e74',
        tooltip: 'left rectus femoris',
        isChoice: true
    },

    rAdductors:{
        url: 'models/Fred_Pelvis/RAdductors.obj',
        color: '0xe03e74',
        tooltip: 'right hip adductors',
        isChoice: true
    },

    rGluteusMaximus:{
        url: 'models/Fred_Pelvis/RGluteusMaximus.obj',
        color: '0xe03e74',
        tooltip: 'right gluteus maximus',
        isChoice: true
    },

    rGluteusMedius:{
        url: 'models/Fred_Pelvis/RGluteusMedius.obj',
        color: '0xe03e74',
        tooltip: 'right glutues medius',
        isChoice: true
    },

    rGluteusMinimus:{
        url: 'models/Fred_Pelvis/RGluteusMinimus.obj',
        color: '0xe03e74',
        tooltip: 'right gluteus minimus',
        isChoice: true
    },

    rIlliopsoas:{
        url: 'models/Fred_Pelvis/RIlliopsoas.obj',
        color: '0xe03e74',
        tooltip: 'right illiopsoas',
        isChoice: true
    },

    rRectusFemoris:{
        url: 'models/Fred_Pelvis/RRectusFemoris.obj',
        color: '0xe03e74',
        tooltip: 'right rectus femoris',
        isChoice: true
    },
    lFemur:{
        url: 'models/Fred_Pelvis/LFemur.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    lFibula:{
        url: 'models/Fred_Pelvis/LFibula.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    lPatella:{
        url: 'models/Fred_Pelvis/LPatella.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    lTibia:{
        url: 'models/Fred_Pelvis/LTibia.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    pelvis:{
        url: 'models/Fred_Pelvis/Pelvis.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    rFemur:{
        url: 'models/Fred_Pelvis/RFemur.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    rFibula:{
        url: 'models/Fred_Pelvis/RFibula.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    rPatella:{
        url: 'models/Fred_Pelvis/RPatella.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    rTibia:{
        url: 'models/Fred_Pelvis/RTibia.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    sacrum:{
        url: 'models/Fred_Pelvis/Sacrum.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    spine:{
        url: 'models/Fred_Pelvis/Spine.obj',
        color: '0xefe8dc',
        isChoice: false
    },

}
