import React from 'react';
import { sendDataSecure } from '../../api';
import { saveAs } from 'file-saver';
import dateFormat from 'dateformat';

const downloadData = async (token, tableName) => {
    const now = Date.now()
    let response = await sendDataSecure(`admin/data/${tableName}?time=${now}`, null, 'GET', token);
    if (response.status >= 200 && response.status < 300) {
        let fileBlob = await response.blob();
        saveAs(fileBlob, `${dateFormat(now, "yyyy_mm_dd_")}${tableName}.csv`);
    }
}

const AppStats = ({token}) => {
    return (
        <div id='content'>
            <h1>Application Stats</h1>
            <br /><hr />
            <h4> Login Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'log_ins')}>Download Login stats</button>
            <h4> Review Reset Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'review_resets')}>Download Review Resets stats</button>
            <h4> User Profile Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'user_profiles')}>Download User Profile stats</button>
            <h4> Rekinnect Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'rekinnects')}>Download Rekinnect stats</button>
            <h4> Badge Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'badges')}>Download Badge stats</button>
            <h4> Hint Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'hints')}>Download Hint stats</button>
            <h4> Incorrect Stats </h4>
            <button className='btn-ghost' onClick={() => downloadData(token, 'incorrects')}>Download Incorrect stats</button>
        </div>
    )
}

export default AppStats;