import React from 'react';
import { connect } from 'react-redux';
import { showRekinnect, startGuessing } from '../../actions/exercise';
import IncorrectOverlay from '../overlays/IncorrectOverlay';
import IncorrectOverlayRK from '../overlays/IncorrectOverlayRK';
import caseStudies from '../../casestudies';
import { EVENT_TYPES } from '../../constants';
import { sendDataSecure } from '../../api';
const { EVENT_FAILED } = EVENT_TYPES;

const IncorrectOverlayWrapper = ({ incorrectOverlayConfig, event, failCount, showRekinnect, startGuessing, clearFunction }) => {
    if (!incorrectOverlayConfig) return null;
    const {show, numberOfAppears} = incorrectOverlayConfig;
    if (show && event === EVENT_FAILED && failCount <= numberOfAppears) {
        if (incorrectOverlayConfig.isRekinnect) {
            return <IncorrectOverlayRK 
                onRekinnectClick={() => {
                    showRekinnect();
                    clearFunction();
                    startGuessing();
                }}
                strong={incorrectOverlayConfig.strong}
                paragraph={incorrectOverlayConfig.paragraph}
            />    
        }
        return <IncorrectOverlay
            Close={()=>{
                startGuessing();
                clearFunction();
            }}
            strong={incorrectOverlayConfig.strong}
            paragraph={incorrectOverlayConfig.paragraph}
        />
    }
    return null;
};

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const exerciseIndex = page - 2;
    const exerciseData = caseStudies[caseStudy].exercises[exerciseIndex];
    const { incorrectOverlayConfig } = exerciseData;
    const { event, failCount, clearFunction } = state.exercise;
    return { 
        incorrectOverlayConfig, 
        event, 
        failCount,
        clearFunction,
        authentication: state.authentication,
        caseStudy,
    };
}

const mapDispatchToProps = dispatch => ({
    showRekinnect: ()=>dispatch(showRekinnect()),
    startGuessing: ()=>dispatch(startGuessing()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showRekinnect: () => {
        const { token, role, studentNumber } = stateProps.authentication;
        const { caseStudy } = stateProps;
        
        let body = new FormData();
        body.append('role', role);
        body.append('studentNumber', studentNumber);
        body.append('exerciseName', caseStudy);
        
        sendDataSecure('/data/rekinnect', body, 'POST', token);

        dispatchProps.showRekinnect();
    }
})


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IncorrectOverlayWrapper);
