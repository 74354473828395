import React, { useState, useEffect } from 'react';
import Model from '../exercises/Model/Model';
import SubmissionArea from '../exercises/SubmissionArea';
import { EVENT_TYPES } from '../../constants';
import connectInteractive from './connectInteractive';

const ORANGE = 0xde6e4f;
const GREEN = 0x35b8b8;
const BLUE = 0x98d6f9;
const WHITE = 0xdddddd;
const GREY = 0xcce5e8;
const LINE_COLORS = [ORANGE, GREEN];

const Draw3D = props => {

    const {
        updateSelCount,
        reviewMode,
        correctAnswer,
        incorrectAnswer,
        event,
        objects,
        answers,
        xOffset,
        yOffset,
        zOffset,
        zRotOffset,
        cameraDistance,
        frontCubeFaceSrc,
        leftCubeFaceSrc,
        backCubeFaceSrc,
        rightCubeFaceSrc,
        topCubeFaceSrc,
        bottomCubeFaceSrc
    } = props;

    const answerKeys = Object.keys(answers);
    let initialSelectionState = {};
    for(let i = 0; i < answerKeys.length; i++){
        initialSelectionState[answerKeys[i]] = {
            from: "",
            to: "",
        }
    }

    const [ hoveredKey, setHoveredKey ] = useState("");
    const [ selected, setSelected ] = useState(initialSelectionState);
    const [ currentLine, setCurrentLine ] = useState(answerKeys[0])
    let shapes = {};

    useEffect(()=>{
        if (selected[currentLine].from !== "" && selected[currentLine].to !== "") 
            setCurrentLine(getNextLine())

        // set number of answers selected
        let numSelected = 0
        for(let line of answerKeys){
            if (selected[line].from !== "" && selected[line].to !== "")
                numSelected++;
        }
        updateSelCount(numSelected);
    }, [selected])

    useEffect(()=>{
        giveAttachmentsCallbacks();
    }, [currentLine, selected]);

    const giveAttachmentsCallbacks = () => {
        const keys = Object.keys(objects);
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            if(objects[key].isAttachment === true){
                objects[key].callback = ()=>handleAttachmentClick(key);
                objects[key].hovercallback = ()=>handleHoverAttachment(key)
            }
        }
    }

    const handleSubmissionClick = () => {
        const keys = Object.keys(selected);
        let match = true; 
        for(let i = 0; i < keys.length; i++){
            const firstAttachmentCorrect = (
                selected[keys[i]].from === answers[keys[i]].from ||
                selected[keys[i]].from === answers[keys[i]].to
            );
            const secondAttachmentCorrect = (
                selected[keys[i]].to === answers[keys[i]].to ||
                selected[keys[i]].to === answers[keys[i]].from
            );

            if(!(firstAttachmentCorrect && secondAttachmentCorrect)){
                match = false;
                break;
            }
        }
        if (match) return correctAnswer('That\'s correct! ✔');
        return incorrectAnswer('Try again. ✕')
    }

    const handleClearClick = () => {
        setSelected(initialSelectionState);
        setCurrentLine(answerKeys[0]);
    }

    const handleAttachmentClick = selection => {

        if(event === EVENT_TYPES.EVENT_SOLVED) return;

        if(alreadySelected(selection)){
            const lineOfSelection = getLineOfSelection(selection);
            setCurrentLine(lineOfSelection);

            setSelected(selected => {
                if ( selected[lineOfSelection].from === selection ) {
                    return {
                        ...selected,
                        [lineOfSelection]: {
                            from: selected[lineOfSelection].to,
                            to: "",
                        }
                    }
                } else {
                    return {
                        ...selected,
                        [lineOfSelection]: {
                            from: selected[lineOfSelection].from,
                            to: "",
                        }
                    }
                }

            })

        } else {

            setSelected(selected => {

                if(selected[currentLine].from === ""){
                    return {
                        ...selected,
                        [currentLine]:{
                            from: selection,
                            to: "",
                        }
                    }
                } else if(selected[currentLine].to === ""){
                    return {
                        ...selected,
                        [currentLine]:{
                            from: selected[currentLine].from,
                            to: selection,
                        }
                    }
                } else {
                    return {
                        ...selected,
                        [currentLine]:{
                            from: selection,
                            to: "",
                        }
                    }
                }
                    
            })

        }

    }

    const handleHoverAttachment = key => {
        setHoveredKey(key)
    }

    const handleHoverEmpty = () => {
        setHoveredKey("");
    }

    const handleAClPress = () => {
        setCurrentLine('acl')
    }

    const handlePCLPress = () => {
        setCurrentLine('pcl')
    }

    const getLineOfSelection = (selection) => {

        const lines = Object.keys(selected);
        for(let i = 0; i < lines.length; i++){
            if (selected[lines[i]].from === selection || selected[lines[i]].to === selection) {
                return lines[i]
            }
        }
        return null;

    }

    const alreadySelected = (selection) => {
        let selectedArray = [];
        const lines = Object.keys(selected);
        for(let i = 0; i < lines.length; i++){
            const line = lines[i];
            const fromSelection = selected[line].from;
            const toSelection = selected[line].to;
            selectedArray.push(fromSelection, toSelection);
        }
        const selectionIndex =  selectedArray.indexOf(selection);
        if(selectionIndex === -1) return false;
        return true;
    }

    const getNextLine = () => {
        const lines = Object.keys(answers);
        for(let i = 0; i < lines.length; i++){
            if(lines[i] === currentLine){
                return lines[(i+1)%lines.length];
            }
        }
        return null;
    }

    const updateColors = () => {
        resetAttachmentColors();
        setNewAttachmentColors();
    }

    const resetAttachmentColors = () => {
        const keys = Object.keys(objects);
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            if(objects[key].isAttachment === true){
                if(key === hoveredKey)
                    objects[key].color = [GREY, BLUE, GREY];
                else 
                    objects[key].color = [WHITE, BLUE, WHITE];
            }
        }
    }

    const drawLines = () => {
        let selectedToRender;
        if(reviewMode) selectedToRender = answers;
        else selectedToRender = selected;

        let selectedKeys = Object.keys(selectedToRender);

        for(let i = 0; i < selectedKeys.length; i++){
            const line = selectedToRender[selectedKeys[i]];
            const firstAttachmentChosen = line.from !== "";
            const secondAttachmentChosen = line.to !== "";
            if(firstAttachmentChosen && secondAttachmentChosen) {
                addLineBetweenAttachments(
                    selectedKeys[i],
                    LINE_COLORS[i],
                    objects[line.from],
                    objects[line.to]
                )
            } 
        }
    }

    const addLineBetweenAttachments = (id, color, object1, object2) => {
        shapes[id] = {
            shape: 'line',
            color: color,
            x1: object1.x,
            y1: object1.y,
            z1: object1.z,
            x2: object2.x,
            y2: object2.y,
            z2: object2.z,
        }
    }

    const setNewAttachmentColors = () => {
        let selectedToRender;
        if(reviewMode) selectedToRender = answers;
        else selectedToRender = selected;

        let selectedKeys = Object.keys(selectedToRender);
        for(let i = 0; i < selectedKeys.length; i++){
            if(selectedToRender[selectedKeys[i]].from !== ""){
                const key = selectedToRender[selectedKeys[i]].from;
                objects[key].color = [WHITE, LINE_COLORS[i], WHITE];
            }
            if(selectedToRender[selectedKeys[i]].to !== ""){
                const key = selectedToRender[selectedKeys[i]].to;
                objects[key].color = [WHITE, LINE_COLORS[i], WHITE];
            }
        }
    }

    updateColors();
    drawLines();
    return (
        <div id="visual3D">
            <Model style={{position: 'relative',borderStyle: 'solid',}}
                shapes={shapes}
                objects={objects}
                xOffset={xOffset}
                yOffset={yOffset}
                zOffset={zOffset}
                zRotOffset={zRotOffset}
                cameraDistance={cameraDistance}
                noIntersectsCallback={handleHoverEmpty}
                frontCubeFaceSrc={frontCubeFaceSrc}
                backCubeFaceSrc={backCubeFaceSrc}
                rightCubeFaceSrc={rightCubeFaceSrc}
                leftCubeFaceSrc={leftCubeFaceSrc}
                topCubeFaceSrc={topCubeFaceSrc}
                bottomCubeFaceSrc={bottomCubeFaceSrc}
            >
                <div className="div-acl-pcl">
                    <button className="btn-acl" 
                        title="Draw ACL"
                        onClick={handleAClPress}
                    >
                        ACL
                    </button>

                    <button className="btn-pcl"
                        title="Draw PCL"
                        onClick={handlePCLPress}
                    >
                        PCL
                    </button>

                    <div style={{margin:'5px'}}>
                        Now drawing: {currentLine.toUpperCase()}
                    </div>
                </div>
            </Model>
            <SubmissionArea 
            onSubmissionClick={handleSubmissionClick} 
            onClearClick={handleClearClick}
            />
        </div>
    )
}

export default connectInteractive(Draw3D);

