import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { HeadingInput, RoleInput, SetProgressInput } from "./inputs";
import { sendDataSecure } from "../../api";

const LIST_MAX = 12;

const UserList = ({ users, setCurrUser }) => {
  const userList = users.map((user) => (
    <tr onClick={() => setCurrUser(user.studentNumber)}>
      <td>{user.studentNumber}</td>
      <td>{user.role}</td>
    </tr>
  ));

  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Username</th>
          <th>Role</th>
        </tr>
        {userList}
      </tbody>
    </table>
  );
};

const UserInfo = ({ currUser, setCurrUser, getAllUsers, token }) => {
  const [exercises, setExercises] = useState([]);
  const [progress, setProgress] = useState({});
  const [changePasswordMssg, setChangePasswordMssg] = useState(null);
  const [changeRoleMssg, setChangeRoleMssg] = useState(null);

  const getUser = async () => {
    let response = await sendDataSecure(
      `admin/user/${currUser}`,
      null,
      "GET",
      token
    );
    if (response.status >= 200 && response.status < 300) {
      let responseJSON = await response.json();
      setExercises(responseJSON.exercises);
      setProgress(responseJSON.progress);
    }
  };

  useEffect(() => {
    getUser();
    setChangePasswordMssg(null);
    document.getElementById("change-password").value = "";
  }, [currUser]);

  useEffect(() => {
    setChangePasswordMssg(null);
    setChangeRoleMssg(null);
  }, [currUser]);

  const changePassword = async () => {
    const newPassword = document.getElementById("change-password").value;

    let body = new FormData();
    body.append("studentNumber", currUser);
    body.append("password", newPassword);

    let response = await sendDataSecure(
      "admin/changepassword",
      body,
      "POST",
      token
    );
    if (response.status >= 200 && response.status < 300) {
      setChangePasswordMssg(
        <h4 style={{ color: "green" }}>Successfully changed password</h4>
      );
    } else {
      setChangePasswordMssg(
        <h4 style={{ color: "red" }}>Failed to changed password</h4>
      );
    }
  };

  const changeRole = async () => {
    const newRole = document.getElementById("change-role").value;

    let body = new FormData();
    body.append("studentNumber", currUser);
    body.append("role", newRole);

    let response = await sendDataSecure(
      "admin/changerole",
      body,
      "POST",
      token
    );
    if (response.status >= 200 && response.status < 300) {
      setChangeRoleMssg(
        <h4 style={{ color: "green" }}>Successfully changed role</h4>
      );
    } else {
      setChangeRoleMssg(
        <h4 style={{ color: "red" }}>Failed to changed role</h4>
      );
    }
    getAllUsers();
  };

  const setExerciseProgress = async () => {
    const caseStudy = document.getElementById("caseStudySelect").value;
    const progress = document.getElementById("progressSelect").value;

    let body = new FormData();
    body.append("studentNumber", currUser);
    body.append("caseStudy", caseStudy);
    body.append("progress", progress);

    let response = await sendDataSecure(
      "admin/setExerciseProgress",
      body,
      "POST",
      token
    );
    if (response.status >= 200 && response.status < 300) {
      getUser();
    }
  };

  const deleteUser = async () => {
    let response = await sendDataSecure(
      `admin/user/${currUser}`,
      null,
      "DELETE",
      token
    );
    if (response.status >= 200 && response.status < 300) {
      setCurrUser("");
      getAllUsers();
    }
  };

  const exerciseInfo = exercises.map((exercise) => (
    <tr>
      <td>{exercise.Name}</td>
      <td>{exercise.Solved}</td>
    </tr>
  ));

  const badgeNames = Object.keys(progress).filter((key) =>
    key.includes("Badge")
  );
  const progressInfo = badgeNames.map((badgeName) => (
    <tr>
      <td>{badgeName}</td>
      <td>{progress[badgeName].toString()}</td>
    </tr>
  ));

  return (
    <div>
      <h4>Selected Student: {currUser} </h4>
      <div id="user-info">
        <div className="info-block">
          <h4>Exercise Info</h4>
          <table className="table">
            <tbody>
              <tr>
                <th>Exercise</th>
                <th>Solved</th>
              </tr>
              {exerciseInfo}
            </tbody>
          </table>
        </div>
        <div className="info-block">
          <h4>Progress Info</h4>
          <table className="table">
            <tbody>
              <tr>
                <th>Badge</th>
                <th>Acquired</th>
              </tr>
              {progressInfo}
            </tbody>
          </table>
        </div>
        <div className="info-block" style={{ height: "200px" }}>
          <HeadingInput heading="New Password" id="change-password" />
          <button className="btn-ghost" onClick={changePassword}>
            Change Password
          </button>
          {changePasswordMssg}
          <RoleInput heading="New Role" id="change-role" />
          <button className="btn-ghost" onClick={changeRole}>
            Change Role
          </button>
          {changeRoleMssg}
          <SetProgressInput />
          <button className="btn-ghost" onClick={setExerciseProgress}>
            Set progress
          </button>
        </div>
      </div>
      <button className="btn-ghost-incorrect" onClick={deleteUser}>
        Delete User
      </button>
    </div>
  );
};

const EditUser = ({ allUsers, getAllUsers, token }) => {
  const [users, setUsers] = useState([]);
  const [currUser, setCurrUser] = useState("");
  const [page, setPage] = useState(0);

  const filterUsers = () => {
    const partialStudentNumber = document.getElementById("user-search").value;
    let filtered = allUsers.filter((user) =>
      user.studentNumber.includes(partialStudentNumber)
    );
    setUsers(filtered);
  };

  useEffect(() => {
    filterUsers();
  }, [allUsers]);

  const totalPages = Math.ceil(users.length / LIST_MAX);
  const usersFrom = page * LIST_MAX;
  const usersTo = Math.min(users.length, (page + 1) * LIST_MAX);

  useEffect(() => {
    setPage(Math.max(0, Math.min(page, totalPages - 1)));
  }, [users]);

  return (
    <div id="edit-user">
      <h1>Edit User</h1>
      <br />
      <hr />
      <div id="edit-user-content">
        <div id="user-table">
          <div id="search-bar">
            <HeadingInput
              heading="Search"
              id="user-search"
              handleKeyUp={filterUsers}
            />
          </div>
          <div id="page-control" style={{ marginBottom: "5px" }}>
            <p>
              <a
                className="noselect"
                style={{ cursor: "pointer" }}
                onClick={() => setPage(Math.max(page - 1, 0))}
              >
                {"<  "}
              </a>
              <b className="noselect">
                Page {Math.min(page + 1, totalPages)} of {totalPages}
              </b>
              <a
                className="noselect"
                style={{ cursor: "pointer" }}
                onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
              >
                {"  >"}
              </a>
            </p>
          </div>
          <UserList
            {...{ users: users.slice(usersFrom, usersTo), setCurrUser, token }}
          />
        </div>
        {currUser && (
          <UserInfo {...{ currUser, getAllUsers, setCurrUser, token }} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.authentication.token,
});

export default connect(mapStateToProps)(EditUser);
