import React from 'react';
import ChoicesMC from '../components/interactive/ChoicesMC';
import ChoicesCB from '../components/interactive/ChoicesCB';
import MaryemConc from '../components/conclusions/MaryemConc';
import { VISUAL_ASSET_TYPES } from '../constants';
import TutorialMC from '../components/tutorials/TutorialMC';
import TutorialCB from '../components/tutorials/TutorialCB';


export default {
    title: 'The Case of the Alpine Skier',
    name: 'Maryem',
    profilePicSrc: '../img/maryem/profile.svg',

    introAsset: {
        type: 'slideshow',
        slides: [
            <img src="../img/maryem/intro-scene1.png" alt="The Case of the Alpine Skier" />,
            <img src="../img/maryem/intro-scene2.png" alt="Maryem on skis on top of a ski hill" />,
            <img src="../img/maryem/intro-scene3.png" alt="Maryem in the middle of a slalom race" />,
            <img src="../img/maryem/intro-scene4.png" alt="Maryem crashes" />,
            <img src="../img/maryem/intro-scene5.png" alt="Maryem broke her right tibia" />,
            <img src="../img/maryem/intro-scene6.png" alt="Maryem in a cast confined to a wheelchair for two months" />,
            <img src="../img/maryem/intro-scene7.png" alt="Maryem's cast was finally removed" />,
            <video controls>
                <source src="../vid/maryem-intro.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>,
            <img src="../img/maryem/intro-scene8.png" alt="Maryem says 'Isn't this weird? I broke my right tibia...'" />,
            <img src="../img/maryem/intro-scene9.png" alt="'...but now my left knee hurts when I walk.'" />,
            <img src="../img/maryem/intro-scene10.png" alt="'What can explain all my symptoms?'" />,
            <img src="../img/maryem/intro-scene11.png" alt="Let's solve this kinundrum!" />,
        ],
    },
    observations: [
        "broke right tibia skiing",
        "cast and wheelchair for three months, recently removed",
        "left knee pain during walking, but not sitting",
        "gets tired easily, trouble walking "
    ],
    conclusion: MaryemConc,
    exercises: [

        // Exercise 1: Abnormal gait cycle (part 1)

        {
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Abnormal gait cycle (part 1)',
                paragraph: 'What part of Maryem’s gait cycle is abnormal in the sagittal plane? (Watch the right leg!)',
                hint: '',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/maryem-ex1.mp4",
                visualAlt: "",
            },
            notes: [
                "abnormal gait, no right heel strike",
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Heel strike",
                    "Foot flat",
                    "Toe off",
                    "Pre-swing",
                    "Mid-swing",
                    "Terminal swing"
                ],
                answer:"Heel strike",
                choicesPerRow:3,
            },
            tutorial: TutorialMC,
        },

        // Exercise 2: Normal gait cycle

        {
            questionArea: {
                smallHeading: 'Exercise 2:',
                largeHeading: 'Normal gait cycle',
                paragraph: 'Based on a normal gait cycle, at which joint does the heel strike occur?',
                hint: '',
            },
            notes: [
                "heel strike occurs at talocrural joint",
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Hip",
                    "Knee",
                    "Talocrural",
                    "Subtalar",
                    "Talocalcaneonavicular"
                ],
                answer:"Talocrural",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 3: Abnormal heel strike

        {
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Abnormal heel strike',
                paragraph: 'What two actions are permitted at the talocrural joint?',
                hint: '',
            },
            notes: [
                "dorsiflexion and plantarflexion occur at the talocrural joint",
            ],
            maxSelCount: 2,
            interactiveArea: ChoicesCB,
            interactiveAreaProps:{
                options:[
                    "Dorsiflexion",
                    "Plantarflexion", 
                    "Inversion", 
                    "Eversion"
                ],
                answers:[
                    "Dorsiflexion",
                    "Plantarflexion"
                ],
                choicesPerRow:1,
            },
            tutorial: TutorialCB,
        },

        // Exercise 4: Normal gait cycle
        // TODO: This exercise seems wrong, will clarify with Chelsea

        {
            questionArea: {
                smallHeading: 'Exercise 4:',
                largeHeading: 'Abnormal heel strike',
                paragraph: 'In Maryem’s case, she is unable to do what action during the heel strike?',
                hint: '',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/maryem-ex1.mp4",
                visualAlt: "",
            },
            notes: [
                "unable to dorsiflex"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Dorsiflex",
                    "Plantarflex",
                    "Invert",
                    "Evert"
                ],
                answer:"Dorsiflex",
                choicesPerRow:2,
            },
            tutorial: TutorialMC,
        },

        // Exercise 5: Dorsiflexion muscles

        {
            questionArea: {
                smallHeading: 'Exercise 5:',
                largeHeading: 'Dorsiflexion muscles',
                paragraph: <p>Yes, instead of dorsiflexing, Maryem is dropping her right toe and dragging it abnormally during the heel strike. 
                    <br />
                    <br />So, what are the muscles responsible for dorsiflexion?
                    </p>,
                hint: '',
            },
            notes: [
                "dorsiflexion: TA, EHL, EDL"
            ],
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps:{
                    options:[
                        "Soleus",
                        "Flexor digitorum longus", 
                        "Flexor hallucis longus", 
                        "Tibialis post.",
                        "Fibularis longus",
                        "Fibularis brevis",
                        "Tibialis ant.",
                        "Extensor hallucis longus",
                        "Extensor digitorum longus"
                    ],
                    answers:[
                        "Tibialis ant.",
                        "Extensor hallucis longus",
                        "Extensor digitorum longus"
                    ],
                    choicesPerRow:1,
            },
            tutorial: TutorialCB,
        },

        // Exercise 6: Foot drop cause

        {
            questionArea: {
                smallHeading: 'Exercise 6:',
                largeHeading: 'Foot drop cause',
                paragraph: 'Which of the following is the most likely to cause Maryem’s right foot drop?',
                hint: '',
            },
            notes: [
                "potential lack of innervation"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Muscle stiffness (post-cast)",
                    "Ligament restriction",
                    "Joint restriction",
                    "Lack of innervation"
                ],
                answer:"Lack of innervation",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 7: Denervation

        {
            questionArea: {
                smallHeading: 'Exercise 7:',
                largeHeading: 'Lack of innervation',
                paragraph: <p>Considering Maryem is unable to dorsiflex, which nerves could be dysfunctional in Maryem’s right leg? 
                    <br />
                    <br />Think about the relationships between nerves and how they connect to one another.
                    </p>,
                hint: '',
            },
            notes: [
                "potential lack of innervation of right common fib., sup. fib. n. and/or deep fib. n."
            ],
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps:{
                    options:[
                        "Common fibular n.",
                        "Superficial fibular n.", 
                        "Deep fibular n.", 
                        "Tibialis n.",
                    ],
                    answers:[
                        "Common fibular n.",
                        "Superficial fibular n.", 
                        "Deep fibular n.", 
                    ],
                    choicesPerRow:1,
            },
            tutorial: TutorialCB,
        },

        // Exercise 8: Kinnect the dots

        {
            questionArea: {
                smallHeading: 'Exercise 8:',
                largeHeading: 'Kinnect the dots',
                paragraph: 'Considering that the loss of innervation to the lower leg could explain Maryem’s symptoms, what is a possible explanation for how she damaged her nerve?',
                hint: '',
            },
            notes: [
                "lack of innervation of right common fib. n. due to compression of cast"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Maryem tore the nerve during her skiing accident",
                    "The cast compressed the common fibular nerve on the fibular head and damaged the nerve",
                    "From being confined to wheelchair, Maryem’s dorsiflexors atrophied and subsequently caused the superficial fibular nerve to atrophy as well",
                    "Compression from the cast decreased the blood supply to the common fibular nerve, which injured the nerve"
                ],
                answer:"The cast compressed the common fibular nerve on the fibular head and damaged the nerve",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 9: Abnormal gait cycle (part 2)

        {
            questionArea: {
                smallHeading: 'Exercise 9:',
                largeHeading: 'Abnormal gait cycle (part 2)',
                paragraph: <p><b>Great job!</b> Maryem’s cast compressed her common fibular nerve causing weakness of her dorsiflexors and subsequent foot drop. The common fibular nerve’s superficial position makes it vulnerable to injury. 
                    <br />
                    <br />But we haven’t fully analyzed Maryem’s limp. What part of Maryem’s gait cycle is abnormal in the coronal plane?
                    </p>,
                hint: '',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/maryem-ex9.mp4",
                visualAlt: "",
            },
            notes: [
                "abnormal gait, right swing phase"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Foot flat",
                    "Toe off",
                    "Heel strike",
                    "Swing phase"
                ],
                answer:"Swing phase",
                choicesPerRow:2,
            },
            tutorial: TutorialMC,
        },

        // Exercise 10: Swing phase

        {
            questionArea: {
                smallHeading: 'Exercise 10:',
                largeHeading: 'Swing phase',
                paragraph: 'Which joint is affected in the swing phase?',
                hint: '',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/maryem-ex9.mp4",
                visualAlt: "",
            },
            notes: [
                "affected hip joint during swing phase"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Hip",
                    "Knee",
                    "Talocrural",
                    "Subtalar",
                    "Talocalcaneonavicular"
                ],
                answer:"Hip",
                choicesPerRow:2,
            },
            tutorial: TutorialMC,
        },

        // Exercise 11: Swing phase action

        {
            questionArea: {
                smallHeading: 'Exercise 11:',
                largeHeading: 'Swing phase action',
                paragraph: 'More specifically, which of the following actions is responsible for the abnormal swing phase in Maryem’s gait?',
                hint: '',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/maryem-ex9.mp4",
                visualAlt: "",
            },
            notes: [
                "hip hike at right hip joint"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Adduction",
                    "Extension",
                    "Flexion",
                    "Hip hike",
                    "Hip drop",
                    "External rotation",
                    "Internal rotation"
                ],
                answer:"Hip hike",
                choicesPerRow:3,
            },
            tutorial: TutorialMC,
        },

        // Exercise 12: Hip hike joint(s)

        {
            questionArea: {
                smallHeading: 'Exercise 12:',
                largeHeading: 'Hip hike joint(s)',
                paragraph: <p>Across which joint is the hip hike movement produced? 
                    <br />
                    <br />If you are having trouble, try this movement out yourself!
                    </p>,
                hint: '',
            },
            notes: [
                "hip hike across vertebral joints"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Vertebral",
                    "Left hip",
                    "Right hip",
                    "Left knee",
                    "Right knee"
                ],
                answer:"Vertebral",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 13: Vertebra to pelvis muscles

        {
            questionArea: {
                smallHeading: 'Exercise 13:',
                largeHeading: 'Vertebrae to pelvis muscles',
                paragraph: <p><b>Correct!</b> Maryem’s hip hike involves lateral flexion of the lumbar spine. 
                <br />
                <br />So, what are the muscles that connect the vertebrae to the pelvis?
                </p>,
                hint: '',
            },
            notes: [
                "lat. dorsi, quad. lumborum and erector spinae connect vertebrae to pelvis"
            ],
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps:{
                options:[
                    "Trapezius",
                    "Rhomboids",
                    "Latissimus dorsi",
                    "Quadratus lumborum",
                    "Erector spinae",
                    "Iliopsoas"
                ],
                answers:[
                    "Latissimus dorsi",
                    "Quadratus lumborum",
                    "Erector spinae"
                ],
                choicesPerRow:1,
            },
            tutorial: TutorialCB,
        },

        // Exercise 14: Hip hike muscle

        {
            questionArea: {
                smallHeading: 'Exercise 14:',
                largeHeading: 'Hip hike muscle',
                paragraph: <p>Considering the attachment points of <b>two</b> of the three muscles you identified in the previous exercise, 
                which one is more likely responsible for the hip hike?
                </p>,
                hint: '',
            },
            notes: [
                "right quad. lumborum acts to hike hip"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Left latissimus dorsi",
                    "Right latissimus dorsi",
                    "Right quadratus lumborum",
                    "Left quadratus lumborum"
                ],
                answer:"Right quadratus lumborum",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 15: Kinnect the dots

        {
            questionArea: {
                smallHeading: 'Exercise 15:',
                largeHeading: 'Hip hike muscle',
                paragraph: 'Considering Maryem’s injury and recovery, what is a possible reason for her right hip hike?',
                hint: '',
            },
            notes: [
                "hip hike compensates for ipsilateral foot drop"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Maryem’s right hip hikes to clear her right foot drop in the swing phase",
                    "After being confined to a wheelchair, her right hamstring has become weak and Maryem right hip hikes to compensate ",
                    "Maryem’s right tibia healed abnormally so that her right leg is now longer than her left. Maryem’s right hip hikes to compensate for leg length discrepancy ",
                    "Maryem developed arthritis in her left hip after being confined in a wheelchair. Consequently, she has reduced range of movement during left swing phase which causes an exaggeration of movement in the opposite limb, shown as 'hip hiking'"
                ],
                answer:"Maryem’s right hip hikes to clear her right foot drop in the swing phase",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,
        },

        // Exercise 16: Knee structures causing pain

        {
            questionArea: {
                smallHeading: 'Exercise 16:',
                largeHeading: 'Knee structures causing pain',
                paragraph: <p><b>Awesome deduction!</b> Yes, Maryem’s right hip hikes to compensate for her right foot drop in the swing phase. 
                <br />
                <br />Now we’ve accounted for all of Maryem’s symptoms except her left knee pain. 
                <br />
                <br />Consider how her knee pain occured. Given this mechanism of injury, choose <b>three</b> structures which will most likely cause the pain.
                </p>,
                hint: '',
            },
            notes: [
                "left knee pain likely due to bursa, bony joint, tendon"
            ],
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps:{
                    options:[
                        "Menisci",
                        "ACL",
                        "PCL",
                        "LCL",
                        "MCL",
                        "Joint capsule",
                        "Bursa",
                        "Bony joint",
                        "Tendon"
                    ],
                    answers:[
                        "Bursa",
                        "Bony joint",
                        "Tendon"
                    ],
                    choicesPerRow:1,
            },
            tutorial: TutorialCB,           
        },

        // Exercise 17: Kinnect the dots

        {
            questionArea: {
                smallHeading: 'Exercise 17:',
                largeHeading: 'Kinnect the dots',
                paragraph: <p>Considering Maryem’s injury and recovery, what is a possible reason for her left knee pain? 
                    <br />
                    <br />Remember the left knee pain only manifests when Maryem walks, but not when she is sitting or lying down.
                    </p>,
                hint: '',
            },
            notes: [
                "reliance on healthy left leg led to overuse and inflammation in the right joint"
            ],
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps:{
                options:[
                    "Due to her abnormal gait, Maryem has been relying on her healthy left leg as compensation. Overtime, the added load lead to overuse and inflammation in the joint",
                    "Maryem also injured her left knee cartilage during the skiing accident",
                    "Skiing is considered a high impact sport that can put a lot of stress on the knee joint. Due to her intense training schedule as a competitive skiier, Maryem has developed anterior knee pain due to overuse",
                    "Stress on the knee joint from skiing all the time has causesd degenerative arthritis in Maryem’s left knee. Overuse has grinded down her knee cartilage and the  bone to bone contact is painful"
                ],
                answer:"Due to her abnormal gait, Maryem has been relying on her healthy left leg as compensation. Overtime, the added load lead to overuse and inflammation in the joint",
                choicesPerRow:1,
            },
            tutorial: TutorialMC,  
        },

    ]
};
