import React, { useEffect } from "react";
import AutoAnimation from "../animations/AutoAnimation";
import { connect } from "react-redux";
import { getBadgesRecieved } from "../../utils/progressHelpers";
import { showBadge } from "../../actions/progress";
import caseStudies from "../../casestudies";
import { postDataSecure } from "../../api";
import { camelCase } from "lodash";

const BadgeOverlay = ({
  badgeName,
  badgeSrc,
  heading,
  text,
  showBadge,
  shouldShow,
}) => {
  useEffect(() => {
    if (shouldShow) {
      return () => showBadge(badgeName);
    }
  }, [badgeName, shouldShow]);

  if (!shouldShow) return null;
  return (
    <div id="overlay-show" onClick={() => showBadge(badgeName)}>
      <div className="overlay-cont">
        <div className="overlay-level">
          <div className="level-badge">
            <AutoAnimation path={badgeSrc} />
          </div>
          <p className="congrats">{heading}</p>
          <p>{text}</p>
          <p>
            <i>(Click anywhere to go back)</i>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const badgesRecieved = getBadgesRecieved(state);

  let badgeUnlocked = "";
  for (var key in badgesRecieved) {
    const badgeShown = state.progress[`${camelCase(key)}BadgeShown`];
    if (badgesRecieved[key] !== badgeShown && badgeShown === false) {
      badgeUnlocked = camelCase(key);
    }
  }

  const badgeSources = {
    rookie: "../../bodymovin/user/rookie.json",
    novice: "../../bodymovin/user/novice.json",
    expert: "../../bodymovin/user/expert.json",
    master: "../../bodymovin/user/master.json",
    standingTall: "../../bodymovin/user/standingTall.json",
  };

  const badgeHeadings = {
    rookie: (
      <p className="congrats">
        Well done, Rookie. <br />
        You just finished your first case study!
      </p>
    ),
    novice: (
      <p className="congrats">
        Nice work, Novice! <br />
        You completed Level One!
      </p>
    ),
    expert: (
      <p className="congrats">
        Well hey now, Expert! <br />
        You completed Level Two!
      </p>
    ),
    master: "Congratulations, you are a Kinundrum Master!",
    standingTall: "You are standing tall!",
  };

  const badgeText = {
    rookie:
      "Complete the other two case studies to level up to Novice. Keep track of your achievements in your User Profile.",
    novice: "Ready for Level Two?",
    expert: "Ready for the final challenge?",
    master:
      "Remember, you can go back to any case study to review your answers or reset a case study to test your knowledge.",
    standingTall: "Way to finish the spine case studies!",
  };

  const caseStudy = state.navigation.caseStudy;
  const page = state.navigation.page;
  const numPages = caseStudies[caseStudy].exercises.length + 2;

  return {
    badgeName: badgeUnlocked,
    badgeSrc: badgeSources[badgeUnlocked],
    heading: badgeHeadings[badgeUnlocked],
    text: badgeText[badgeUnlocked],
    shouldShow: badgeUnlocked !== "" && page === numPages,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showBadge: (badgeName) => {
    dispatch(showBadge(badgeName));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { shouldShow, authentication, badgeName } = stateProps;
  const { token, studentNumber, role } = authentication;

  if (shouldShow) {
    let body = new FormData();
    body.append("badgeName", badgeName + "Badge");
    body.append("role", role);
    postDataSecure(`progresses/badge/${studentNumber}`, body, token);
  }

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BadgeOverlay);
