import React from 'react';
import { connect } from 'react-redux';
import { showTutorial } from '../../actions/exercise';

const mapDispatchToProps = dispatch => ({
    handleClick: () => dispatch(showTutorial()),
})


const ButtonTutorial = ({handleClick}) => {

    return(
        <button className="btn-link" id="tutorial" onClick={handleClick}>
            <div className="btnIconLeft">
                <svg width="16px" height="16px" viewBox="0 0 16 16">
                    <path className="btnIconLeft" d="M7.2,12.8H8.8V11.2H7.2ZM8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.4A6.4,6.4,0,1,1,14.4,8,6.41,6.41,0,0,1,8,14.4ZM8,3.2A3.2,3.2,0,0,0,4.8,6.4H6.4a1.6,1.6,0,1,1,3.2,0c0,1.6-2.4,1.4-2.4,4H8.8c0-1.8,2.4-2,2.4-4A3.2,3.2,0,0,0,8,3.2Z"
                    />
                </svg>
            </div>Tutorial
        </button>
    );
}

export default connect(null, mapDispatchToProps)(ButtonTutorial);