import React, { useState, useEffect } from 'react';
import Model from '../exercises/Model/Model.jsx';
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';

const SELECTED_COLOR = 0xffc400;
const UNSELECTED_COLOR = 0xe03e74;
const HOVER_COLOR = 0xff84a3;
const WRONG_COLOR = 0x871200;
const HOVER_BG = '#1c1e39'

const Choices3D = props => {

    const {
        correctAnswer,
        incorrectAnswer,
        reviewMode,
        updateSelCount,
        maxSelCount,
        event,
        objects,
        answers,
        xOffset,
        yOffset,
        zOffset,
        zRotOffset,
        cameraDistance,
        frontCubeFaceSrc,
        leftCubeFaceSrc,
        backCubeFaceSrc,
        rightCubeFaceSrc,
        topCubeFaceSrc,
        bottomCubeFaceSrc
    } = props;

    const [ labelX, setLabelX ] = useState(0);
    const [ labelY, setLabelY ] = useState(0);
    const [ hoveredKey, setHoveredKey ] = useState("");
    const [ selected, setSelected ] = useState([]);
    const [ correct, setCorrect ] = useState([]);


    useEffect(()=>giveChoicesCallbacks(), []);

    const giveChoicesCallbacks = () => {
        const keys = Object.keys(objects);
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            if(objects[key].isChoice === true){
                objects[key].callback = (x, y)=>handleClickChoice(key);
                objects[key].hovercallback = (x, y)=>{
                    
                    handleHoverChoice(key, x, y)};
            }
        }
    }

    const handleSubmissionClick = () => {
        let correct = [];
        for (let answer of answers) {
            if (selected.indexOf(answer) > -1) {
                correct.push(answer);
            }
        }

        if (correct.length === answers.length) {
            correctAnswer('That\'s correct! ✔');
        } else {
            incorrectAnswer('Try again. ✕');
            setCorrect(correct);
        }
    }

    const handleClearClick = () => {
        updateSelCount(correct.length);
        setSelected(correct)
    }

    const handleClickChoice = key => {
        if(!isDisabled(key)){
            setSelected(selected => {
                const selectedCopy = [...selected];
                const keyIndex = selectedCopy.indexOf(key);
                if (keyIndex === -1){
                    if(selectedCopy.length < maxSelCount)
                        selectedCopy.push(key);
                } else 
                    selectedCopy.splice(keyIndex, 1);
                updateSelCount(selectedCopy.length)
                return selectedCopy
            })
        }
    }

    const handleHoverChoice = (key, x, y) => {
        setLabelX(x+20);
        setLabelY(y);
        setHoveredKey(key);
    }

    const handleHoverEmpty = (x, y) => {
        if (hoveredKey !== ""){
            setHoveredKey("");
        }
    }

    const isDisabled = (key) => {
        const reachedMaxChoices = selected.length >= maxSelCount;
        const isSelected = selected.indexOf(key) !== -1;
        if( reachedMaxChoices && !isSelected) return true;
        return false;
    }

    const updateColors = () => {
        const keys = Object.keys(objects);
        for(let i = 0; i < keys.length; i++){
            const key = keys[i];
            if(objects[key].isChoice){
                if (key === hoveredKey) 
                    objects[key].color = HOVER_COLOR;
                else
                    objects[key].color = UNSELECTED_COLOR;
            }
        }
        if (reviewMode){
            for(let i = 0; i < answers.length; i++){
                const key = answers[i];
                objects[key].color = SELECTED_COLOR;
            }
        } else {
            for(let i = 0; i < selected.length; i++){
                const key = selected[i];
                objects[key].color = SELECTED_COLOR;
                if (event === 'failed' && answers.indexOf(key) === -1){
                    objects[key].color = WRONG_COLOR;
                }
            }
        }
    }

    const renderLabel = () => {
        if(hoveredKey !== ""){
            const tooltip = objects[hoveredKey].tooltip
            return (
                <div
                style={{
                    position: 'absolute',
                    top: labelY+'px',
                    left:labelX+'px',
                    backgroundColor: HOVER_BG,
                    color: 'white',
                    padding: '8px',
                    opacity: '0.6',
                }}
                >{tooltip}</div>
            )
        } else {
            return null;
        }
    }

    updateColors()
    return (
        <div id="visual3D">
            <Model style={{position: 'relative', borderStyle: '3px solid #1c1e39',}}
            shapes={{}}
            objects={objects}
            xOffset={xOffset}
            yOffset={yOffset}
            zOffset={zOffset}
            zRotOffset={zRotOffset}
            cameraDistance={cameraDistance}
            noIntersectsCallback={handleHoverEmpty}
            frontCubeFaceSrc={frontCubeFaceSrc}
            backCubeFaceSrc={backCubeFaceSrc}
            rightCubeFaceSrc={rightCubeFaceSrc}
            leftCubeFaceSrc={leftCubeFaceSrc}
            topCubeFaceSrc={topCubeFaceSrc}
            bottomCubeFaceSrc={bottomCubeFaceSrc}
            >
                {renderLabel()}
            </Model>
            <SubmissionArea 
            onSubmissionClick={handleSubmissionClick} 
            onClearClick={handleClearClick}
            />
        </div>
    );
    
}

export default connectInteractive(Choices3D);

