import React, { Component } from 'react';


class SlideShow extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentSlideIndex: 0,
        }
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    handlePrevClick(){
        this.setState({
            currentSlideIndex: Math.max(0, this.state.currentSlideIndex-1),
        })
    }

    handleNextClick(){
        const nextSlideIndex = Math.min(this.props.slides.length-1, this.state.currentSlideIndex+1)
        if(nextSlideIndex === this.props.slides.length-1) this.props.onFinish();
        this.setState({
            currentSlideIndex: nextSlideIndex,
        })
    }

    render(){
        let leftVisibility = this.state.currentSlideIndex <= 0 ? 'hidden' : 'visible';
        let rightVisibility = this.state.currentSlideIndex >= this.props.slides.length-1 ? 'hidden' : 'visible';
        const currentSlideComponent = this.props.slides[this.state.currentSlideIndex];
        return(
            <div className="slideshow">
                <div className="slide">{currentSlideComponent}
                    <button onClick={this.handlePrevClick} style={{visibility: leftVisibility}} className="btn-slide-back" id="back">
                        <div className="btnIconLeft">
                            <svg width="10px" height="20px" viewBox="0 0 7.4 12">
                                <path className="btnIconLeft" d="M7.4,1.4L6,0L0,6l6,6l1.4-1.4L2.8,6L7.4,1.4z" />
                            </svg>
                        </div>
                    </button>
                    <button onClick={this.handleNextClick} style={{visibility: rightVisibility}} className="btn-slide-next" id="next">
                        <div className="btnIconRight">
                            <svg width="10px" height="20px" viewBox="0 0 7.4 12">
                                <path className="btnIconRight" d="M0,1.4L1.4,0l6,6l-6,6L0,10.6L4.6,6L0,1.4z" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

}

export default SlideShow;
