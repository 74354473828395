import React,{Component} from 'react';

/**
 *  The basic Incorrect Overlay that is displayed when a user gets an exercise wrong
 * 
 * Props:
 * Close - the function being called when the overlay is being called.
 * strong - the strong text being displayed in the overlay
 * paragraph - the weak text being displayed in the overlay 
 */


class IncorrectOverlay extends Component{
    render(){
        return(
            <div id="overlay-incorrect">

            <button className="btnClose" aria-label="Close" title="Close" onClick={this.props.Close}>
                <span aria-hidden="true">&times;</span>
            </button>

            <div className="incorrectText">
                <p>
                    <strong>{this.props.strong}</strong>
                </p>
                <p>{this.props.paragraph}</p>
            </div>

            </div>
        );
    }
}

export default IncorrectOverlay