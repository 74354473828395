import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = (props) => {
    const { isAuthorized } = props
    if (!isAuthorized) {
        return <Redirect to='/login'/>;
    }
    return <Route {...props}/>
}

const mapStateToProp = ({ authentication }) => {
    const { isAuthorized } = authentication;  
    return {
        isAuthorized
    }
}

export default connect(mapStateToProp)(PrivateRoute);
