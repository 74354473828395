import React from 'react';
import { connect } from 'react-redux';

/**
 * ExerciseFeedback renders the text that is displayed when a user submits an answer
 * 
 * Props:
 * event - the current event of the exercise page
 * message - the text being displayed if the event is succeeded or failed
 */

const mapPropsToState = state => {

    const { caseStudy, page } = state.navigation;
    const progressToCheck = state.navigation.mode === 'redo' ? 'resolved' : 'solved';
    const solved = state.progress[caseStudy][progressToCheck];

    return {
        event: state.exercise.event,
        message: state.exercise.message,
        reviewMode: state.navigation.mode === 'review' || page <= solved,
    }
}

const ExerciseFeedback = ({event, message, reviewMode}) => {

    var elmClass = 'hidden';
    var elmText = "No Feedback";
    if (event === 'failed'){
        elmClass = 'incorrect-text';
        elmText = message;
    } else if  (event === 'solved' || reviewMode){
        elmClass = 'correct-text';
        elmText = message; 
    }

    if (reviewMode && elmText === '') elmText = 'Already completed!'

    return (
        <div id="submitFeedback" className={elmClass}>
            <p>{elmText}</p>
        </div>
    );
    
}

export default connect(mapPropsToState)(ExerciseFeedback);
