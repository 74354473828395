import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import caseStudies from '../../casestudies';
import { EVENT_TYPES } from '../../constants';
import { showScenarioOverlay } from '../../actions/exercise';
const { EVENT_SOLVED } = EVENT_TYPES;

const mapStateToProps = state => {
    const { caseStudy, page } = state.navigation;
    const caseStudyData = caseStudies[caseStudy];
    const progressToCheck = state.navigation.mode === 'redo' ? 'resolved' : 'solved';
    const exercisesSolved = state.progress[caseStudy][progressToCheck] - 1; 
    const numPages = caseStudies[caseStudy].exercises.length + 2;

    let notes = ["This will update as you go through exercises"];
    let observations = ["Nothing yet! Watch the animation first"];
    let newNotes = [];
    if (page !== 1){
        observations = caseStudyData.observations;
        notes=[];
        for(let i = 0; i < exercisesSolved-1; i++)
            notes.push(...caseStudyData.exercises[i].notes);
        if (exercisesSolved > 0)
            newNotes = caseStudyData.exercises[exercisesSolved-1].notes
    }
    return {
        profilePic: caseStudyData.profilePicSrc,
        profileName: caseStudyData.name,
        observations,
        notes,
        newNotes,
        isSolved: state.exercise.event === EVENT_SOLVED,
        page,
        show: page !== numPages,

    }
}

const mapDispatchToProps = dispatch => ({
    showScenarioOverlay: ()=>dispatch(showScenarioOverlay())
})

const Folder = props => {

    const [ position, setPosition ] = useState('-255px');
    const [ count, setCount ] = useState(-1); 

    const longestNote = props.newNotes.reduce((acc,curr)=>{
        return acc.length > curr.length ? acc : curr;
    }, 0).length

    useEffect(()=>{
        if(props.isSolved) setPosition('0px');
    },[props.isSolved])

    useEffect(()=>{
        if(props.page === 1) setPosition('0px');
        else setPosition('-255px');
    },[props.page])

    useEffect(()=>{
        setCount(0);
    },[props.newNotes])

    useEffect(()=>{
        const timeout = setTimeout(()=>{
            if(count < longestNote)
                setCount(count => count + 1);
        }, 50)
        return ()=>clearTimeout(timeout)
    })

    const handleClick = () => {
        if (position === '0px') setPosition('-255px');
        else setPosition('0px'); 
    }

    const handleProfileClick = () => {
        props.showScenarioOverlay();
    }

    const observations = props.observations.map((observation,ind)=>{
        return <li key={ind}> {observation} </li>
    })

    const notes = props.notes.map((note,ind)=>{
        return <li key={ind}> {note} </li>
    })

    const newNotes = props.newNotes.map((note, ind)=>{
        return <li key={ind}> {note.slice(0,count)} </li>
    })

    if (!props.show) return null;

    return (
        <div id="folderArea" style={{'right': position}}>
            <div id="folder">
                <svg id="foldersvg" viewBox="0 0 297.5 620">
                    <path className="foldercol" d="M79.5,0h-26c-13.8,0-25,10.4-25,23.1V25v7.8L20,34C9,34,0,43,0,54v52c0,11,9,20,20,20l8.5,1.2V595v1.9 c0,12.8,11.2,23.1,25,23.1h26h218V0H79.5z"
                    />
                    <g id="sheets_5_" transform="translate(30 -10)">
                        <rect id="Rectangle_2-2_4_" x="36.8" y="52" className="pagecol1" width="231.2" height="560" />
                        <rect id="Rectangle_1-2_4_" x="28" y="42" className="pagecol2" width="240" height="560" />
                    </g>
                </svg>

                {/*-- tab for collapsing folder --*/}
                <button id="folderarrow" onClick={handleClick}>
                    <i className="left"></i>
                </button>

                {/*-- profile pic --*/}
                <div className="profilePic">
                    <button className="overlay-play" onClick={handleProfileClick}>
                        <img src="../../img/icons/icon-play.svg" alt="play button" className="play" />
                    </button>
                    <img src={props.profilePic} alt={props.profileName} />
                </div>

                {/*-- profile text --*/}
                <div className="profileText">
                    <p>
                        <b>{props.profileName}</b>
                    </p>
                    <p className="caption">{ 'Click on the photo to see what happened to ' + props.profileName }</p>
                </div>

                {/*-- notes text --*/}
                <div id="folderText">
                    <p>
                        <b>Observations:</b>
                    </p>
                    <ul>
                        {observations}
                    </ul>
                    <p>
                        <b>Notes:</b>
                    </p>
                    <ul>
                        {notes}
                        {newNotes}
                    </ul>
                </div>
                
            </div>
            
        </div>
        
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
