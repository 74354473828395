import React from "react";
import Intro from "./Intro";
import Exercise from "./Exercise";
import Topnav from "../navigation/Topnav";
import Folder from "../exercises/Folder";
import ScenarioOverlay from "../exercises/ScenarioOverlay";
import { connect } from "react-redux";
import { setCaseStudy } from "../../actions/navigation";
import caseStudies from "../../casestudies";
import BadgeOverlay from "../overlays/BadgeOverlay";

const mapStateToProps = (state) => {
  const caseStudy = state.navigation.caseStudy;
  return {
    page: state.navigation.page,
    numPages: caseStudies[caseStudy].exercises.length + 2,
    Conclusion: caseStudies[caseStudy].conclusion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCaseStudy: (caseStudy) => {
    dispatch(setCaseStudy(caseStudy));
  },
});

const CaseStudy = ({ page, numPages, Conclusion }) => {
  const Content = () => {
    switch (page) {
      case 1:
        return <Intro />;
      case numPages:
        return <Conclusion />;
      default:
        return <Exercise />;
    }
  };

  const renderMaxBGScreen = () => (
    <div id="max-screen-bg">
      <img src="../../img/max-bg.svg" alt="background" />
    </div>
  );

  const showMaxBGScreen = page !== numPages;

  return (
    <div className="case-study">
      <BadgeOverlay />
      <ScenarioOverlay />
      <div className="content-wrapper">
        <Topnav page="exercise" />
        <Content />
        <Folder />
      </div>
      {showMaxBGScreen && renderMaxBGScreen()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudy);
