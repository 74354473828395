import React from "react";
import ChoicesCB from "../components/interactive/ChoicesCB";
import TutorialCB from "../components/tutorials/TutorialCB";
import { VISUAL_ASSET_TYPES } from "../constants";
import BenEx1Rekinnect from "../components/rekinnect/BenEx1Rekinnect";
import BenEx2Rekinnect from "../components/rekinnect/BenEx2Rekinnect";
import BenEx3Rekinnect from "../components/rekinnect/BenEx3Rekinnect";
import BenEx4Rekinnect from "../components/rekinnect/BenEx4Rekinnect";
import BenConc from "../components/conclusions/BenConc";
import ChoicesMC from "../components/interactive/ChoicesMC";
import TutorialMC from "../components/tutorials/TutorialMC";

export default {
  title: "The Case of the Golfer",
  name: "Ben",
  profilePicSrc: "../img/ben/profile.svg",
  introAsset: {
    type: "slideshow",
    slides: [
      <img src="../img/ben/intro_scene_0.png" />,
      <img src="../img/ben/intro_scene_1.png" />,
      <img src="../img/ben/intro_scene_2.png" />,
      <video controls>
        <source src="../img/ben/intro_scene_3.mp4" type="video/mp4" />
      </video>,
      <img src="../img/ben/intro_scene_4.png" />,
      <img src="../img/ben/intro_scene_5.png" />,
      <img src="../img/ben/intro_scene_6.png" />,
      <img src="../img/ben/intro_scene_7.png" />,
      <img src="../img/ben/intro_scene_8.png" />,
      <img src="../img/ben/intro_scene_9.png" />,
      <img src="../img/ben/intro_scene_10.png" />,
      <img src="../img/ben/intro_scene_11.png" />,
      <img src="../img/ben/intro_scene_12.png" />,
      <img src="../img/ben/intro_scene_13.png" />,
      <img src="../img/ben/intro_scene_14.png" />,
      <img src="../img/ben/intro_scene_15.png" />,
      <img src="../img/ben/intro_scene_16.png" />,
    ],
  },
  observations: [
    "experiences back pain, shoulder pain, and shortness of breath with golfing",
    "used to work as a full time landscaper, repetitive tasks in landscaping causes Ben to have move shoulder pain",
    "switched to part time last year and shoulder pain has diminished",
    "with golfing, Ben's shoulder pain has increased again",
  ],
  conclusion: BenConc,
  exercises: [
    // Exercise 1
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/ben/ex_1.png",
      },
      questionArea: {
        smallHeading: "Exercise 1:",
        largeHeading: "What is the cause of the back pain?",
        paragraph: (
          <p>
            Let's use Ben's recent X-ray of his spine to look at what is going on
            as this might help illuminate what is causing the pain and where.
            <br />
            <br />
            Which part of his spine is most affected and what is this alignment
            change called?
          </p>
        ),
        hint:
          "Use the Re-kinnect to compare Ben’s radiograph to a typical spine radiograph/alignment here to determine what has changed.",
      },
      notes: ["presents with thoracic hyperkyphosis"],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 2,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "Cervical",
          "Kyphotic",
          "Thoracic",
          "Hyperkyphotic",
          "Lumbar",
          "Lordotic",
          "Sacral",
          "Hyperlordotic",
        ],
        answers: ["Thoracic", "Hyperkyphotic"],
        choicesPerRow: 2,
      },
      tutorial: TutorialCB,
      rekinnect: BenEx1Rekinnect,
    },
    // Exercise 2
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/ben/ex_2.png",
      },
      questionArea: {
        smallHeading: "Exercise 2:",
        largeHeading: "What is the cause of the back pain?",
        paragraph: (
          <p>
            In the thoracic region, what <b>three</b> structures might be causing Ben’s back pain?
          </p>
        ),
        hint:
          "Think carefully about what might be compressed or stretched in this posture! Use the Re-kinnect model to visually observe what is going on during movement from neutral to flexion at a spinal segment.",
      },
      notes: [
        "back muscles, spinal discs, and posterior spinal ligaments could be causing back pain",
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 3,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "Posterior spinal ligaments",
          "Anterior spinal ligaments",
          "Back muscles",
          "Shoulder muscles",
          "Vertebral body",
          "Disc",
          "Articular facets",
        ],
        answers: ["Back muscles", "Posterior spinal ligaments", "Disc"],
        choicesPerRow: 2,
      },
      tutorial: TutorialCB,
      rekinnect: BenEx2Rekinnect,
    },
    // Exercise 3
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/ben/ex_3.png",
      },
      questionArea: {
        smallHeading: "Exercise 3:",
        largeHeading: "Why is he out of breath?",
        paragraph: (
          <p>
            He says he has to take the golf cart more now, when he walks long distances. What is happening? <b>Choose</b> the system that is the biggest problem area.
          </p>
        ),
        hint:
          "Look carefully at his posture again. What structure is going to be MOST impacted by hyperkyphosis and reduce his aerobic endurance?",
      },
      notes: [
        "limited space for lung expansion is causing shortness of breath",
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 1,
      interactiveArea: ChoicesMC,
      interactiveAreaProps: {
        options: [
          "Heart",
          "Lungs",
          "Nerve",
          "Intercostal nerve",
          "Muscles of the chest wall"
        ],
        answer:"Lungs",
        choicesPerRow: 3,
      },
      tutorial: TutorialMC,
      rekinnect: BenEx3Rekinnect,
    },
    // Exercise 4
    {
      visualAsset: {
        type: VISUAL_ASSET_TYPES.IMAGE,
        visualSource: "../img/ben/ex_4.png",
      },
      questionArea: {
        smallHeading: "Exercise 4:",
        largeHeading: "Why can't he lift his arm?",
        paragraph: (
          <p>
            Ben has said he isn’t swinging his golf club as well lately. And
            he’s also having shoulder pain doing landscaping tasks.
            <br />
            <br />
            Are these related in some way? What do you suppose is causing the
            issue? <b>Choose</b> all that apply from the list.
          </p>
        ),
        hint:
          "Use the Re-kinnect to observe Ben's muscles and scapula, in particular, when he lifts his arm.",
      },
      notes: [
        "limited rotation of the scapula leads to limited space between the scapula and humerus, causing compression of supraspinatus tendon as the arm lifts up",
      ],
      incorrectOverlayConfig: {
        show: true,
        strong: "Not quite!",
        paragraph: "Before you can move on, complete the Re-kinnect exercise.",
        numberOfAppears: 2,
        isRekinnect: true
      },
      maxSelCount: 6,
      interactiveArea: ChoicesCB,
      interactiveAreaProps: {
        options: [
          "Shoulder muscles are weak",
          "Shoulder joint is restricted in moving",
          "Chest muscles are short",
          "Back joints are not moving properly",
          "Back muscles are weak",
          "Shoulder tendons are getting compressed",
        ],
        answers: [
          "Shoulder muscles are weak",
          "Shoulder joint is restricted in moving",
          "Chest muscles are short",
          "Back joints are not moving properly",
          "Back muscles are weak",
          "Shoulder tendons are getting compressed",
        ],
        choicesPerRow: 2,
      },
      tutorial: TutorialCB,
      rekinnect: BenEx4Rekinnect,
    },
  ],
};
