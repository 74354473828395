import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const AlexConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Hamstring strain
              </h2>

              <p>
                When Alex scored her winning goal, she also hyperextended during
                knee extension. Normally, the hamstrings contract eccentrically
                at the end of a kick to decelerate and prevent the leg from
                hyperextending past its anatomical limit. Because she kicked too
                hard and too fast, she ends up straining her hamstrings. Due to
                Alex’s injury, they will feel pain during activities involving
                hip flexion/knee extension or hip flexion/knee flexion such as
                running, or bending over with knees straight.
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Normally, movement is initiated by concentric contraction. At
                  high speeds, antagonist muscles on the opposite side of the
                  joint contract eccentrically to prevent the agonist muscle
                  from extending beyond its anatomical limit
                </li>
                <li>
                  Muscles are usually damaged when they are extended past their
                  anatomical limit forcefully and rapidly
                </li>
                <li>
                  When muscles are damaged, any activity that contracts and
                  fully stretches the muscle causes pain and affects daily
                  activities
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img
                src="../img/alex/conc.svg"
                alt="diagram of Alex's right leg at the end of a kick"
              />
              <figcaption>
                Figure 1. Diagram of Alex's strained right hamstring at the end
                of a kick at high speed
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Muscles for wind-up vs. kick</b>
                          <br />
                          What are the main muscles involved in the wind-up and
                          kicking movement. Click and drag to match the muscle
                          to the movement type.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../img/alex/ex-1.png"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Stand up and go through the motions with your own leg!
                          Think about the muscles involved as you try it.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">
                          Wind-up:
                          <br />
                          <b>Hamstrings</b>
                        </li>
                        <li className="flo-box5 correct">
                          Wind-up:
                          <br />
                          <b>Gluteus max.</b>
                        </li>
                        <li className="flo-box5 correct">
                          Kick:
                          <br />
                          <b>Quadriceps</b>
                        </li>
                        <li className="flo-box5 correct">
                          Kick:
                          <br />
                          <b>Iliopsoas</b>
                        </li>
                        <li className="flo-box5 correct">
                          Kick:
                          <br />
                          <b>Rectus femoris</b>
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Wind-up vs. kicking contractions</b>
                          <br />
                          Can you identify what type of contraction is involved
                          in the beginning and end of the wind up and kicking
                          phase?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          The muscle needs to rapidly accelerate in the
                          beginning and rapidly decelerate at the end of the
                          contraction.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a">
                          Wind-up <br />
                          start
                        </li>
                        <li className="flo-box4">
                          Wind-up <br />
                          end
                        </li>
                        <li className="flo-box4">
                          Kick <br />
                          start
                        </li>
                        <li className="flo-box4">
                          Kick <br />
                          end
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">
                          Glutes: <br />
                          <b>concentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Hip flexors: <br />
                          <b>eccentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Hip flexors: <br />
                          <b>concentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Glutes: <br />
                          <b>eccentric</b>
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">
                          Hamstrings: <br />
                          <b>concentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Quadriceps: <br />
                          <b>eccentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Quadriceps: <br />
                          <b>concentric</b>
                        </li>
                        <li className="flo-box4 correct">
                          Hamstrings: <br />
                          <b>eccentric</b>
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Alex's muscle strain</b>
                          <br />
                          Based on the mechanism of Alex’s injury, choose the
                          muscle most likely to be strained.
                        </a>
                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/alex/flowchart-3.png"
                            alt="Visual asset for Exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          During a kick, which muscle normally contracts
                          eccentrically to prevent the knee from hyperextending?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">Hamstrings</li>
                        <li className="flo-box5">Quadriceps</li>
                        <li className="flo-box5">Glutes</li>
                        <li className="flo-box5">Iliopsoas</li>
                        <li className="flo-box5">Rectus femoris</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Hamstring strain</b>
                          <br />
                          Which coupled actions are going to aggravate the
                          hamstring again? Choose all that are correct.
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          When a muscle is aggravated/injured, pain could be
                          produced by either contracting or fully stretching the
                          muscle.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">
                          Knee flexion and hip flexion together
                        </li>
                        <li className="flo-box4">
                          Knee flexion and hip extension together
                        </li>
                        <li className="flo-box4 correct">
                          Knee extension and hip flexion together
                        </li>
                        <li className="flo-box4">
                          Knee extension and hip extension together
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 5" onClick={() => setPage(5)}>
                          <b>Exercise 5: Activities of daily living</b>
                          <br />
                          Poor Alex, their hamstring is going to be sore for a
                          while. What other daily activities will aggravate
                          their injury?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/alex/flowchart-5.svg"
                            alt="Visual asset for Exercise 5"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          For each activity, think about what happens at the
                          joints. Stand up and try each movement!
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw8"></div>
                      <div className="lh-8a"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box8a">
                          Bending to the ground with knees bent
                        </li>
                        <li className="flo-box8 correct">Climbing up stairs</li>
                        <li className="flo-box8 correct">
                          Bending over with knees straight
                        </li>
                        <li className="flo-box8 correct">Running</li>
                        <li className="flo-box8">Sitting in a chair</li>
                        <li className="flo-box8">Figure four stretch</li>
                        <li className="flo-box8">
                          Laying in a bed (netflixing)
                        </li>
                        <li className="flo-box8">Standing</li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default AlexConc;
