import React, { useState } from 'react';
import { connect } from 'react-redux';

import { toggleIntroTutorial } from '../../actions/progress';
import { postDataSecure } from '../../api';

const Tut1 = ({nextPage, setPage, closeTutorial}) => {
    return(
        <div className="overlay-tut-center" id="tut1">
            <div className="tut-header">
                <button className="btnClose" aria-label="Close" title="Close" onClick={closeTutorial}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
    
            <div className="tut-body">
                <p>
                    <b>Hey there!</b> Before you start solving Kinundrums, would you like to run through a quick tutorial?</p>
            </div>
    
            <div className="tut-button">
                <ol>
                    <li>
                        <button className="circle" onClick={() => setPage(1)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(2)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(3)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(4)}></button>
                    </li>
                </ol>
    
                <button className="btn-ghost" onClick={closeTutorial}>Skip</button>
                <button className="btn-ghost" onClick={nextPage}>Sure!</button>
            </div>
        </div>
    );
}

const Tut2 = ({nextPage, prevPage, setPage, closeTutorial}) => {
    return(
        <div className="overlay-cs-tut2" id="tut2">
            <div className="tut-header">
                <button className="btnClose" aria-label="Close" title="Close" onClick={closeTutorial}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="tut-body">
                <p>This is your navigation menu. Click the icons to access the <strong>Home</strong>
                    <img className="tut-icon" src="../../img/icons/icon-nav-home.svg" alt="home icon"/>and <strong>User Profile</strong>
                    <img className="tut-icon" src="../../img/icons/icon-nav-user.svg" alt="user profile icon"
                    />.</p>
            </div>

            <div className="tut-button">
            <ol>
                    <li>
                        <button className="circle current" onClick={() => setPage(1)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(2)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(3)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(4)}></button>
                    </li>
                </ol>

                <button className="btn-ghost" onClick={prevPage}>Back</button>
                <button className="btn-ghost" onClick={nextPage}>Next</button>
            </div>
        </div>
    );
}

const Tut3 = ({nextPage, prevPage, setPage, closeTutorial}) => {
    return(
        <div className="overlay-cs-tut3" id="tut3">
            <div className="tut-header">
                <button className="btnClose" aria-label="Close" title="Close" onClick={closeTutorial}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="tut-body">
                <p>This is your local navigation menu and <b>progress bar</b>. Click on the icons to navigate between exercises you have already completed.</p>
            </div>

            <div className="tut-button">
                <ol>
                    <li>
                        <button className="circle current" onClick={() => setPage(1)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(2)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(3)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(4)}></button>
                    </li>
                </ol>

                <button className="btn-ghost" onClick={prevPage}>Back</button>
                <button className="btn-ghost" onClick={nextPage}>Next</button>
            </div>
        </div>
    );
}

const Tut4 = ({nextPage, prevPage, setPage, closeTutorial}) => {
    return(
        <div className="contentIntro" id="tut4">

            <div className="overlay-cs-tut4">

                <div className="tut-header">
                    <button className="btnClose" aria-label="Close" title="Close" onClick={closeTutorial}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="tut-body">
                    <p>This is your <b>patient folder</b>. You can click on the profile picture to rewatch the intro animation. The notes will automatically
                        update with information about the patient’s injury as you complete the exercises.</p>
                </div>

                <div className="tut-button">
                <ol>
                    <li>
                        <button className="circle current" onClick={() => setPage(1)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(2)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(3)}></button>
                    </li>
                    <li>
                        <button className="circle" onClick={() => setPage(4)}></button>
                    </li>
                </ol>

                <button className="btn-ghost" onClick={prevPage}>Back</button>
                <button className="btn-ghost" onClick={nextPage}>Next</button>
                </div>

            </div>

            <div id="introfolderArea">

                <div id="folder">
                    <svg id="foldersvg" viewBox="0 0 297.5 620">
                        <path className="foldercol" d="M79.5,0h-26c-13.8,0-25,10.4-25,23.1V25v7.8L20,34C9,34,0,43,0,54v52c0,11,9,20,20,20l8.5,1.2V595v1.9 c0,12.8,11.2,23.1,25,23.1h26h218V0H79.5z"
                        />
                        <g id="sheets_5_" transform="translate(30 -10)">
                            <rect id="Rectangle_2-2_4_" x="36.8" y="52" className="pagecol1" width="231.2" height="560" />
                            <rect id="Rectangle_1-2_4_" x="28" y="42" className="pagecol2" width="240" height="560" />
                        </g>
                    </svg>


                    <button id="folderarrow">
                        <i className="left"></i>
                    </button>


                    <div className="profilePic">
                        <img src="../../img/icons/folder-anon.svg" alt="Injured character" />
                    </div>


                    <div className="profileText">
                        <p>
                            <b>Character Profile</b>
                        </p>
                        <p className="caption">Click on the photo to play the animation</p>
                    </div>


                    <div id="folderText">
                        <p>
                            <b>Observations:</b>
                        </p>
                        <ul>
                            <li>Nothing yet! Watch the animation first</li>
                        </ul>
                        <p>
                            <b>Notes:</b>
                        </p>
                        <ul>
                            <li>This will update as you go through exercises</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}


const Tut5 = ({prevPage, setPage, closeTutorial}) => {
    return(
        <div className="overlay-tut-center" id="tut6">

            <div className="tut-header">
                <button className="btnClose" aria-label="Close" title="Close" onClick={closeTutorial}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="tut-body">
                <p>Click on the question mark icon
                    <img className="tut-icon-s" src="../../img/icons/icon-tutorial.svg" alt="tutorial icon" /> to access <b>tutorials</b> on specific interactive features.</p>
            </div>

            <div className="tut-button">
            <ol>
                    <li>
                        <button className="circle current" onClick={() => setPage(1)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(2)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(3)}></button>
                    </li>
                    <li>
                        <button className="circle current" onClick={() => setPage(4)}></button>
                    </li>
                </ol>

                <button className="btn-ghost" onClick={prevPage}>Back</button>
                <button className="btn-ghost" onClick={closeTutorial}>Got it!</button>
            </div>
        </div>
    );
}

const Tutorial = ({displayTutorial, toggleTutorial}) => {

    const [page, setPage] = useState(0);

    const nextPage = () => {
        setPage(page + 1);
    }

    const prevPage = () => {
        setPage(page - 1);
    }

    const display = () => {
        switch (page){
            case 0:
                return <Tut1 nextPage={nextPage} closeTutorial={() => toggleTutorial(setPage)} setPage={setPage}/>;
            case 1:
                return <Tut2 nextPage={nextPage} prevPage={prevPage} closeTutorial={() => toggleTutorial(setPage)} setPage={setPage}/>;
            case 2:
                return <Tut3 nextPage={nextPage} prevPage={prevPage} closeTutorial={() => toggleTutorial(setPage)} setPage={setPage}/>;
            case 3:
                return <Tut4 nextPage={nextPage} prevPage={prevPage} closeTutorial={() => toggleTutorial(setPage)} setPage={setPage}/>;
            case 4:
                return <Tut5 nextPage={nextPage} prevPage={prevPage} closeTutorial={() => toggleTutorial(setPage)} setPage={setPage}/>;
            default:
                return null;
        }
    };

    if (displayTutorial) {
        return (
            <div id='overlay-tut'>
                <div className='overlay-cont'>
                    {display()}
                </div>
            </div>
        );
    }
    
    return null;

}

const mapStateToProps = ({progress, authentication}) => {
    const { displayIntroTutorial } = progress;
    const { studentNumber, token } = authentication;
    return {
        displayTutorial: displayIntroTutorial,
        studentNumber,
        token,
    }
}

const mapDispatchToProps = dispatch => ({
    toggleTutorial: (setPage) => {
        dispatch(toggleIntroTutorial());
        setPage(0);
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    toggleTutorial: (setPage) => {
        postDataSecure(`progresses/tutorial/${stateProps.studentNumber}`, null, stateProps.token)
        dispatchProps.toggleTutorial(setPage);
    }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Tutorial);