export default {
    femur: {
        id: '1',
        url: 'models/Van_Knee/Femur.obj',
        color: '0xefe8dc',
        isAttachment: false,
    },
    fibula: {
        url: 'models/Van_Knee/Van_fibula.obj',
        color: '0xefe8dc',
        isAttachment: false,
    },
    patella: {
        url: 'models/Van_Knee/Van_Patella.obj',
        color: '0xefe8dc',
        isAttachment: false,
    },
    tibia: {
        url: 'models/Van_Knee/Van_Tibia.obj',
        color: '0xefe8dc',
        isAttachment: false,
    },
    attach_fem_1: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Fem_1_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: 0.649,
        y: 17.353,
        z: 3.931,
    },
    attach_fem_2: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Fem_2_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: 0.565,
        y: 17.794,
        z: 3.723,
    },
    attach_fem_3: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Fem_3_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: 0.309,
        y: 17.541,
        z: 3.580,
    },
    attach_tib_1: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Tib_1_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: 1.248,
        y: 15.986,
        z: 4.125,
    },
    attach_tib_2: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Tib_2_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: 0.198,
        y: 16.090,
        z: 4.090,
    },
    attach_tib_3: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Tib_3_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: -0.407,
        y: 15.618,
        
        z: 4.447,
    },
    attach_tib_4: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Tib_4_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd', '0xdddddd', '0xdddddd'],
        isAttachment: true,
        x: 0.730,
        y: 16.075,
        z: 3.172,
    },
    attach_tib_5: {
        url: 'models/Van_Knee/Attachments/Selected/Attach_Tib_5_selected.obj',
        color: ['0xdddddd', '0x98d6f9', '0xdddddd'],
        isAttachment: true,
        x: -0.197,
        y: 16.108,
        z: 3.318,
    }
}