import alex from "./alex";
import bernie from "./bernie";
import fred from "./fred";
import jenny from "./jenny";
import maryem from "./maryem";
import van from "./van";
import gary from "./gary";
import ben from "./ben";

export default {
  alex,
  bernie,
  fred,
  jenny,
  maryem,
  van,
  gary,
  ben,
};
