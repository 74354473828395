import { 
    SET_CASE_STUDY, 
    SET_PAGE, 
    GOTO_NEXT_PAGE, 
    GOTO_PREV_PAGE 
} from '../actions/navigation';

const initialState = {
    caseStudy: '',
    page: 1,
    mode: '',
};

const navigation = (state = initialState, action, progressState) => {
    switch(action.type) {
        case SET_CASE_STUDY:
            let progressToUse = action.mode === 'redo' ? 'resolved' : 'solved';
            const page = action.reset || action.mode === 'review' ? 1 : Math.max(1,progressState[action.caseStudy][progressToUse]+1); 
            return {
                ...state,
                caseStudy: action.caseStudy,
                mode: action.mode,
                page,
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page,
            };
        case GOTO_NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1,
            };
        case GOTO_PREV_PAGE:
            return {
                ...state,
                page: state.page - 1,
            }
        default:
            return state;
    }
}

export default navigation;
