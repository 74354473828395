import React, { useState } from "react";

function ToggleButton({ text, active, onClick }) {
  return (
    <button
      className={active ? "btn-ghost-ready" : "btn-ghost-rkwhite focused"}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

function PNGSliderWithToggle({ frames, text, toggleText, toggleDescription }) {
  const [frame, setFrame] = useState(0);
  const [frameGroup, setFrameGroup] = useState(0);

  const updateSlider = (e) => {
    setFrame(e.target.value);
  };

  const switchFrameGroup = (ind) => {
    setFrameGroup(ind);
    setFrame((prev) => Math.min(prev, frames[ind].length - 1));
  };

  return (
    <div>
      {/* Preload images by rendering all of them hidden */}
      <div
        style={{
          visibility: "hidden",
          width: 0,
          height: 0,
          overflow: "hidden",
        }}
      >
        {frames.map((group) =>
          group.map((frameSrc) => <img src={frameSrc}></img>)
        )}
      </div>
      <img style={{ width: "100%" }} src={frames[frameGroup][frame]}></img>
      <div className="sliderCont">
        <input
          id="slider"
          type="range"
          value={frame}
          min="0"
          max={frames[frameGroup].length - 1}
          onChange={updateSlider}
        />
        <div className="slidertext-container">
          <div>{text[0]}</div>
          <div>{text[1]}</div>
          <div>{text[2]}</div>
        </div>
      </div>
      <div>
        <div style={{ textAlign: "center", marginTop: "32px" }}>
          {toggleText.map((text, ind) => (
            <ToggleButton
              {...{
                text: text,
                onClick: () => switchFrameGroup(ind),
                active: frameGroup === ind,
              }}
            />
          ))}
        </div>
        {toggleDescription && (
          <div style={{ width: "60%", margin: "16px auto auto auto" }}>
            {toggleDescription[frameGroup]}
          </div>
        )}
      </div>
    </div>
  );
}

export default PNGSliderWithToggle;
