import React, {Component} from 'react';

import connectRekinnect from './connectRekinnect';

class Rekinnect extends Component{
    constructor(props){
        super(props);
        this.state = {
            videoSrc: "../../vid/alex-vid1.mp4",
            activeButton: "Slow",
        }

        this.renderButtons = this.renderButtons.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    setSpeed(speed){
        this.setState({speed})
    }

    renderButtons(){
        return(
            <div>
                {this.renderButton('Slow', "../../vid/alex-vid1.mp4")}
                {this.renderButton('Fast', "../../vid/alex-vid2.mp4")}
                {this.renderButton('Too Fast', "../../vid/alex-vid3.mp4")}
            </div>
            
        )
    }

    renderButton(buttonName, videoSrc){
        const buttonIsActive = this.state.activeButton === buttonName;
        const buttonClass = buttonIsActive ? "btn-ghost-rkwhite inline-m focused" : "btn-ghost-rkwhite inline-m";
        return (
            <button 
            className={buttonClass} 
            onClick={()=>this.handleButtonClick(buttonName, videoSrc)}>
                {buttonName}
            </button>
        );
    }

    handleButtonClick(buttonName, videoSrc){
        this.setState({
            activeButton: buttonName,
            videoSrc: videoSrc,
        });
    }

    render(){
        if(!this.props.showingRekinnect) return null;
        return(
            <div id="contentRK">
                <section>
                    <div className="questionArea">
                        <div className="questionWrap">

                            <div className="questionRK">
                                <h1>Re-kinnect:</h1>
                                <h2>Eccentric vs. concentric contractions</h2>
                                <p>Let’s focus on knee extension. 
                                    <br />
                                    <br />When you extend your knee, the quadriceps <b>concentrically</b> contract to lift your leg. Depending on the speed of the kick, however, the muscle activations will change at the end of the kick. 
                                    <br />
                                    <br />When the leg is kicked really fast, the hamstrings must contract <b>eccentrically</b> to ensure that the leg doesn’t hyperextend past its anatomical limit and cause injury. 
                                    <br />
                                    <br /><b>Explore different scenarios of knee extension by adjusting the speed of the kick.</b></p>
                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className="interactiveArea">
                        <div className="interactiveWrap">
                            <div className="visualRK">

                                <video className="video" controls key={this.state.videoSrc}>
                                    <source src={this.state.videoSrc} type="video/mp4" />
                                </video>

                                <p className="speed">Speed of the kick:</p>

                                {this.renderButtons()}
                            </div>
                            <div className="submissionRK">
                                <button className="btn-ghost-white" onClick={this.props.close}>Back to exercise</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connectRekinnect(Rekinnect);