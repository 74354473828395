import React, { useState } from 'react';
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';

const ChoicesMC = props => {

    const {
        updateSelCount,
        reviewMode,
        correctAnswer,
        incorrectAnswer,
        options,
        answer,
        choicesPerRow,
        images,
    } = props;

    const [ selected, updateSelected ] = useState('');

    const handleChoiceClick = (choice) => {
        updateSelected(choice);
        updateSelCount(1);
    }

    const handleSubmissionClick = () => {
        if (selected === answer){
            correctAnswer('That\'s correct! ✔');
        } else {
            incorrectAnswer('Try again. ✕');
        }
    }

    const handleClearClick = () => {
        updateSelected('');
        updateSelCount(0);
    }

    const renderRows = () => {
        const numRows = Math.ceil(options.length / choicesPerRow);
        let rows = [];
        for(let i = 0; i < numRows; i++){
            rows.push(renderRow(i))
        }
        return rows;
    }

    const renderRow = rowIndex => {
        const startIndex = rowIndex * choicesPerRow;
        let choices = [];
        for(let i = startIndex; i < startIndex + choicesPerRow; i++){
            const str = options[i];
            choices.push(renderChoice(str, i));
        }
        return(
            <div className="choiceRow" key={"row" + rowIndex}>
                {choices}
            </div>
        )
    }

    const renderChoice = (str, ind) => {
        let checked;
        if (reviewMode) {
            checked = answer===str;
        } else {
            checked = selected===str;
        }

        let visibility = "visible";
        if (!str) visibility = "hidden";
        return(
            <li className="choice" style={{visibility}} key={"choice" + ind}>
                <span>
                    {renderImage(ind)}
                    <input type="radio"  checked={checked} 
                    id={"option"+ind} name="radio" onChange={()=>handleChoiceClick(str)} 
                    />
                    <label htmlFor={"option"+ind}>
                        <span></span>
                        {str}
                    </label>
                </span>
            </li>
        );
    }

    const renderImage = ind => {
        if(!images) return null;
        return(
            <div>
                <div className="img">
                    <img src={images[ind]} alt={options[ind]}></img>
                </div>
            </div>
        );
    }


    return(
        <div>
            <ul id="choices">
                {renderRows()}
            </ul>
            <SubmissionArea 
            onSubmissionClick={handleSubmissionClick} 
            onClearClick={handleClearClick}
            />
        </div>
        
    )
    
}



export default connectInteractive(ChoicesMC);
