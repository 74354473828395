import React from 'react';
import SlideShow from '../exercises/SlideShow';
import { connect } from 'react-redux';
import { markVideoWatched, setSolved } from '../../actions/progress';

const SlideShowOverlay = props => {

    const handleSlideShowFinish = () => {
        props.markVideoWatched(props.caseStudy);
        props.setSolved(props.caseStudy, 1);
    }

    return(
        <div id="overlay-show">
            <div className="overlay-flex">

                <section>
                    <div className="overlay-slideshow">

                        <button className="btnClose" aria-label="Close" title="Close" onClick={props.Close}>
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <SlideShow 
                        onFinish={handleSlideShowFinish}
                        slides={ props.slides }
                        />

                    </div>
                </section>

            </div>
        </div>
    );

}

const mapStateToProps = state => ({
    caseStudy: state.navigation.caseStudy,
})

const mapDispatchToProps = dispatch => ({
    markVideoWatched: caseStudy => {
        dispatch(markVideoWatched(caseStudy));
    },
    setSolved: (caseStudy, page) => {
        dispatch(setSolved(caseStudy, page));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SlideShowOverlay);
