import { connect } from 'react-redux';
import { hideRekinnect } from '../../actions/exercise';

const mapStateToProps = state => ({
    showingRekinnect: state.exercise.showingRekinnect,
})

const mapDispatchToProps = dispatch => ({
    close: ()=>dispatch(hideRekinnect())
})

const connectRekinnect = rekinnectComponent => {
    return connect(mapStateToProps, mapDispatchToProps)(rekinnectComponent);
};

export default connectRekinnect;