import React from 'react';
import AlexConc from '../components/conclusions/AlexConc';
import AlexEx1 from '../components/interactive/AlexEx1';
import AlexEx2 from '../components/interactive/AlexEx2';
import ChoicesMC from '../components/interactive/ChoicesMC';
import ChoicesCB from '../components/interactive/ChoicesCB';
import AlexEx2Rekinnect from '../components/rekinnect/AlexEx2Rekinnect';
import { VISUAL_ASSET_TYPES } from '../constants';
import TutorialMC from '../components/tutorials/TutorialMC';
import TutorialCB from '../components/tutorials/TutorialCB';
import TutorialDrag from '../components/tutorials/TutorialDrag';


export default {
    title: 'The Case of the Soccer Player',
    name: 'Alex',
    profilePicSrc: '../../img/alex/profile.svg',
    introAsset: {
        type: 'slideshow',
        slides: [
            <img src="../img/alex/intro-scene1.png" alt="The Case of the Soccer Player" />,
            <img src="../img/alex/intro-scene2.png" alt="Players on a soccer field" />,
            <img src="../img/alex/intro-scene3.png" alt="Close-up of Alex in the lead with the ball" />,
            <img src="../img/alex/intro-scene4.png" alt="Close-up of Alex as the star of their team" />,
            <img src="../img/alex/intro-scene5.png" alt="Alex in front of the goal post" />,
            <video controls>
                <source src="../vid/alex-intro.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>,
            <img src="../img/alex/intro-scene7.png" alt="Alex scores!" />,
            <img src="../img/alex/intro-scene8.png" alt="Alex holding her thigh because she's hurt" />,
            <img src="../img/alex/intro-scene9.png" alt="Let's solve this kinundrum!" />,
        ]
    },
    observations: [
        "kicked the ball with high velocity and force" 
    ],
    conclusion: AlexConc,
    exercises: [

        // Exercise 1: Muscles for wind-up vs. kick

        {
            questionArea: {
                smallHeading: 'Exercise 1:',
                largeHeading: 'Muscles for wind-up vs. kick',
                paragraph: <p>What are the main muscles involved in the wind-up and kicking movement? 
                    <br />
                    <br /><b>Click and drag</b> to match the muscle to the movement type.
                    </p>,
                hint: 'Try standing up and going through the motions with your own leg! Think about the  muscles involved as you try it for yourself.',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.IMAGE,
                visualSource: "../img/alex/ex-1.png",
                visualAlt: "Alex during the wind-up and kick",
            },
            notes: [
                "hamstrings and glutes are main wind-up muscles involved in hip extension and knee flexion",
                "quads, iliopsoas, and rectus femoris are main kicking muscles involved in hip flexion and knee extension"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "Not quite!",
                paragraph: "Think about what muscles are involved in hip extension and knee flexion. Now link these actions to the muscles.",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 5,
            interactiveArea: AlexEx1,

            tutorial: TutorialDrag,
        },

        // Exercise 2: Wind-up vs. kicking contractions

        {
            questionArea: {
                smallHeading: 'Exercise 2:',
                largeHeading: 'Wind-up vs. kicking contractions',
                paragraph: <p>Can you identify what type of contraction is involved in the start and end of the wind up and kicking phase?
                    <br />
                    <br /><b>Click and drag</b> the muscle and its contraction type to the start and end of each action.
                    </p>,
                hint: 'The muscle needs to rapidly accelerate in the beginning and rapidly decelerate at the end of the contraction. Think about what type of contractions would be needed to do so.',
            },
            notes: [
                "wind-up start: hamstrings (concentric), glutes (concentric)",
                "wind-up end: quadriceps (eccentric), hip flexors (eccentric)",
                "kick start: quadriceps (concentric), hip flexors (concentric)",
                "kick end: hamstrings (eccentric), glutes (eccentric)",
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "Not quite!",
                paragraph: "Before you can move on, complete the Re-kinnect exercise.",
                numberOfAppears: 2,
                isRekinnect: true
            },
            maxSelCount: 8,
            interactiveArea: AlexEx2,
            rekinnect: AlexEx2Rekinnect,

            tutorial: TutorialDrag,
        },

        // Exercise 3: Alex's muscle strain

        {
            questionArea: {
                smallHeading: 'Exercise 3:',
                largeHeading: 'Alex\'s muscle strain',
                paragraph: <p>Based on the mechanism of Alex’s injury, choose the muscle most likely to be strained.
                    <br />
                    <br />Watch the video to see her injury again.
                    </p>,
                hint: 'During their kick, Alex hyperextends during knee extension. Which muscle normally contracts eccentrically to prevent the knee from hyperextending?',
            },
            visualAsset: {
                type: VISUAL_ASSET_TYPES.VIDEO,
                visualSource: "../vid/alex-ex3.mp4",
                visualAlt: "Alex Kicking",
            },
            notes: [
                "hyperextended during knee flexion, resulting in hamstring strain"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "",
                paragraph: "",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 1,
            interactiveArea: ChoicesMC,
            interactiveAreaProps: {
                options:[
                    "Hamstrings",
                    "Quadriceps",
                    "Glutes",
                    "Iliopsoas",
                    "Rectus femoris"
                ],
                answer:"Hamstrings",
                choicesPerRow:3,
            },

            tutorial: TutorialMC,

            rekinnect: null,
        },

        // Exercise 4: Hamstring strain
        {
            questionArea: {
                smallHeading: 'Exercise 4:',
                largeHeading: 'Hamstring strain',
                paragraph: 'Which coupled actions are going to aggravate the hamstring again? Choose all that are correct.',
                hint: 'When a muscle is aggravated/injured, pain could be produced by either contracting or fully stretching the muscle.',
            },
            notes: [
                "hamstring strain aggravated by either knee flexion/hip flexion or knee extension/hip flexion"
            ],
            incorrectOverlayConfig: {
                show: true,
                strong: "That's not right!",
                paragraph: "Sit on the floor and stretch your hamstring. Now stand up and contract your hamstring. Now you know what may aggravate it!",
                numberOfAppears: 2,
                isRekinnect: false
            },
            maxSelCount: 2,
            interactiveArea: ChoicesCB,
            interactiveAreaProps: {
                options:[
                    "Knee flexion and hip flexion together", 
                    "Knee flexion and hip extension together",
                    "Knee extension and hip flexion together", 
                    "Knee extension and hip extension together"
                ],
                answers:[
                    "Knee flexion and hip extension together",
                    "Knee extension and hip flexion together"
                ],
                choicesPerRow:1,
            },
            rekinnect: null,
            tutorial: TutorialCB,
        },

        // Exercise 5: Activities of daily living
        {
            questionArea: {
                smallHeading: 'Exercise 5:',
                largeHeading: 'Activities of daily living',
                paragraph: 'Poor Alex, their hamstring is going to be sore for a while. What other daily activities will aggravate their injury?',
                hint: 'For each activity, think about what happens at the joints. Stand up and try each movement!',
            },
            notes: [
                "hamstring strain aggravated by either knee flexion/hip flexion or knee extension/hip flexion, including activities such as: climbing up stairs, bending over with knees straight, and running"
            ],
            incorrectOverlayConfig: {
                show: false,
                strong: "",
                paragraph: "",
                numberOfAppears: 1,
                isRekinnect: false
            },
            maxSelCount: 3,
            interactiveArea: ChoicesCB,
            interactiveAreaProps: {
                options:[
                    "Bending to the ground with knees bent",
                    "Sitting in a chair",
                    "Climbing up stairs",
                    "Figure four stretch",
                    "Bending over with knees straight",
                    "Laying in bed (Netflixing)",
                    "Running",
                    "Standing",
                ],
                images:[
                    "../../img/alex/ex5-1-pickup.svg",
                    "../../img/alex/ex5-5-sit.svg",
                    "../../img/alex/ex5-2-climbup.svg",
                    "../../img/alex/ex5-6-figurefour.svg",
                    "../../img/alex/ex5-3-bendover.svg",
                    "../../img/alex/ex5-7-netflix.svg",
                    "../../img/alex/ex5-4-run.svg",
                    "../../img/alex/ex5-8-stand.svg"

                ],
                answers:[
                    "Climbing up stairs",
                    "Bending over with knees straight",
                    "Running"
                ],
                choicesPerRow:4,
            },
            rekinnect: null,
            tutorial: TutorialCB,
        },
    ]
};
