import React, { Component } from 'react';

class DraggableChoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentX: this.props.xOffset,
            currentY: this.props.yOffset,
            dragging: false,
            zIndex: 0,
        }
        this.startX = this.state.currentX;
        this.startY = this.state.currentY;

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    componentDidMount(){
        let containerElement = document.getElementById(this.props.containerId);
        this.absoluteX = containerElement.getBoundingClientRect().left;
        this.absoluteY = containerElement.getBoundingClientRect().top;

    }

    handleMouseDown(e){
        this.setState({dragging: true, zIndex: 10})
    }

    handleMouseUp(e){
        if(this.state.dragging){
            this.props.onDrop(this.props.ind, this.props.match, e.clientX,e.clientY)
            this.setState({dragging: false, currentX: this.startX, currentY:this.startY, zIndex: 0})
        }
    }

    handleMouseMove(e){
        if (this.state.dragging){
            this.props.onMove(this.props.ind, e.clientX,e.clientY)
            const xPosition = e.clientX - this.absoluteX-80;
            const yPosition = e.clientY - this.absoluteY-20;
            this.setState({currentX: xPosition, currentY: yPosition});
        }
    }

    render(){
        return(
            <div className='draggableChoiceMatch' 
                style={{
                    position:'absolute',
                    userSelect:'none',
                    left: this.state.currentX+'px',
                    top: this.state.currentY+'px',
                    width: this.props.width,
                    zIndex: this.state.zIndex,
                }}
                onMouseDown={this.handleMouseDown} 
                onMouseUp={this.handleMouseUp}
                onMouseMove={this.handleMouseMove}
                onMouseLeave={this.handleMouseUp}
            >
                {this.props.children}
            </div>
        )
    }
}

export default DraggableChoice;