import {
  MARK_VIDEO_WATCHED,
  SET_SOLVED,
  TOGGLE_INTRO_TUTORIAL,
  RESET,
  SHOW_BADGE,
} from "../actions/progress";
import { LOAD_USER } from "../actions/authentication";
import { CORRECT_ANSWER } from "../actions/exercise";

const initialState = {
  displayIntroTutorial: true,
  rookieBadgeShown: false,
  noviceBadgeShown: false,
  masterBadgeShown: false,
  expertBadgeShown: false,
  standingTallBadgeShown: false,
  van: {
    solved: -1, // max 4
    resolved: 0,
    videoSeen: false,
  },
  bernie: {
    solved: -1, // max 5
    resolved: 0,
    videoSeen: false,
  },
  jenny: {
    solved: -1, // max 4
    resolved: 0,
    videoSeen: false,
  },
  alex: {
    solved: -1, // max 6
    resolved: 0,
    videoSeen: false,
  },
  fred: {
    solved: -1, // max 6
    resolved: 0,
    videoSeen: false,
  },
  maryem: {
    solved: -1, // max 18
    resolved: 0,
    videoSeen: false,
  },
  gary: {
    solved: 0, // max 9
    resolved: 0,
    videoSeen: false,
  },
  ben: {
    solved: 0, // max 5
    resolved: 0,
    videoSeen: false,
  },
};

const progress = (state = initialState, action, navigationState) => {
  let currentSolved;
  let stateToChange;
  switch (action.type) {
    case MARK_VIDEO_WATCHED:
      return {
        ...state,
        [action.caseStudy]: {
          ...state[action.caseStudy],
          videoSeen: true,
        },
      };
    case SET_SOLVED:
      stateToChange = navigationState.mode === "redo" ? "resolved" : "solved";
      currentSolved = state[navigationState.caseStudy][stateToChange];
      return {
        ...state,
        [action.caseStudy]: {
          ...state[action.caseStudy],
          [stateToChange]: Math.max(currentSolved, action.page),
        },
      };
    case CORRECT_ANSWER:
      stateToChange = navigationState.mode === "redo" ? "resolved" : "solved";
      currentSolved = state[navigationState.caseStudy][stateToChange];
      return {
        ...state,
        [navigationState.caseStudy]: {
          ...state[navigationState.caseStudy],
          [stateToChange]: Math.max(currentSolved, navigationState.page),
        },
      };
    case RESET:
      return {
        ...state,
        [action.caseStudy]: {
          ...state[action.caseStudy],
          resolved: 0,
        },
      };
    case TOGGLE_INTRO_TUTORIAL:
      let currentTutorialDisplay = state.displayIntroTutorial;
      return {
        ...state,
        displayIntroTutorial: !currentTutorialDisplay,
      };
    case LOAD_USER:
      const { exercises, progress } = action.serverRes;

      let newState = { ...state };
      exercises.forEach((exercise) => {
        let name = exercise.Name;
        newState[name].solved = exercise.Solved;
        newState[name].videoSeen = exercise.VideoSeen;
        newState[name].resolved = exercise.Resolved;
      });

      newState.displayIntroTutorial = progress.ShowIntroTutorial;
      newState.rookieBadgeShown = progress.RookieBadge;
      newState.noviceBadgeShown = progress.NoviceBadge;
      newState.masterBadgeShown = progress.MasterBadge;
      newState.expertBadgeShown = progress.ExpertBadge;
      newState.standingTallBadgeShown = progress.StandingTallBadge;
      return newState;
    case SHOW_BADGE:
      const badgeState = action.badgeName + "BadgeShown";
      return {
        ...state,
        [badgeState]: true,
      };
    default:
      return state;
  }
};

export default progress;
