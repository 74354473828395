import React from 'react';
import SlideShowOverlay from '../overlays/SlideShowOverlay';
import VideoOverlay from '../overlays/VideoOverlay';
import { connect } from 'react-redux';
import { hideScenarioOverlay } from '../../actions/exercise';
import caseStudies from '../../casestudies';


const mapStateToProps = state => {
    const { caseStudy } = state.navigation;
    const caseStudyData = caseStudies[caseStudy];
    return {
        showingScenarioOverlay: state.exercise.showingScenarioOverlay,
        introAssetConfig: caseStudyData.introAsset,
    }
}

const mapDispatchToProps = dispatch => ({
    hideScenarioOverlay: ()=>dispatch(hideScenarioOverlay())
})

const ScenarioOverlay = ({showingScenarioOverlay, hideScenarioOverlay, introAssetConfig}) => {

    if (!showingScenarioOverlay) return null;

    switch(introAssetConfig.type){
        case 'video':
            return <VideoOverlay
                videoPath={introAssetConfig.vidSrc}
                ccPath={introAssetConfig.captionSrc}
                Close={hideScenarioOverlay}
            />
        case 'slideshow':
            return <SlideShowOverlay
                slides={introAssetConfig.slides}
                Close={hideScenarioOverlay}
            />
        default:
            return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioOverlay);