import React,{Component} from 'react';
import { connect } from 'react-redux';
import { gotoNextPage, gotoPrevPage } from '../../../actions/navigation';

const mapStateToProps = state => {
    const { caseStudy, page, mode } = state.navigation;
    const progressToCheck = mode === 'redo' ? 'resolved' : 'solved';
    const solved = state.progress[caseStudy][progressToCheck];
    const canNext = solved >= page;
    return { canNext };
}

const mapDispatchToProps = dispatch => ({
    Next: () => {
        dispatch(gotoNextPage());
    },
    Prev: () => {
        dispatch(gotoPrevPage());
    },
})

class SubmitNav extends Component{

    render(){
        return(
            <div id="submitNav">
                <a className="btn-link" id="back" title="Back to Introduction" onClick={this.props.Prev}>
                    <div className="btnIconLeft">
                        <svg width="7.4px" height="12px" viewBox="0 0 7.4 12">
                            <path className="btnIconLeft" d="M7.4,1.4L6,0L0,6l6,6l1.4-1.4L2.8,6L7.4,1.4z" />
                        </svg>
                    </div>Back
                </a>

                <a className={this.props.canNext ? "btn-link" : "btn-link disabled"} id="next" title="Next Exercise" onClick={this.props.Next}>Next
                    <div className="btnIconRight">
                        <svg width="7.4px" height="12px" viewBox="0 0 7.4 12">
                            <path className="btnIconLeftRight" d="M0,1.4L1.4,0l6,6l-6,6L0,10.6L4.6,6L0,1.4z" />
                        </svg>
                    </div>
                </a>
            </div>
        );
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(SubmitNav);
