import React from 'react';
import { connect } from 'react-redux';
import { gotoNextPage } from '../../../actions/navigation';
import { setSolved } from '../../../actions/progress';


const mapStateToProps = state => {
    const caseStudy = state.navigation.caseStudy;
    return {
        caseStudy,
        videoSeen: state.progress[caseStudy].videoSeen,
    }
}

const mapDispatchToProps = dispatch => ({
    gotoNextPage: () => {
        dispatch(gotoNextPage());
    },
    setSolved: (caseStudy) => {
        dispatch(setSolved(caseStudy, 1));
    },
});

const StartButton = ({ videoSeen, gotoNextPage, setSolved, caseStudy }) => {

    const handleOnClick = () => {
        gotoNextPage();
        setSolved(caseStudy);
    }

    const startBtnClass = videoSeen ? "btn-ghost" : "btn-ghost disabled";
    return <a className={startBtnClass} id="startIntro" title="go to Exercise 1" onClick={handleOnClick}>Start</a>
}

export default connect(mapStateToProps, mapDispatchToProps)(StartButton);

