import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const BenConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Thoracic Hyperkyphosis
              </h2>

              <p>
                Usually the spine has four main curves: two kyphotic curves
                (thoracic and sacral regions) and two lordotic curves (cervical
                and lumbar regions). Having these curves aligned atop each other
                ensures that forces are absorbed through the spine like a
                spring, that your center of balance is maintained and you use
                your muscles and ligaments efficiently to maintain alignment.
              </p>
              <p>
                Hyperkyphosis is an exaggeration of normal kyphosis, typically
                found in the thoracic region of the spine. When this change
                becomes permanent or fixed (because let’s be clear, we ALL get
                lazy and slouch sometimes), it can be as a result of the change
                of shape of the disc or vertebral body (becoming thinner or
                wedge-shaped). Ultimately, if you cannot return to a more ideal
                alignment, then your soft tissues will adapt poorly, and can
                cause pain and mechanical issues.
              </p>
              <p>
                In this case, we have seen the posture change stretch out
                posterior ligaments and muscles of the back…causing pain. The
                discs are being pushed backwards and perhaps also becoming
                damaged. And as we saw in the last case, this could also
                compress nerves!
              </p>
              <p>
                The hyperkyphotic posture also reduced the space in the thoracic
                cage and compressed the lungs, making breathing/expansion
                difficult. The diaphragm also might become impacted, and if you
                think about what is just underneath the diaphragm, you could
                imagine that it might cause some digestive issues as well!
              </p>
              <p>
                Lastly, we learned that thoracic position has a very important
                impact on our shoulder movement. This is a complex biomechanical
                relationship - too complex to explain in full detail but the
                brief version is as follows:
              </p>
              <ul>
                <li>
                  Excessive flexion of the thoracic vertebrae allows the
                  scapulae to shift forward (think gravity pulling into
                  protraction) which is not an ideal position for the scapula.
                </li>
                <li>
                  The scapula will not be able to rotate upwards all the way
                  which means the humerus no longer has a ‘base’ to rotate on
                  and ultimately you cannot lift up into full elevation.
                </li>
                <li>
                  The change in scapula/humerus position will also narrow the
                  space that the tendons from the scapula passing to the humerus
                  (think rotator cuff!) run through…and over time, constantly
                  compressing these could lead to tendonitis and pain.
                </li>
                <li>
                  Lastly, over time, you could end up changing length of
                  muscles, ligaments and even the shape of your vertebrae!
                </li>
              </ul>
              <p>
                Phew! That was a lot going on for one change in alignment... SO
                STAND TALL and preserve your spinal curves :)
              </p>
            </div>

            <div id="concImg">
              <img src="../img/ben/conc_1.png" alt="ligaments" />
              <figcaption>
                Figure 1. Soft tissues (vertebral disc, ligaments) adapting to
                hyperkyphosis. Stretching out posterior structures and pushing
                back vertebral discs.
              </figcaption>
              <img src="../img/ben/conc_2.png" alt="lung expansion" />
              <figcaption>
                Figure 2. Hyperkyphosis reduces space in the ribcage, which
                compresses the lungs
              </figcaption>
              <img src="../img/ben/conc_3.png" alt="scapula" />
              <figcaption>
                Figure 3. Hyperkyphosis shifts the scapula forward, limiting
                upward rotation. This change in position also reduces space for
                the tendon between the scapula and humerus. Constant compression
                leads to tendonitis and pain.{" "}
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Anatomy overview</b>
                          <br />
                          Which part of his spine is most affected and what is
                          this alignment change called?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/ben/ex_1.png"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Use the Re-kinnect to compare Ben’s radiograph to a
                          typical spine radiograph/alignment here to determine
                          what has changed.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw8"></div>
                      <div className="lh-8a"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box8a">Cervical</li>
                        <li className="flo-box8 correct">Thoracic</li>
                        <li className="flo-box8">Lumbar</li>
                        <li className="flo-box8">Sacral</li>
                        <li className="flo-box8">Kyphotic</li>
                        <li className="flo-box8 correct">Hyperkyphotic</li>
                        <li className="flo-box8">Lordotic</li>
                        <li className="flo-box8">Hyperlordotic</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-8a"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw8"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Injury mechanism</b>
                          <br />
                          In the thoracic region, what three structures might be
                          causing Ben’s back pain?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/ben/ex_2.png"
                            alt="Visual asset for Exercise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Use the Re-kinnect model to visually observe what is
                          going on during movement from neutral to flexion at a
                          spinal segment.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box7a correct">
                          Posterior spinal ligaments
                        </li>
                        <li className="flo-box7">Anterior spinal ligaments</li>
                        <li className="flo-box7 correct">
                          Back muscles
                          <br />
                          <br />
                        </li>
                        <li className="flo-box7">
                          Shoulder muscles
                          <br />
                          <br />
                        </li>
                        <li className="flo-box7">
                          Vertebral body
                          <br />
                          <br />
                        </li>
                        <li className="flo-box7">
                          Disc
                          <br />
                          <br />
                        </li>
                        <li className="flo-box7">
                          Articular facets
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Injury mechanism</b>
                          <br />
                          He says he has to take the golf cart more now, when he
                          walks long distances. What is happening? Choose the
                          system that is the biggest problem area.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/ben/ex_3.png"
                            alt="Visual asset for Exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          What structure is going to be MOST impacted by
                          hyperkyphosis and reduce his aerobic endurance?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a">
                          Heart
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5 correct">
                          Lungs
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Nerve
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Intercostal nerve
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">Muscles of the chest wall</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Injury mechanism</b>
                          <br />
                          Ben has said he isn’t swinging his golf club as well
                          lately. And he’s also having shoulder pain doing
                          landscaping tasks. Are these related in some way? What
                          do you suppose is causing the issue? Choose all that
                          apply from the list.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/ben/ex_4.png"
                            alt="Visual asset for Exercise 4"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Use the Re-kinnect to observe Ben's muscles and
                          scapula, in particular, when he lifts his arm.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a correct">
                          Shoulder muscles are weak
                          <br />
                          <br />
                        </li>
                        <li className="flo-box6 correct">
                          Shoulder joint is restricted in moving
                        </li>
                        <li className="flo-box6 correct">
                          Chest muscles are short
                          <br />
                          <br />
                        </li>
                        <li className="flo-box6 correct">
                          Back joints are not moving properly
                        </li>
                        <li className="flo-box6 correct">
                          Back muscles are weak
                          <br />
                          <br />
                        </li>
                        <li className="flo-box6 correct">
                          Shoulder tendons are getting compressed
                        </li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default BenConc;
