import React, { useState } from 'react';

import { sendDataSecure } from '../../api';
import { HeadingInput, RoleInput } from './inputs';

export default ({getAllUsers, token}) => {
    const [createUserMssg, updateCreateUserMssg] = useState(null);

    const createUser = async () => {
        updateCreateUserMssg(null);
        const studentNumber = document.getElementById('studentNumber').value;
        const password = document.getElementById('password').value;

        if (studentNumber === "" || password === "") {
            updateCreateUserMssg(<h4 style={{color: "red"}}>Username and Password field cannot be empty</h4>)
            return
        }
        const role = document.getElementById('role').value;

        let body = new FormData();
        body.append('studentNumber', studentNumber);
        body.append('password', password);
        body.append('role', role);

        let response = await sendDataSecure("admin/register", body, 'POST', token);
        if (response.status >= 200 && response.status < 300) {
            updateCreateUserMssg(<h4 style={{color: "green"}}>Successfully created user</h4>);
            getAllUsers();
        } else {
            updateCreateUserMssg(<h4 style={{color: "red"}}>Failed to create user</h4>);
        }
    }
    return (
        <div id='create-user'>
            <h1>Create New User</h1>
            <br/><hr/>
            <div className='create-user-inputs'>
                <HeadingInput heading='Username' id='studentNumber'/>
                <HeadingInput heading='Password' id='password'/>
                <RoleInput heading='Role' id='role'/>
            </div>
            <button className='btn-ghost' onClick={createUser}>Create User</button>
            {createUserMssg}
        </div>
    );
}