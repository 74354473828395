import React, {Component} from 'react';
import { EVENT_TYPES } from '../../constants';
import { DragArea, draggable, dragContainer } from '../exercises/DragAndDrop'
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';

const { EVENT_GUESSING } = EVENT_TYPES;

const CHOICES = [
    'Hip flexors (concentric)',
    'Quadriceps (concentric)',
    'Hamstrings (concentric)',
    'Glutes (concentric)',
    'Hip flexors (eccentric)',
    'Quadriceps (eccentric)',
    'Hamstrings (eccentric)',
    'Glutes (eccentric)',
];

const ANSWER = {
    windUpStart:[
        'Hamstrings (concentric)',
        'Glutes (concentric)',
    ],
    windUpEnd:[
        'Hip flexors (eccentric)',
        'Quadriceps (eccentric)',
    ],
    kickStart:[
        'Hip flexors (concentric)',
        'Quadriceps (concentric)',
    ],
    kickEnd:[
        'Hamstrings (eccentric)',
        'Glutes (eccentric)',
    ],
};

class AlexEx2 extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            unselected: CHOICES,
            windUpStart: [],
            windUpEnd: [],
            kickStart: [],
            kickEnd: [],
            numberOfWrongChoices: 0,
            currentlyHoveredContainer: "",
        }

        this.getCurrSelCount = this.getCurrSelCount.bind(this);
        this.handleSubmissionClick = this.handleSubmissionClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleDragDrop = this.handleDragDrop.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.getWrongChoices = this.getWrongChoices.bind(this);
    }

    getCurrSelCount(){
        return CHOICES.length - this.state.unselected.length;
    }

    handleSubmissionClick(){
        const containerNames = Object.keys(ANSWER);
        let numberOfWrongChoices = 0;
        for(let i = 0; i < containerNames.length; i++){
            const containerName = containerNames[i];
            numberOfWrongChoices += this.getWrongChoices(containerName).length;
        }
        if (numberOfWrongChoices > 0){
            this.props.incorrectAnswer('Try again. ✕');
        } else {
            this.props.correctAnswer('That\'s correct! ✔');
        }
    }

    handleClearClick(){
        if(this.props.event === EVENT_GUESSING){
            this.setState({
                unselected: CHOICES,
                windUpStart: [],
                windUpEnd: [],
                kickStart: [],
                kickEnd: [],
            })
            return;
        }
        let wrongChoices = {};
        let allWrongChoices = [];
        let nextContainers = {};
        const containerNames = Object.keys(ANSWER);
        for(let i = 0; i < containerNames.length; i++){
            const containerName = containerNames[i];
            nextContainers[containerName] = [...this.state[containerName]];
            wrongChoices[containerName] = this.getWrongChoices(containerName);
            allWrongChoices.push(...this.getWrongChoices(containerName))
            for(let j = 0; j < wrongChoices[containerName].length; j++){
                const wrongChoice = wrongChoices[containerName][j];
                const indexOfWrongChoice = nextContainers[containerName].indexOf(wrongChoice);
                nextContainers[containerName].splice(indexOfWrongChoice, 1);
            }
        }
        this.setState({
            event: EVENT_GUESSING,
            unselected: allWrongChoices,
            windUpStart: nextContainers['windUpStart'],
            windUpEnd: nextContainers['windUpEnd'],
            kickStart: nextContainers['kickStart'],
            kickEnd: nextContainers['kickEnd'],
        })
        let currSelCount = 0
        currSelCount += nextContainers['windUpStart'].length
        currSelCount += nextContainers['windUpEnd'].length
        currSelCount += nextContainers['kickStart'].length
        currSelCount += nextContainers['kickEnd'].length
        this.props.updateSelCount(currSelCount);

    }

    getWrongChoices(containerName){
        let wrongChoices = [];
        const container = this.state[containerName];
        const answerContainer = ANSWER[containerName];
        for(let i = 0; i < container.length; i++){
            const choice = container[i];
            const answerIndex = answerContainer.indexOf(choice);
            if(answerIndex === -1){
                wrongChoices.push(choice);
            }
        }
        return wrongChoices;
    }

    handleDragDrop(choice, prevContainer, nextContainer){
        if (prevContainer === nextContainer) return;
        let prevContainerObjects = [...this.state[prevContainer]];
        let nextContainerObjects = [...this.state[nextContainer]];
        const choiceIndex = prevContainerObjects.indexOf(choice);
        prevContainerObjects.splice(choiceIndex, 1);
        nextContainerObjects.push(choice);

        let currSelCount = this.getCurrSelCount();
        if (prevContainer === 'unselected') currSelCount ++;
        if (nextContainer === 'unselected') currSelCount --;
        this.props.updateSelCount(currSelCount);    

        this.setState({
            event: EVENT_GUESSING,
            [prevContainer]: prevContainerObjects,
            [nextContainer]: nextContainerObjects,
            currentlyHoveredContainer: "",
        })
    }

    handleHover(containerID){
        this.setState({currentlyHoveredContainer: containerID})
    }

    render(){

        const windUpStartClass = this.state.currentlyHoveredContainer === 'windUpStart' ? "hovered" : "unhovered";
        const windUpEndClass = this.state.currentlyHoveredContainer === 'windUpEnd' ? "hovered" : "unhovered";
        const kickStartClass = this.state.currentlyHoveredContainer === 'kickStart' ? "hovered" : "unhovered";
        const kickEndClass = this.state.currentlyHoveredContainer === 'kickEnd' ? "hovered" : "unhovered";

        let windUpStartRender, windUpEndRender, kickStartRender, kickEndRender, unselectedRender;
        if(this.props.reviewMode){
            windUpStartRender = ANSWER['windUpStart'];
            windUpEndRender = ANSWER['windUpEnd'];
            kickStartRender = ANSWER['kickStart'];
            kickEndRender = ANSWER['kickEnd'];
            unselectedRender = [];
        } else {
            windUpStartRender = this.state.windUpStart;
            windUpEndRender = this.state.windUpEnd;
            kickStartRender = this.state.kickStart;
            kickEndRender = this.state.kickEnd;
            unselectedRender = this.state.unselected;
        }

        return (
            <div>
                <DragArea 
                onDragDrop={this.handleDragDrop}
                onHover={this.handleHover}
                >
                    <div className="choicediv-alex2">
                        <div className="div-dragto">
                            <div className="col">
                                <p>Start</p>
                                <p>End</p>
                            </div>
                            <div className="dragtocol">
                                <p>Wind-up</p>
                                {dragContainer(
                                    <div id="dragto-windupstart" className={windUpStartClass}>
                                        {windUpStartRender.map(choice=>renderChoice(choice))}
                                    </div>,
                                    'windUpStart'
                                )}
                                {dragContainer(
                                    <div id="dragto-windupend" className={windUpEndClass}>
                                        {windUpEndRender.map(choice=>renderChoice(choice))}
                                    </div>,
                                    'windUpEnd'
                                )}
                            </div>
                            <div className="dragtocol">
                                <p>Kick</p>
                                {dragContainer(
                                    <div id="dragto-kickstart" className={kickStartClass}>
                                        {kickStartRender.map(choice=>renderChoice(choice))}
                                    </div>,
                                    'kickStart'
                                )}
                                {dragContainer(
                                    <div id="dragto-kickend" className={kickEndClass}>
                                        {kickEndRender.map(choice=>renderChoice(choice))}
                                    </div>,
                                    'kickEnd'
                                )}
                            </div>
                        </div>
                        <div className="div-dragfrom">
                            <div className="col"></div>
                            {dragContainer(
                                <div style={{height: '200px'}}>
                                    <div className="dragfromcol">
                                        {unselectedRender.map(choice=>renderChoice(choice, 'concentric'))}
                                    </div>
                                    <div className="dragfromcol" style={{float: 'right'}}>
                                        {unselectedRender.map(choice=>renderChoice(choice, 'eccentric'))}
                                    </div>
                                </div>,
                                'unselected'
                            )}
                            
                        </div>
                    </div>
                </DragArea>
                <SubmissionArea 
                onSubmissionClick={this.handleSubmissionClick}
                onClearClick={this.handleClearClick}
                />
            </div>
        )
    }

}

const renderChoice = (choice, choiceType)=>{
    if(choice.search(choiceType) !== -1){
        return draggable(
            <div className="dragchoice">{choice}</div>,
            choice
        );
    } else {
        return null
    } 

}

export default connectInteractive(AlexEx2);
