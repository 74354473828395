import React from 'react';
import { connect } from 'react-redux';
import { markVideoWatched, setSolved } from '../../actions/progress';

const VideoOverlay  = props => {

    const handlePlay = () => {
        props.markVideoWatched(props.caseStudy);
        props.setSolved(props.caseStudy, 1);
    }

    return(
        <div id="overlay-show">
            <div className="overlay-flex">

                <section>
                    <div className="overlay-vid">

                        <video id="overlayvideo" className="video" onPlay={handlePlay} controls>
                            <source src={props.videoPath} type="video/mp4" />
                            <track default src={props.ccPath} label="English" kind="captions" srcLang="en-us" />
                            Your browser does not support the video tag.
                        </video>

                        <button className="btnClose" aria-label="Close" title="Close" onClick={props.Close}>
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                </section>

            </div>
        </div>
    );

}

const mapStateToProps = state => ({
    caseStudy: state.navigation.caseStudy,
})

const mapDispatchToProps = dispatch => ({
    markVideoWatched: caseStudy => {
        dispatch(markVideoWatched(caseStudy));
    },
    setSolved: (caseStudy, page) => {
        dispatch(setSolved(caseStudy, page));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)( VideoOverlay );
