import React, {useState} from 'react';
import { connect } from 'react-redux';

import { loadUser } from '../../actions/authentication';
import { postData } from '../../api/';

const Login = ({ loadUser }) => {
    
    const [validStudentNumber, setValidStudentNumber] = useState(true);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [prevPassword, setPrevPassword] = useState("");
    const [prevStudentNumber, setPrevStudentNumber] = useState("");

    const attemptLogin = async () => {
        const studentNumber = document.getElementById('studentnumber').value;
        const password = document.getElementById('password').value;

        if (studentNumber !== prevStudentNumber || password !== prevPassword) {
            setValidStudentNumber(true);
            setInvalidPassword(false);
        }

        const isValid = /^([a-zA-Z0-9]){1,}$/.test(studentNumber);
        setValidStudentNumber(isValid);

        if (isValid && (studentNumber !== prevStudentNumber || password !== prevPassword)) {    
            let body = new FormData();
            body.append('studentNumber', studentNumber);
            body.append('password', password);

            let response = await postData('login', body);
            if (response.status >= 200 && response.status <= 299) {
                const responseJSON = await response.json();
                loadUser(responseJSON);
            } else {
                setInvalidPassword(true);
                setPrevPassword(password);
                setPrevStudentNumber(studentNumber);
            }
        }

    }


    const invalidStudentNumberWarning = <p style={{color:"red",textAlign:"center", marginTop:"15px"}}> Invalid Username </p>;
    const invalidPasswordWarning = <p style={{color:"red",textAlign:"center", margin:"15px"}}> Wrong Password </p>;

 
    const handleEnterPress = e => {
        if (e.key === 'Enter') attemptLogin();
    }

    return (
        <main>
            <div className="login-bg-top">
                <img className="icon" src="img/icons/icon-kinundrum-white.svg" alt="Kinundrum logo" />
            </div>

            <div className="login-bg-bottom">
                <div className="login-area">

                    <div>
                        <p><b>Hey! Before you log on, please note:</b>
                            <ul>
                                <li>Use on <b>desktop ONLY</b> - not your phone or tablet</li>
                                <li>Use <b>Chrome</b>, <b>Safari</b> or <b>Firefox</b></li>
                                <li>Email <b>kinundrumuoft@gmail.com</b> if you run into issues or errors</li>
                            </ul>
                        </p>  
                    </div>
                    <input type="text" id="studentnumber" placeholder="Username" onKeyUp={handleEnterPress}/>
                    <input type="password" id="password" placeholder="Password" onKeyUp={handleEnterPress}/>

                    <a className="btn-ghost" id="login" onClick={() => attemptLogin()}>
                        Log in
                    </a>
                    {!validStudentNumber && invalidStudentNumberWarning}
                    {invalidPassword && invalidPasswordWarning}
                </div>

            </div>
        </main>
    );
}

const mapDispatchToProps = dispatch => ({
    loadUser: async serverRes => {
        dispatch(loadUser(serverRes));
    }
});

export default connect(null, mapDispatchToProps)(Login);
