export const MARK_VIDEO_WATCHED = 'MARK_VIDEO_WATCHED';
export const SET_SOLVED = 'SET_SOLVED';
export const TOGGLE_INTRO_TUTORIAL = 'TOGGLE_INTRO_TUTORIAL';
export const RESET = 'RESET';
export const SHOW_BADGE = 'SHOW_BADGE';

export const markVideoWatched = caseStudy => ({
    type: MARK_VIDEO_WATCHED,
    caseStudy,
});

export const setSolved = (caseStudy, page) => ({
    type: SET_SOLVED,
    caseStudy,
    page,
});

export const toggleIntroTutorial = () => ({
    type: TOGGLE_INTRO_TUTORIAL,
});

export const reset = caseStudy => ({
    type: RESET,
    caseStudy,
})

export const showBadge = badgeName => ({
    type: SHOW_BADGE,
    badgeName,
})
