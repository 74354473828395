import React, { useState, useEffect } from 'react';
import CreateUser from './createUser';
import EditUser from './editUser';
import { sendDataSecure } from '../../api';

const ManageUsers = ({token}) => {

    const [allUsers, setAllUsers] = useState([]);

    const getAllUsers = async () => {
        let response = await sendDataSecure("admin/allusers",null,"GET",token);
        if (response.status >= 200 && response.status < 300) {
            let responseJSON = await response.json();
            const allStudents = responseJSON.users.map(user => ({
                'studentNumber': user.StudentNumber,
                'role': user.Role,
            }));
            setAllUsers(allStudents);
        }
    }

    useEffect(()=> {
        getAllUsers();
    },[])

    return (
        <div id='content'>
            <CreateUser {...{getAllUsers, token}}/>
            <EditUser {...{allUsers, getAllUsers}}/>
        </div>
    );
}

export default ManageUsers;