import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ExerciseFeedback from './ExerciseFeedback';
import ButtonSubmit from './ButtonSubmit';
import ButtonClear from './ButtonClear';
import SubmitNav from './SubmitNav';
import { setClearFunction } from '../../../actions/exercise';

const SubmissionArea = ({onSubmissionClick, onClearClick, setClearFunction}) => {

    useEffect(()=>{
        setClearFunction(onClearClick);
    });

    return (
        <div id="submission">
            <ExerciseFeedback/>
            <div id="submitButtons">
                <ButtonSubmit onSubmissionClick={onSubmissionClick}/>
                <ButtonClear onClearClick={onClearClick}/>
            </div>
            <SubmitNav/>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    setClearFunction: clearFunction => dispatch(setClearFunction(clearFunction)),
})

export default connect(null, mapDispatchToProps)(SubmissionArea);
