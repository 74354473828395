import React from "react";
import HoverAnimation from "../../animations/HoverAnimation";
import Topnav from "../../navigation/Topnav";
import CaseStudiesSection from "./CaseStudiesSection";

const Home = () => {
  return (
    <div className="Home">
      <Topnav page="home" />
      <div id="content">
        <main>
          <HeroSection />
          <LearningGoalsSection />
          <CaseStudiesSection />
          <AboutTheProjectSection />
        </main>
      </div>
    </div>
  );
};

const HeroSection = () => (
  <section>
    <div id="hero">
      <div className="heroIcon">
        <HoverAnimation path="../bodymovin/home/hero-hip.json" />
      </div>
      <div className="heroIcon">
        <HoverAnimation path="../bodymovin/home/hero-knee.json" />
      </div>
      <div className="heroIcon">
        <HoverAnimation path="../bodymovin/home/hero-ankle.json" />
      </div>
      <div className="heroRight">
        <h1>Ready to solve some Kinundrums?</h1>
        <a
          className="btn-ghost-white"
          href="#caseStudies"
          title="go to Case Studies"
        >
          Let's get started
        </a>
      </div>
    </div>
  </section>
);

const LearningGoalsSection = () => (
  <section id="learningGoals" className="xtarget">
    <div className="section">
      <div className="sectionLeft">
        <h2>Learning Goals</h2>
        <p>
          <strong>Let's face it.</strong> Anatomy requires a lot of memorizing,
          but that's not all there is to it. You need to be able to apply your
          knowledge to real-life scenarios.
        </p>
      </div>

      <div className="sectionRight">
        <div className="lgsectionLevel">
          <div className="lgBoxTitle">
            Key
            <br /> Concepts
          </div>
          <ul>
            <li className="lgBox">
              <p>
                <b>Group muscle dynamics</b>
              </p>
              <p>
                Each anatomical region has individual structures that contribute
                to their function as a whole
              </p>
            </li>
            <li className="lgBox">
              <p>
                <b>Integrate form and function</b>
              </p>
              <p>
                The shape, orientation and 3D position of a structure informs
                its functionality
              </p>
            </li>
            <li className="lgBox">
              <p>
                <b>Normal vs. abnormal</b>
              </p>
              <p>
                The effect of denervation on movement and normal resting
                position
              </p>
            </li>
          </ul>
        </div>

        <div className="lgsectionLevel">
          <div className="lgBoxTitle">Key Skills</div>
          <ul>
            <li className="lgBox">
              <div id="bmlevels" className="skills">
                <HoverAnimation path="../bodymovin/home/s-levels.json" />
              </div>
              <p>
                <b>Think across levels of organization</b>
              </p>
            </li>
            <li className="lgBox">
              <div id="bmextrapolate" className="skills">
                <HoverAnimation path="../bodymovin/home/s-extrapolate.json" />
              </div>
              <p>
                <b>Extrapolate from previous knowledge</b>
              </p>
            </li>
            <li className="lgBox">
              <div id="bmsystems" className="skills">
                <HoverAnimation path="../bodymovin/home/s-systems.json" />
              </div>
              <p>
                <b>Break down and systematically solve a case study problem</b>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const AboutTheProjectSection = () => (
  <section id="about" className="xtarget">
    <div className="section">
      <div className="sectionLeft">
        <h2>About the Project</h2>

        <p>
          Designed to address the need for a learning tool that engages
          undergraduate kinesiology students in active learning,{" "}
          <i>Kinundrum</i> guides students through lower limb and spine
          biomechanics case studies at increasing levels of difficulty. Case
          studies present animated injury scenarios, after which students solve
          interactive problems accompanied by 3D models, animations, and
          illustrations of the affected anatomy.
        </p>

        <p>
          At the intersection of art, science, education, and technology,{" "}
          <i>Kinundrum</i> combines problem-based learning pedagogy with
          multimedia design and web technology to create an engaging learning
          tool that can be accessed outside of the formal classroom.
        </p>

        <p>
          <b>
            If any issues, errors or questions come up, please shoot us an email
            at <u>kinundrumuoft@gmail.com</u>
          </b>
        </p>
      </div>

      <div className="sectionRight">
        <div className="aboutdiv">
          <div className="aboutgroup">
            <b>Team Leg</b>
          </div>
          <ul>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-chel.jpg"
                alt="Chelsea Canlas"
              />
              <a href="https://www.chelseacanlasvisuals.com/">
                <b>Chelsea Canlas</b>
              </a>
              <br />
              <i>
                Lead Designer
                <br />
                BMC, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-nati.jpg"
                alt="Natividad Chen"
              />
              <a href="http://www.natividadchen.com">
                <b>Natividad Chen</b>
              </a>
              <br />
              <i>
                Lead Animator
                <br />
                BMC, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-pat.jpg"
                alt="Patricia Nguyen"
              />
              <a href="http://www.pennguyen.com">
                <b>Patricia Nguyen</b>
              </a>
              <br />
              <i>
                Lead Illustrator
                <br />
                BMC, U of T
              </i>
            </li>
          </ul>
        </div>
        <div className="aboutdiv">
          <div className="aboutgroup">
            <b>Team Spine</b>
          </div>
          <ul>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-janell.jpg"
                alt="Janell Lin"
              />
              <a href="https://janell-lin.format.com/">
                <b>Janell Lin</b>
              </a>
              <br />
              <i>BMC, U of T</i>
            </li>
          </ul>
        </div>

        <div className="aboutdiv">
          <div className="aboutgroup">
            <b>Committee</b>
          </div>
          <ul>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-laprade.jpg"
                alt="Judi Laprade"
              />
              <b>Dr. Judi Laprade</b>
              <br />
              <i>
                Content Advisor
                <br />
                Department of Surgery, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-corrin.jpg"
                alt="Michael Corrin"
              />
              <b>Prof. Michael Corrin</b>
              <br />
              <i>
                Supervisor
                <br />
                BMC, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-jenkinson.jpg"
                alt="Jodie Jenkinson"
              />
              <b>Dr. Jodie Jenkinson</b>
              <br />
              <i>
                Supervisor
                <br />
                BMC, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-ng.jpg"
                alt="Derek Ng"
              />
              <b>Dr. Derek Ng</b>
              <br />
              <i>
                Supervisor
                <br />
                BMC, U of T
              </i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-wall.jpg"
                alt="Shelley Wall"
              />
              <b>Dr. Shelley Wall</b>
              <br />
              <i>
                Supervisor
                <br />
                BMC, U of T
              </i>
            </li>
          </ul>
        </div>
        <div className="aboutdiv">
          <div className="aboutgroup">
            <b>Developers</b>
          </div>
          <ul>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-michael.jpg"
                alt="Michael Le"
              />
              <a href="https://www.linkedin.com/in/lemich/">
                <b>Michael Le</b>
              </a>
              <br />
              <i>Lead Developer</i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-thomas.jpg"
                alt="Thomas Bernard Armena"
              />
              <a href="https://www.linkedin.com/in/thomas-armena/">
                <b>Thomas Armena</b>
              </a>
              <br />
              <i>Developer</i>
            </li>
            <li className="aboutgroup">
              <img
                className="placeholder"
                src="../img/home/about-jason.jpg"
                alt="Jason Lao"
              />
              <b>Jason Lao</b>
              <br />
              <i>Consultant</i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default Home;
