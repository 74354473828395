import React, { useEffect } from "react";
import { connect } from "react-redux";
import Topnav from "../../navigation/Topnav";
import HoverAnimation from "../../animations/HoverAnimation";
import ProgressBar from "./ProgressBar";
import {
  getCaseStudyProgresses,
  getBadgesRecieved,
  getHighestBadge,
} from "../../../utils/progressHelpers";
import { sendDataSecure } from "../../../api";

const mapStateToProps = (state) => {
  return {
    caseStudyProgresses: getCaseStudyProgresses(state),
    badgesRecieved: getBadgesRecieved(state),
    highestBadge: getHighestBadge(state),
    authentication: state.authentication,
  };
};

const User = ({
  caseStudyProgresses,
  badgesRecieved,
  highestBadge,
  authentication,
}) => {
  useEffect(() => {
    const { token, studentNumber, role } = authentication;
    let body = new FormData();
    body.append("role", role);
    body.append("studentNumber", studentNumber);

    sendDataSecure("data/userProfile", body, "POST", token);
  }, []);

  const { role } = authentication;

  return (
    <div className="User">
      <Topnav page="user" />

      <main>
        <div id="content">
          <section>
            <div className="title">
              <h1>User Profile</h1>
              <div className="title-status">
                <h2>Your Level:</h2>
                <h2 id="level">{highestBadge}</h2>
              </div>
            </div>
          </section>

          <section>
            <div className="section">
              <h2>Levels</h2>
              <div className="level">
                <div className="levelLeft">
                  <h3>Level 1</h3>
                  <p>Pretty easy. You should know this.</p>
                </div>

                <ul className="levelCenter">
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["bernie"]} />
                    <p>The Case of the Tennis Mom</p>
                  </li>
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["van"]} />
                    <p>The Case of the Rebel Driver</p>
                  </li>
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["jenny"]} />
                    <p>The Case of the Volleyball Player</p>
                  </li>
                </ul>

                <div className="levelRight">
                  <div className="levelBadge">
                    <p>Completed first case study</p>
                    <div id="bmrookie">
                      <HoverAnimation
                        disabled={!badgesRecieved["Rookie"]}
                        path="../bodymovin/user/rookie.json"
                      />
                    </div>
                  </div>
                  <div className="levelBadge">
                    <p>Completed Level 1</p>
                    <HoverAnimation
                      disabled={badgesRecieved["Novice"] ? "" : "disabled"}
                      path="../bodymovin/user/novice.json"
                    />
                  </div>
                </div>
              </div>

              <div className="level">
                <div className="levelLeft">
                  <h3>Level 2</h3>
                  <p>Put that thinking cap on.</p>
                </div>

                <ul className="levelCenter">
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["alex"]} />
                    <p>The Case of the Soccer Player</p>
                  </li>
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["fred"]} />
                    <p>The Case of the Weightlifter</p>
                  </li>
                </ul>

                <div className="levelRight">
                  <div className="levelBadge">
                    <p>Completed Level 2</p>
                    <HoverAnimation
                      disabled={badgesRecieved["Expert"] ? "" : "disabled"}
                      path="../bodymovin/user/expert.json"
                    />
                  </div>
                </div>
              </div>

              <div className="level">
                <div className="levelLeft">
                  <h3>Level 3</h3>
                  <p>This one's tough but you got this!</p>
                </div>

                <ul className="levelCenter">
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["maryem"]} />
                    <p>The Case of the Alpine Skier</p>
                  </li>
                </ul>

                <div className="levelRight">
                  <div className="levelBadge">
                    <p>Completed Level 3</p>
                    <HoverAnimation
                      disabled={badgesRecieved["Master"] ? "" : "disabled"}
                      path="../bodymovin/user/master.json"
                    />
                  </div>
                </div>
              </div>

              <div className="level">
                <div className="levelLeft">
                  <h3>Spine Level</h3>
                  <p>Complete the spine case studies to stand tall!</p>
                </div>

                <ul className="levelCenter">
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["gary"]} />
                    <p>The Case of the Gardener</p>
                  </li>
                  <li className="levelBar">
                    <ProgressBar percentDone={caseStudyProgresses["ben"]} />
                    <p>The Case of the Golfer</p>
                  </li>
                </ul>

                <div className="levelRight">
                  <div className="levelBadge">
                    <p>Completed Spine Levels</p>
                    <HoverAnimation
                      disabled={
                        badgesRecieved["StandingTall"] ? "" : "disabled"
                      }
                      path="../bodymovin/user/standingTall.json"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default connect(mapStateToProps)(User);
