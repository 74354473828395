import caseStudies from "../casestudies";

export const getPercentDone = (state, caseStudy) => {
  const totalExercises = caseStudies[caseStudy].exercises.length;
  const exercisesDone = Math.max(0, state.progress[caseStudy].solved - 1); // subtract 1 to disregard intro
  return (exercisesDone / totalExercises) * 100;
};

export const getCaseStudyProgresses = (state) => {
  const caseStudyNames = Object.keys(caseStudies);
  let caseStudyProgresses = {};
  for (const caseStudy of caseStudyNames) {
    caseStudyProgresses[caseStudy] = getPercentDone(state, caseStudy);
  }
  return caseStudyProgresses;
};

export const getCaseStudyDone = (state) => {
  const caseStudyNames = Object.keys(caseStudies);
  let caseStudyDone = {};
  for (const caseStudy of caseStudyNames)
    caseStudyDone[caseStudy] = getPercentDone(state, caseStudy) >= 100;
  return caseStudyDone;
};

export const getCaseStudyAvailability = (state) => {
  const levelsCompleted = getLevelsCompleted(state);
  const { legModule, spineModule } = levelsCompleted;
  return {
    maryem: legModule.level2,
    alex: legModule.level1,
    fred: legModule.level1,
    bernie: true,
    van: true,
    jenny: true,

    // Spine
    gary: true,
    ben: spineModule.level1,
  };
};

const getLevelsCompleted = (state) => {
  const caseStudyDone = getCaseStudyDone(state);
  const { van, bernie, jenny, alex, fred, maryem, gary, ben } = caseStudyDone;
  return {
    legModule: {
      level1: van && bernie && jenny,
      level2: alex && fred,
      level3: maryem,
    },
    spineModule: {
      level1: gary,
      level2: ben,
    },
  };
};

export const getBadgesRecieved = (state) => {
  const levelsCompleted = getLevelsCompleted(state);
  const caseStudyDone = getCaseStudyDone(state);

  const { van, bernie, jenny } = caseStudyDone;
  const { legModule, spineModule } = levelsCompleted;

  return {
    Rookie: van || bernie || jenny,
    Novice: legModule.level1,
    Expert: legModule.level2,
    Master: legModule.level3,
    StandingTall: spineModule.level1 && spineModule.level2,
  };
};

export const getHighestBadge = (state) => {
  const badgesRecieved = getBadgesRecieved(state);
  const badgeNames = ["Rookie", "Novice", "Expert", "Master"];
  let highestBadge = "Newbie";
  for (const badge of badgeNames) {
    if (badgesRecieved[badge]) {
      highestBadge = badge;
    }
  }
  return highestBadge;
};
