import React, {Component} from 'react';
import * as THREE from 'three';
import {Redirect} from 'react-router-dom';

var OrbitControls = require('three-orbit-controls')(THREE);
var OBJLoader = require('three-obj-loader');
var MeshLine = require( 'three.meshline' );

OBJLoader(THREE);

function getXFromRadian(radian, length){
    return Math.cos(radian)*length;
}

function getYFromRadian(radian, length){
    return Math.sin(radian)*length;
}

class Model extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            loadingProgress: 0,
        };
        this.objects = this.props.objects;
        this.animate = this.animate.bind(this);
        this.startMainScene = this.startMainScene.bind(this);
        this.startBoxOrientation = this.startBoxOrientation.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.updateRenderer = this.updateRenderer.bind(this);
        this.triggerCallbackFromMouse = this.triggerCallbackFromMouse.bind(this);
    }

    componentDidMount(){
        this.startMainScene();
        this.startBoxOrientation();

    }

    startMainScene(){
        this.THREE = THREE;
        const width = 548;
        const height = 450;
        this.xOffset = this.props.xOffset;
        this.yOffset = this.props.yOffset;
        this.zOffset = this.props.zOffset;
        this.currentControl = null;

        //Initialize Scene
        this.scene = new THREE.Scene();

        //Initialize Camera
        this.camera = new THREE.PerspectiveCamera(
            30,
            width / height,
            0.1,
            100000,
        );
        this.camera.position.z = getXFromRadian(this.props.zRotOffset, this.props.cameraDistance);
        this.camera.position.x = getYFromRadian(this.props.zRotOffset, this.props.cameraDistance);

        this.controls = new OrbitControls(this.camera);
        this.controls.enable = false;

        //Initialize Renderer
        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, premultipliedAlpha: true});
        this.renderer.setClearColor('#FFFFFF', 1);
        this.renderer.setSize(width, height);
        this.mount.appendChild(this.renderer.domElement);

        //Configure Lighting
        const light = new THREE.AmbientLight( 0xFFEEDD, 0.8 ); // soft white light
        this.scene.add( light );
		var pointLight = new THREE.PointLight( 0xFFFFFF, 0.3, 0 );
		pointLight.position.set( 100, 100, 100 );
		this.scene.add( pointLight );
        var pointLight2 = new THREE.PointLight( 0xFFFFFF, 0.3, 0 );
		pointLight2.position.set( -100, -100, -100 )
		this.scene.add( pointLight2 );

        //Configure Loading Manager
        this.manager = new THREE.LoadingManager();
        this.manager.onLoad = () => {
            if (!this.frameId)
                this.frameId = requestAnimationFrame(this.animate)
            this.setState({isLoading: false})
        };
        this.manager.onProgress = ( url, itemsLoaded, itemsTotal ) => {
            const progressPercentage = itemsLoaded / itemsTotal;
            this.setState({loadingProgress: progressPercentage});
        };
        this.objLoaders = [];
        this.models = {};
        this.shapes = [];

        for (const object of Object.values(this.props.objects)){
            let objLoader = new this.THREE.OBJLoader(this.manager);

            objLoader.load(
                '/'+object.url,
                (obj) => {
                    obj.position.x = this.xOffset;
                    obj.position.y = this.yOffset;
                    obj.position.z = this.zOffset;
                    if(!obj.children[0]) return;
                    if(obj.children[0].material instanceof Array){
                        for(let i = 0 ; i < obj.children[0].material.length ; i++){
                             obj.children[0].material[i].color.setHex(object.color[i] || object.color[0]);
                        }
                    } else {
                        obj.children[0].material.color.setHex(object.color);
                    }
                    if (object.visible === false)
                        obj.visible = false
                    obj.children[0].callback = object.callback;
                    obj.children[0].hovercallback = object.callback;
                    this.models[object.url] = (obj);
                    this.scene.add(obj);
                },
                (xhr) => {},
                (error) => {},
            );
        }

    }

    startBoxOrientation(){
        const width = 100;
        const height = 100;

        //Initialize Scene
        this.boxScene = new THREE.Scene();

        //Initialize Camera
        this.boxCamera = new THREE.PerspectiveCamera(
            30,
            width / height,
            0.1,
            100000,
        );
        this.boxCamera.position.z = 4;

        //Configure Lighting
        const light = new THREE.AmbientLight( 0xFFFFFF, 0.95 ); // soft white light
        this.boxScene.add( light );
        let pointLight = new THREE.PointLight( 0xFF0000, 1, 100 );
        pointLight.position.set( 50, 50, 50 );
        this.boxScene.add( pointLight );
        let pointLight2 = new THREE.PointLight( 0xFFFFFF, 0.1, 0 );
        pointLight2.position.set( -4, -4, -4 )
        this.boxScene.add( pointLight2 );

        //Initialize Renderer
        this.boxRenderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this.boxRenderer.setClearColor('#000000', 0);
        this.boxRenderer.setSize(width, height);
        this.boxMount.appendChild(this.boxRenderer.domElement);

        //ADD CUBE
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        var edges = new THREE.EdgesGeometry( geometry);
        this.lines = new THREE.LineSegments( edges, new THREE.LineBasicMaterial({ color: 0x000000 }));
        var textureLoader = new THREE.TextureLoader();
        var materials = [
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.frontCubeFaceSrc)}),
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.backCubeFaceSrc)}),
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.rightCubeFaceSrc)}),
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.leftCubeFaceSrc)}),
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.topCubeFaceSrc)}),
            new THREE.MeshBasicMaterial({ map:textureLoader.load('/'+this.props.bottomCubeFaceSrc)}),

        ]
        this.cube = new THREE.Mesh( geometry, materials )
        this.boxScene.add(this.cube);
        this.boxScene.add(this.lines);
    }

    componentWillUnmount(){
        cancelAnimationFrame(this.frameId)
        if(this.mount) this.mount.removeChild(this.renderer.domElement);
        if(this.boxMount) this.boxMount.removeChild(this.boxRenderer.domElement);
        this.controls.enabled = false;
    }

    animate() {
        if(this.cube !== null){
            this.cube.rotation.x = -this.controls.getPolarAngle();
            this.cube.rotation.z = -this.controls.getAzimuthalAngle() + this.props.zRotOffset - 0.5*Math.PI;
            this.lines.rotation.x = -this.controls.getPolarAngle();
            this.lines.rotation.z = -this.controls.getAzimuthalAngle() + this.props.zRotOffset - 0.5*Math.PI;
            this.renderer.render(this.scene, this.camera)
            this.boxRenderer.render(this.boxScene, this.boxCamera)
        }
        if(this.currentControl != null) this.currentControl();
        this.frameId = window.requestAnimationFrame(this.animate)
    }

    updateRenderer() {

        //update models
        for (const object of Object.values(this.props.objects)){
            let obj = this.models[object.url];
            if (!obj) return false;

            if(obj.children[0].material instanceof Array){
                for(let i = 0 ; i < obj.children[0].material.length ; i++){
                    if(object.color[i] !== undefined){
                        obj.children[0].material[i].color.setHex(object.color[i]);
                    } else {
                        obj.children[0].material[i].color.setHex(object.color[0]);
                    }
                }
            } else {
                obj.children[0].material.color.setHex(object.color);
            }
            obj.children[0].callback = object.callback;
            obj.children[0].hovercallback = object.hovercallback;
        }

        //delete drawn shapes
        for(let i = 0; i < this.shapes.length; i++){
            this.scene.remove(this.shapes[i]);
        }

        //draw new updated shapes
        for (const shape of Object.values(this.props.shapes)){
            if (shape.shape === 'line'){
                const geometry= new THREE.Geometry();
                geometry.vertices.push(new THREE.Vector3(
                    shape.x1 + this.xOffset,
                    shape.y1 + this.yOffset,
                    shape.z1 + this.zOffset,
                ));
                geometry.vertices.push(new THREE.Vector3(
                    shape.x2 + this.xOffset,
                    shape.y2 + this.yOffset,
                    shape.z2 + this.zOffset,
                ));
                const line = new MeshLine.MeshLine();
                line.setGeometry(geometry, function( p ) { return 0.15; } );
                const material = new MeshLine.MeshLineMaterial({
                    color: new THREE.Color(shape.color),
                });
                const mesh = new THREE.Mesh( line.geometry, material );
                this.shapes.push(mesh)
                this.scene.add(mesh);
            }
        }
        return true;

    }

    handleMouseOver(){
        this.controls.enabled = true;
    }

    handleMouseOut(){
        this.controls.enabled = false;
    }

    handleMouseUp(e){
        this.triggerCallbackFromMouse(e, 'callback');
    }

    handleMouseMove(e){
        this.triggerCallbackFromMouse(e, 'hovercallback');
    }

    triggerCallbackFromMouse(e, callbackName){
        const rect = this.mount.getBoundingClientRect();
        const relativeX = e.clientX - rect.x;
        const relativeY = e.clientY - rect.y;
        const mouseX = relativeX / rect.width * 2 - 1;
        const mouseY = - relativeY / rect.height * 2 + 1;

        let raycaster = new THREE.Raycaster();
        let mouse = new THREE.Vector2(mouseX, mouseY);
        raycaster.setFromCamera(mouse, this.camera);
        const intersects = raycaster.intersectObjects(this.scene.children, true);
        if(intersects.length > 0 && intersects[0].object && intersects[0].object[callbackName]){
            intersects[0].object[callbackName](relativeX, relativeY);
        } else if (this.props.noIntersectsCallback){
            this.props.noIntersectsCallback(relativeX, relativeY);
        }
    }

    renderLoadingOverlay(){
        return(
            <div className="loading" style={{position:'absolute'}}>
                <span className="loading__anim"></span>
                <div className="loading-title">
                    Loading: {Math.round(this.state.loadingProgress * 100)} 
                </div>
            </div>
            
        );
    }

    setCurrentControl(control){
        this.currentControl = control;
    }

    renderButtons(){
        return (
            <div className="div-btn-3d">
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d" 
                    title="Zoom in"
                    onMouseDown={()=>this.setCurrentControl(this.controls.zoomIn)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/zoom-in.png" alt="zoom in"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <div className="icon-3d"><img src="../img/icons/zoom.png" alt="zoom"></img></div>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-down" 
                    title="Zoom out"
                    onMouseDown={()=>this.setCurrentControl(this.controls.zoomOut)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/zoom-out.png" alt="zoom out"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d"
                    title="Pan up" 
                    onMouseDown={()=>this.setCurrentControl(this.controls.panDown)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/up.png" alt="up"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-left" 
                    title="Pan left"
                    onMouseDown={()=>this.setCurrentControl(this.controls.panRight)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/left.png" alt="left"></img>
                    </button>
                    <img src="../img/icons/pan.png" alt="pan"></img>
                    <button 
                    className="btn-3d-right" 
                    title="Pan right"
                    onMouseDown={()=>this.setCurrentControl(this.controls.panLeft)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/right.png" alt="right"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-down" 
                    title="Pan down"
                    onMouseDown={()=>this.setCurrentControl(this.controls.panUp)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/down.png" alt="down"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d" 
                    title="Rotate up"
                    onMouseDown={()=>this.setCurrentControl(this.controls.rotateDown)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/up.png" alt="up"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-left" 
                    title="Rotate left"
                    onMouseDown={()=>this.setCurrentControl(this.controls.rotateRight)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/left.png" alt="left"></img>
                    </button>
                    <img src="../img/icons/rotate.png" alt="rotate"></img>
                    <button 
                    className="btn-3d-right" 
                    title="Rotate right"
                    onMouseDown={()=>this.setCurrentControl(this.controls.rotateLeft)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/right.png" alt="right"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-down" 
                    title="Rotate down"
                    onMouseDown={()=>this.setCurrentControl(this.controls.rotateUp)} 
                    onMouseUp={()=>this.setCurrentControl(null)}
                    >
                        <img src="../img/icons/down.png" alt="down"></img>
                    </button>
                </div>
                <div className="btn-3d-container">
                    <button 
                    className="btn-3d-reset" 
                    title="Reset view"
                    onClick={()=>{this.controls.reset()}}>
                        Reset<br />view
                    </button>
                </div>
            </div>
        )
    }

    renderModels(){
        return (
            <div
            style={{position:'absolute'}}
            ref={(mount) => { this.mount = mount }}
            >
            </div>
        );
    }

    renderBoxOrientation(){
        return (
            <div
                style={{ position: 'absolute', right: '0px', top:'0px', width: '100px', height: '100px' }}
            >
                <div
                    ref={(mount) => { this.boxMount = mount }}
                >
                </div>
            </div>
        );
    }

    render(){

        if(!this.state.isLoading && !this.updateRenderer())
            return <Redirect to='/' />

        return(
            <div
                style={{
                    position: 'relative',
                    width: '548px',
                    height: '450px',
                }}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                onMouseUp={(e)=>this.handleMouseUp(e)}
                onMouseMove={(e)=>this.handleMouseMove(e)}
            >
                
                {this.renderModels()}
                {this.renderBoxOrientation()}
                {!this.state.isLoading && this.props.children}
                {!this.state.isLoading && this.renderButtons()}
                {this.state.isLoading && this.renderLoadingOverlay()}
            </div>
        );
     }
}

export default Model;
