export const SET_CASE_STUDY = 'SET_CASE_STUDY';
export const SET_PAGE = 'SET_PAGE';
export const GOTO_NEXT_PAGE = 'GOTO_NEXT_PAGE';
export const GOTO_PREV_PAGE = 'GOTO_PREV_PAGE';

export const setCaseStudy = (caseStudy, mode, reset) => ({
    type: SET_CASE_STUDY,
    caseStudy,
    mode,
    reset,
});

export const setPage = (page) => ({
    type: SET_PAGE,
    page,
});

export const gotoNextPage = () => ({
    type: GOTO_NEXT_PAGE,
});

export const gotoPrevPage = () => ({
    type: GOTO_PREV_PAGE,
});
