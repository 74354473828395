import React,{ Component} from 'react';

import Lottie from './Lottie';

class HoverAnimation extends Component{
     
  constructor(props) {
    super(props);


    this.state = {
        'stopped': true
    };

  }

  render() {
 
    const defaultOptions = {
        loop: true,
        autoplay: false, 
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
        path : this.props.path //'../bodymovin/bernie/bernie-ex3.json'
      };

    const className = this.props.disabled ? "disabled" : "";


    return (
            <div className={className} onMouseEnter={() => this.setState({'stopped':false})} onMouseLeave={() => this.setState({'stopped':true})}>
                <Lottie 
                    options={defaultOptions} 
                    isStopped={this.state.stopped} 
                />
            </div>
    );
  }
}

export default HoverAnimation;
