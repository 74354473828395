export const CORRECT_ANSWER = 'CORRECT_ANSWER';
export const INCORRECT_ANSWER = 'INCORRECT_ANSWER';
export const START_GUESSING = 'START_GUESSING';
export const UPDATE_SEL_COUNT = 'UPDATE_SEL_COUNT';
export const SHOW_TUTORIAL = 'SHOW_TUTORIAL';
export const HIDE_TUTORIAL = 'HIDE_TUTORIAL';
export const SHOW_SCENARIO_OVERLAY = 'SHOW_SCENARIO_OVERLAY';
export const HIDE_SCENARIO_OVERLAY = 'HIDE_SCENARIO_OVERLAY';
export const SHOW_REKINNECT = 'SHOW_REKINNECT';
export const HIDE_REKINNECT = 'HIDE_REKINNECT';
export const SET_CLEAR_FUNCTION = 'SET_CLEAR_FUNCTION';

export const correctAnswer = successMessage => ({
    type: CORRECT_ANSWER,
    successMessage,
});

export const incorrectAnswer = failMessage => ({
    type: INCORRECT_ANSWER,
    failMessage,
});

export const startGuessing = () => ({
    type: START_GUESSING,
})

export const updateSelCount = selCount => ({
    type: UPDATE_SEL_COUNT,
    selCount,
})

export const showTutorial = () => ({
    type: SHOW_TUTORIAL,
})

export const hideTutorial = () => ({
    type: HIDE_TUTORIAL,
})

export const showScenarioOverlay = () => ({
    type: SHOW_SCENARIO_OVERLAY,
})

export const hideScenarioOverlay = () => ({
    type: HIDE_SCENARIO_OVERLAY,
})

export const showRekinnect = () => ({
    type: SHOW_REKINNECT,
})

export const hideRekinnect = () => ({
    type: HIDE_REKINNECT,
})

export const setClearFunction = clearFunction => ({
    type: SET_CLEAR_FUNCTION,
    clearFunction,
})