import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const MaryemConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Compression neuropathy
              </h2>

              <p>
                Due to the tightness of Maryem’s cast, it compressed the common
                fibular nerve on the fibular head. Pinched nerve causes numbness
                and weakness of the muscles. In Maryem’s case, the common
                fibular nerve becomes the deep fibular nerve which supplies the
                dorsiflexors. Consequently she is unable to dorsiflex during the
                heel strike phase, resulting in a right foot drop. To compensate
                and clear her dropped foot, her right hip hikes in the swing
                phase. Due to her abnormal gait, Maryem has increasing the
                weightbearing load on her unaffected left leg. Over time, this
                leads to overuse and inflammation in the knee joint causing her
                left knee pain.
              </p>
              <p>
                <strong>From this case, you should know:</strong>
              </p>
              <ul>
                <li>
                  Injuries that occur over a period of time are complicated and
                  can result in major-life altering functional changes and
                  losses
                </li>
                <li>
                  Sometimes the management of an injury (in this case, the
                  application of a cast on a swollen limb) can actually{" "}
                  <i>cause</i> an injury while simultaneoulsy resolving another
                </li>
                <li>
                  Compressed nerves fail to send their motor information to the
                  target muscle. Over time, this can result in faulty mechanics,
                  weakness or even loss of movement of the target muscle
                </li>
                <li>
                  <i>Overcompensation</i> occurs when a person starts to develop
                  different motor patterns elsewhere to compensate for a change.
                  This often leads to over usage and injury
                </li>
              </ul>
            </div>

            <div id="concImg">
              <img
                src="../img/maryem/conc.svg"
                alt="diagram of Maryem's lower body"
              />
              <figcaption>
                Figure 1. Diagram of Maryem's compressed fibular nerve resulting
                in a drop foot. The right hip hikes up to compensate, thereby
                increasing weight bearing on the left knee
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Abnormal gait cycle (part 1)</b>
                          <br />
                          What part of Maryem’s gait cycle is abnormal in the
                          sagittal plane? (Watch the right leg!)
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/maryem/flowchart-1.png"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a correct">Heel strike</li>
                        <li className="flo-box6">Foot flat</li>
                        <li className="flo-box6">Toe off</li>
                        <li className="flo-box6">Pre-swing</li>
                        <li className="flo-box6">Mid-swing</li>
                        <li className="flo-box6">Terminal swing</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Normal gait cycle</b>
                          <br />
                          Based on a normal gait cycle, at which joint does the
                          heel strike occur?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a">
                          Hip
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Knee
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5 correct">
                          Talocrural
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Subtalar
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Talocalcaneo-
                          <br />
                          navicular
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Talocrural joint</b>
                          <br />
                          What action(s) does the talocrural joint allow?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">Dorsiflexion</li>
                        <li className="flo-box4 correct">Plantarflexion</li>
                        <li className="flo-box4">Inversion</li>
                        <li className="flo-box4">Eversion</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Abnormal heel strike</b>
                          <br />
                          In Maryem’s case, she is unable to do what action
                          during the heel strike?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/maryem/flowchart-1.png"
                            alt="Visual asset for Exercise 4"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">Dorsiflex</li>
                        <li className="flo-box4">Plantarflex</li>
                        <li className="flo-box4">Invert</li>
                        <li className="flo-box4">Evert</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 5" onClick={() => setPage(5)}>
                          <b>Exercise 5: Dorsiflexion muscles</b>
                          <br />
                          Yes, instead of dorsiflexing, Maryem is dropping her
                          right toe and dragging it abnormally during the heel
                          strike. So, what are the muscles responsible for
                          dorsiflexion?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box9a">Soleus</li>
                        <li className="flo-box9">FDL</li>
                        <li className="flo-box9">FHL</li>
                        <li className="flo-box9">Tib. post.</li>
                        <li className="flo-box9">FL</li>
                        <li className="flo-box9">FB</li>
                        <li className="flo-box9 correct">Tib. ant.</li>
                        <li className="flo-box9 correct">EHL</li>
                        <li className="flo-box9 correct">EDL</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 6" onClick={() => setPage(6)}>
                          <b>Exercise 6: Foot drop cause</b>
                          <br />
                          Which of the following is the most likely to cause
                          Maryem’s right foot drop?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a">
                          Muscle Stiffness (post-cast)
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4">
                          Ligament restriction
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4">Joint restriction</li>
                        <li className="flo-box4 correct">
                          Lack of innervation
                          <br />
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 7" onClick={() => setPage(7)}>
                          <b>Exercise 7: Denervation</b>
                          <br />
                          Considering Maryem is unable to dorsiflex, which
                          nerve(s) could be dysfunctional in Maryem’s right leg?
                          Think about the relationships between nerves and how
                          they connect to one another.
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">Common fib. n.</li>
                        <li className="flo-box5 correct">
                          Superficial fib. n.
                        </li>
                        <li className="flo-box5 correct">Deep fib. n.</li>
                        <li className="flo-box5">Tib. n.</li>
                        <li className="flo-box5">Sural n.</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 8" onClick={() => setPage(8)}>
                          <b>Exercise 8: Kinnect the dots</b>
                          <br />
                          Considering that the loss of innervation to the lower
                          leg could explain Maryem’s symptoms, what is a
                          possible explanation for how she damaged her nerve?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4ac">
                          Maryem tore the nerve during her skiing accident
                          <br />
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c correct">
                          The cast compressed the common fibular nerve on the
                          fibular head and damaged the nerve
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c">
                          From being confined to wheelchair, Maryem’s
                          dorsiflexors atrophied and subsequently caused the
                          superficial fibular nerve to atrophy as well
                        </li>
                        <li className="flo-box4c">
                          Compression from the cast decreased the blood supply
                          to the common fibular nerve, which injured the nerve
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 9" onClick={() => setPage(9)}>
                          <b>Exercise 9: Abnormal gait cycle (part 2)</b>
                          <br />
                          Great job! Maryem’s cast compressed her common fibular
                          nerve causing weakness of her dorsiflexors and
                          subsequent foot drop. The common fibular nerve’s
                          superficial position makes it vulnerable to injury.
                          But we haven’t fully analyzed Maryem’s limp. Now let’s
                          determine what part of Maryem’s gait cycle is abnormal
                          in the coronal plane.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/maryem/flowchart-9.png"
                            alt="Visual asset for Exercise 9"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a">Foot flat</li>
                        <li className="flo-box4">Toe strike</li>
                        <li className="flo-box4">Heel strike</li>
                        <li className="flo-box4 correct">Swing phase</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 10"
                          onClick={() => setPage(10)}
                        >
                          <b>Exercise 10: Swing phase</b>
                          <br />
                          Which joint is affected in the swing phase?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/maryem/flowchart-9.png"
                            alt="Visual asset for Exercise 10"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">
                          Hip
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Knee
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Talocrural
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">
                          Subtalar
                          <br />
                          <br />
                        </li>
                        <li className="flo-box5">Talocalcaneo-navicular</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 11"
                          onClick={() => setPage(11)}
                        >
                          <b>Exercise 11: Swing phase action</b>
                          <br />
                          More specifically, which of the following actions is
                          responsible for the abnormal swing phase in Maryem’s
                          gait?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/maryem/flowchart-9.png"
                            alt="Visual asset for Exercise 11"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box7a">Adduction</li>
                        <li className="flo-box7">Extension</li>
                        <li className="flo-box7">Flexion</li>
                        <li className="flo-box7 correct">Hip hike</li>
                        <li className="flo-box7">Hip drop</li>
                        <li className="flo-box7">External rotation</li>
                        <li className="flo-box7">Internal rotation</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-7a"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                      <div className="lh-7"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 12"
                          onClick={() => setPage(12)}
                        >
                          <b>Exercise 12: Hip hike joint(s)</b>
                          <br />
                          Across which joint(s) is the hip hike movement
                          produced? If you are having trouble, try this movement
                          out yourself!
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">Vertebral</li>
                        <li className="flo-box5">Left hip</li>
                        <li className="flo-box5">Right hip</li>
                        <li className="flo-box5">Left knee</li>
                        <li className="flo-box5">Right knee</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 13"
                          onClick={() => setPage(13)}
                        >
                          <b>Exercise 13: Vertebra to pelvis muscles</b>
                          <br />
                          Correct! Maryem’s hip hike involves lateral flexion of
                          the lumbar spine. So, what are muscles that connect
                          the vertebrae to the pelvis?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a">
                          Trapezius
                          <br />
                          <br />
                        </li>
                        <li className="flo-box6">
                          Rhomboids
                          <br />
                          <br />
                        </li>
                        <li className="flo-box6 correct">
                          Latissimus <br />
                          dorsi
                        </li>
                        <li className="flo-box6 correct">
                          Quadratus <br />
                          lumborum
                        </li>
                        <li className="flo-box6 correct">
                          Erector <br />
                          spinae
                        </li>
                        <li className="flo-box6">
                          Iliopsoas
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 14"
                          onClick={() => setPage(14)}
                        >
                          <b>Exercise 14: Hip hike muscle</b>
                          <br />
                          Considering the attachment points of two of the three
                          muscles you've identified in the previous exercise,
                          which one is more likely responsible for the hip hike?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a">Left latissimus dorsi</li>
                        <li className="flo-box4">Right latissimus dorsi</li>
                        <li className="flo-box4 correct">
                          Right quadratus lumborum
                        </li>
                        <li className="flo-box4">Left quadratus lumborum</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 15"
                          onClick={() => setPage(15)}
                        >
                          <b>Exercise 15: Kinnect the dots</b>
                          <br />
                          Considering Maryem’s injury and recovery, what is a
                          possible reason for her right hip hike?
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4ac correct">
                          Maryem’s right hip hikes to clear her right foot drop
                          in the swing phase
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c">
                          After being confined to a wheelchair, her right
                          hamstring has become weak and Maryem right hip hikes
                          to compensate
                          <br />
                          <br />
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c">
                          Maryem’s right tibia healed abnormally so that her
                          right leg is now longer than her left. Maryem’s right
                          hip hikes to compensate for leg length discrepancy
                          <br />
                          <br />
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c">
                          Maryem developed arthritis in her left hip after being
                          confined in a wheelchair. Consequently, she has
                          reduced range of movement during left swing phase
                          which causes an exaggeration of movement in the
                          opposite limb, shown as 'hip hiking'
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 16"
                          onClick={() => setPage(16)}
                        >
                          <b>Exercise 16: Knee structures causing pain</b>
                          <br />
                          Awesome deduction! Yes, Maryem’s right hip hikes to
                          compensate for her right foot drop in the swing phase.
                          Now we’ve accounted for all of Maryem’s symptoms
                          except her left knee pain. Consider how her knee pain
                          occured. Given this mechanism of injury, choose three
                          structures which will most likely cause the pain.
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box9a">Menisci</li>
                        <li className="flo-box9">ACL</li>
                        <li className="flo-box9">PCL</li>
                        <li className="flo-box9">LCL</li>
                        <li className="flo-box9">MCL</li>
                        <li className="flo-box9">Joint capsule</li>
                        <li className="flo-box9 correct">Bursa</li>
                        <li className="flo-box9 correct">Bony joint</li>
                        <li className="flo-box9 correct">Tendon</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-9a"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                      <div className="lh-9"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw9"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a
                          title="go to Exercise 17"
                          onClick={() => setPage(17)}
                        >
                          <b>Exercise 17: Kinnect the dots</b>
                          <br />
                          Considering Maryem’s injury and recovery, what is a
                          possible reason for her left knee pain? Remember the
                          left knee pain only manifests when Maryem walks, but
                          not when she is sitting or lying down.
                        </a>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4ac correct">
                          Due to her abnormal gait, Maryem has been relying on
                          her healthy left leg as compensation. Overtime, the
                          added load lead to overuse and inflammation in the
                          joint
                        </li>
                        <li className="flo-box4c">
                          Maryem also injured her left knee cartilage during the
                          skiing accident
                        </li>
                        <li className="flo-box4c">
                          Skiing is considered a high impact sport that can put
                          a lot of stress on the knee joint. Due to her intense
                          training schedule as a competitive skiier, Maryem has
                          developed anterior knee pain due to overuse
                        </li>
                        <li className="flo-box4c">
                          Stress on the knee joint from skiing all the time has
                          causesd degenerative arthritis in Maryem’s left knee.
                          Overuse has grinded down her knee cartilage and the
                          bone to bone contact is painful
                        </li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default MaryemConc;

