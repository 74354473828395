import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPage as setPageAction } from "../../actions/navigation";

const GaryConc = () => {
  const dispatch = useDispatch();
  const setPage = useCallback((page) => dispatch(setPageAction(page + 1)), [
    dispatch,
  ]);

  return (
    <main>
      <div id="content">
        <section>
          <div className="concGroup">
            <div id="concText">
              <h1>Conclusion:</h1>
              <h2>
                Case Closed: <br />
                Spinal Disc Herniation
              </h2>

              <p>
                Gary spends a lot of time in his job and leisure time performing
                repeated or sustained flexion. This repeated poor mechanics can
                damage the annulus gradually over time. When the annulus begins
                to tear or stretch, it can allow the nucleus to bulge out into
                the periphery of the disc whenever he bends/flexes.
              </p>
              <p>
                With flexion the bulging nucleus could go into the vertebral
                canal, but with the presence of the posterior longitudinal
                ligament in the way, it has no place to go other than into the
                space posterior and lateral…against the nerve root as it is
                leaving through the intervertebral foramen.
              </p>
              <p>
                Nerve root compression of this nature can cause pain that
                travels along the nerve path. There are predictable patterns of
                this <b>referred pain</b> clinically, and in this case since the
                compressed nerve root is a contributing portion of the sciatic
                nerve, the pain travels posteriorly down the leg. This pain
                sometimes is called “sciatica.”
              </p>
            </div>

            <div id="concImg">
              <img
                src="../img/gary/conc_1.png"
                alt="illustration of Gary gardening"
              />
              <figcaption>
                Figure 1. Repeated/sustained flexion in Gary’s gardening work.
              </figcaption>
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2>Case Study Recap</h2>

            <div className="flowchart">
              <ol>
                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 1" onClick={() => setPage(1)}>
                          <b>Exercise 1: Anatomy Overview</b>
                          <br />
                          Let’s review the pain Gary is experiencing, which is
                          down the back of his leg as shown in the animation.
                          Choose <b>three</b> possible structures which would
                          most likely cause the pain.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_1.png"
                            alt="Visual asset for Exercise 1"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think of the structures that are directly below the
                          area of the pain. What is located there?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw8"></div>
                      <div className="lh-8a"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box8a correct">Hamstrings</li>
                        <li className="flo-box8 correct">Gastrocnemius</li>
                        <li className="flo-box8 correct">Sciatic n.</li>
                        <li className="flo-box8">Quadriceps</li>
                        <li className="flo-box8">Gluteus maximus</li>
                        <li className="flo-box8">Adductor magnus</li>
                        <li className="flo-box8">Femoral n.</li>
                        <li className="flo-box8">Obturator n.</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-8a"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                      <div className="lh-8"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw8"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 2" onClick={() => setPage(2)}>
                          <b>Exercise 2: Movement</b>
                          <br />
                          What specific movement or position do all of the
                          previous aggravating activities have in common?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_2.png"
                            alt="Visual asset for Exercise 2"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Look closely at each of these aggravating moves. Is
                          the same movements (flexion or extension) occurring at
                          each joint?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box6a correct">Hip extension</li>
                        <li className="flo-box6 correct">Spinal flexion</li>
                        <li className="flo-box6">Kneee extension</li>
                        <li className="flo-box6">Knee flexion</li>
                        <li className="flo-box6">Hip flexion</li>
                        <li className="flo-box6">Spinal extension</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-6a"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                      <div className="lh-6"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw6"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 3" onClick={() => setPage(3)}>
                          <b>Exercise 3: Deduction</b>
                          <br />
                          Could the hamstring or gastrocnemius cause the problem
                          by either contracting or lengthening?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_3.png"
                            alt="Visual asset for Exercise 3"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Look carefully at ALL the positions or movements which
                          aggravate and relieve the pain.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw2"></div>
                      <div className="lh-2a"></div>
                      <div className="lh-2"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box2a">Yes</li>
                        <li className="flo-box2 correct">No</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-2a"></div>
                      <div className="lh-2"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw2"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 4" onClick={() => setPage(4)}>
                          <b>Exercise 4: Injury location</b>
                          <br />
                          Where is the nerve being compressed?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_4.png"
                            alt="Visual asset for Exercise 4"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          A nerve is compressed either between two muscles or
                          against a bony feature. Another structure might push
                          into it causing a 'pinch'.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4a correct">
                          Spinal foramen
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4 correct">
                          Intervertebral foramen
                        </li>
                        <li className="flo-box4">
                          Back of the knee
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4">
                          Back of the hip
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4a"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                      <div className="lh-4"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw4"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 5" onClick={() => setPage(5)}>
                          <b>Exercise 5: Anatomy mechanism</b>
                          <br />
                          During spinal flexion, can the nerve be compressed
                          within the intervertebral foramen?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_5.png"
                            alt="Visual asset for Exercise 5"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about the size of the intervertebral foramen
                          during flexion/extension.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw2"></div>
                      <div className="lh-2a"></div>
                      <div className="lh-2"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box2a correct">Yes</li>
                        <li className="flo-box2">No</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-2a"></div>
                      <div className="lh-2"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw2"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 6" onClick={() => setPage(6)}>
                          <b>Exercise 6: Anatomy mechanism</b>
                          <br />
                          The nerve is being compressed during spinal flexion
                          but it is not the bony aspect of the intervertebral
                          foramen itself that is pinching it. What could be
                          compressing the nerve?
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_6.png"
                            alt="Visual asset for Exercise 6"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Think about the size of the intervertebral foramen
                          during flexion/extension. Can it be pushing into the
                          nerve when flexing?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box5a correct">Disc</li>
                        <li className="flo-box5">Air</li>
                        <li className="flo-box5">Spinal cord</li>
                        <li className="flo-box5">Ligament</li>
                        <li className="flo-box5">Bone</li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-5a"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                      <div className="lh-5"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw5"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 7" onClick={() => setPage(7)}>
                          <b>Exercise 7: Injury mechanism</b>
                          <br />
                          Considering the movements that help relieve or
                          decrease the pain, choose the correct mechanism from
                          the list which explains how standing, walking and
                          lying down can relieve the pain.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_7.png"
                            alt="Visual asset for Exercise 7"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Review your knowledge of how the components making up
                          the disc change during flexion/extension.
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4ac">
                          Spinal flexion occurs and that causes the nucleus to
                          shift anteriorly
                        </li>
                        <li className="flo-box4c">
                          Spinal flexion occurs and that causes the nucleus to
                          shift posteriorly
                        </li>
                        <li className="flo-box4c correct">
                          Spinal extension occurs and that causes the nucleus to
                          shift anteriorly
                        </li>
                        <li className="flo-box4c">
                          Spinal extension occurs and that causes the nucleus to
                          shift posteriorly
                        </li>
                      </ul>
                    </div>

                    <div className="flo-row">
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh"></div>
                    </div>
                  </li>
                </section>

                <section>
                  <li>
                    <div className="flo-row-ex">
                      <div className="flo-box-ex tooltip">
                        <a title="go to Exercise 8" onClick={() => setPage(8)}>
                          <b>Exercise 8: Injury mechanism</b>
                          <br />
                          How did picking up the laundry basket cause this
                          injury? Choose the most likely cause.
                        </a>

                        <span className="tooltiptext">
                          <img
                            className="flo-img"
                            src="../../img/gary/ex_8.png"
                            alt="Visual asset for Exercise 8"
                          />
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row-hint">
                      <div className="flo-box-hint tooltip">
                        Hint
                        <span className="tooltiptext">
                          Would just one repetition of flexion cause the disc to
                          damage? What is more likely to have caused the injury
                          to Gary?
                        </span>
                      </div>
                      <div className="lh"></div>
                    </div>

                    <div className="flo-row">
                      <div className="lw7"></div>
                      <div className="lh-4ac"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                      <div className="lh-4c"></div>
                    </div>

                    <div className="flo-row">
                      <ul>
                        <li className="flo-box4ac">
                          It is a flexion activity and caused the disc to slip
                          <br />
                          <br />
                        </li>
                        <li className="flo-box4c correct">
                          It is a flexion activity but the disc was damaged over
                          time and this was the last straw
                        </li>
                        <li className="flo-box4c">
                          It is a flexion activity and the laundry was so heavy
                          it caused the disc to tear with effort
                        </li>
                        <li className="flo-box4c">
                          Spinal extension occurs and that causes the nucleus to
                          shift posteriorly
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>
                  </li>
                </section>
              </ol>
            </div>

            <Link
              to=""
              className="btn-ghost"
              href="home.html"
              title="Back to Home"
            >
              Back to home
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default GaryConc;
