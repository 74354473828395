import React, { Component } from 'react';
import DraggableChoice from '../../components/exercises/DraggableChoice';
import connectInteractive from './connectInteractive';
import SubmissionArea from '../exercises/SubmissionArea';

const SOL = [0, 1, 2];

/* Temp answer Cheque */
function CheckAnswer(guess, callback) {
    var correct = [];
    for (var i = 0; i < 3; i++) {
        if (SOL[i] === guess[i]) {
            correct.push(guess[i]);
        } else {
            correct.push(null);
        }
    }
    callback(correct);

}

class JennyEx1 extends Component {
    constructor(props) {
        super(props);

        this.options = ["Post. talofibular lig.", "Calcaneofibular lig.", "Ant. talofibular lig."];

        this.Clear = this.Clear.bind(this);
        this.SubmitAnswer = this.SubmitAnswer.bind(this);
        this.handleDragDrop = this.handleDragDrop.bind(this);
        this.handleDragMove = this.handleDragMove.bind(this);

        this.state = {
            'selected': [null, null, null],
            'hoveredMatchBox' : -1,
            'correct': [null, null, null],
            'event': 'guessing',
            'rekinnect': false,
            'failCount' : 0
        }
    }

    SubmitAnswer() {
        var choice = this.state.selected

        var count = this.state.selected.filter((val) => val === null).length;

        if (count === 0) {
            CheckAnswer(choice, (res) => {
                
                if (res.filter((val) => val !== null).length === 3) {
                    this.props.correctAnswer('That\'s correct! ✔')
                } else {
                    this.props.incorrectAnswer('Try again. ✕');
                }
            })
        }
    }

    MatchBox(ind){

        let selectedToRender;
        if (this.props.reviewMode) selectedToRender = SOL;
        else selectedToRender = this.state.selected;

        var choice = selectedToRender[ind]
        if (choice !== null){
            const elementId='match'+ind;
            return (
                <div className="matchBox" id={elementId} style={{position:'relative'}} > 
                    <DraggableChoice
                        ind={choice} 
                        match={ind}
                        onDrop={this.handleDragDrop}
                        onMove={this.handleDragMove}
                        containerId={elementId}
                        xOffset={0}
                        yOffset={0}
                        width='100%'
                    >
                        {this.options[choice]} 
                    </DraggableChoice>
                </div>
            );
        }
        else if (this.state.hoveredMatchBox === ind)
            return <div className="matchBox-hover" id={'match'+ind}> </div>
        else 
            return <div className="matchBox" id={'match'+ind}> </div>

    }

    /*Clear Selection*/
    Clear() {
        if (this.props.event !== 'solved') {
            /* Reset States */
            let selected = this.state.selected;
            let selCount = 3
            for(let i = 0; i < selected.length; i++){
                if(this.state.correct[i] == null){
                    selected[i] = null;
                    selCount -= 1
                }
            }
            this.props.updateSelCount(selCount)
            this.props.startGuessing();
            this.setState({ 'selected': selected, 'event': 'guessing' });
        }
    }

    FeedbackText() {
        if (this.props.event === 'failed') {
            return (this.state.correct.filter((val)=>val === null).length) + " of 3 answers is incorrect. Try again. ✕ "
        } else {
            return "That's correct! ✔"
        }
    }

    getMatchElements(){
        let matchElements = [];
        for(let i = 0; i < 3;i++){
            matchElements.push(document.getElementById('match'+i))
        }
        return matchElements;

    }

    handleDragDrop(choiceIndex, prevMatchIndex, x, y){
        const matchElements = this.getMatchElements();
        for(let matchIndex = 0 ; matchIndex < 3; matchIndex ++){
            const match = matchElements[matchIndex];
            const boundingRect = match.getBoundingClientRect();
            const inBetweenX = x > boundingRect.left && x < boundingRect.right;
            const inBetweenY = y > boundingRect.top && y < boundingRect.bottom;
            if(inBetweenX && inBetweenY){
                let arr = this.state.selected;
                const tmp = arr[matchIndex];
                arr[matchIndex] = choiceIndex;
                if (prevMatchIndex >= 0){
                    arr[prevMatchIndex] = tmp;
                }
                let count = arr.filter((val) => val !== null).length;
                this.props.updateSelCount(count);
                this.setState({selected: arr, hoveredMatchBox: -1})
                return
            }
        }
        let arr = this.state.selected;
        if (arr[prevMatchIndex] >= 0)
            arr[prevMatchIndex] = null

        let count = arr.filter((val) => val !== null).length;
        this.props.updateSelCount(count);
        this.setState({selected: arr, hoveredMatchBox: -1})
        
    }

    handleDragMove(_, x, y){
        const matchElements = this.getMatchElements();
        for(let matchIndex = 0 ; matchIndex < 3; matchIndex ++){
            const match = matchElements[matchIndex];
            const boundingRect = match.getBoundingClientRect();
            const inBetweenX = x > boundingRect.left && x < boundingRect.right;
            const inBetweenY = y > boundingRect.top && y < boundingRect.bottom;
            if(inBetweenX && inBetweenY){
                this.setState({hoveredMatchBox: matchIndex});
                return;
            }
        }
        this.setState({hoveredMatchBox: -1});
    }

    render() {
        const choices = this.options.map((val, ind) => {
            let selectedToRender;
            if (this.props.reviewMode) selectedToRender = SOL;
            else selectedToRender = this.state.selected;

            if (selectedToRender.indexOf(ind) === -1){
                const xOffset = 0;
                const yOffset = ind * 55;
                return (
                    <DraggableChoice 
                        ind={ind} 
                        match={-1}
                        key={ind} 
                        onDrop={this.handleDragDrop}
                        onMove={this.handleDragMove}
                        containerId='choice-dragcontainer'
                        xOffset={xOffset}
                        yOffset={yOffset}
                        width='30%'
                    >
                        {val}
                    </DraggableChoice>
                );
            }
            else
                return null;

        });
        
        return (
            <div>
                <div className="visual">
                    <div id="visualAssetMatch">
                        <div className="matchGroup">
                            <img className="matchImg" src="../../img/jenny/ex-1a.svg" alt="ankle inverted and plantarflexed" />
                            {this.MatchBox(2)}
                        </div>
                        <div className="matchGroup">
                            <img className="matchImg" src="../../img/jenny/ex-1b.svg" alt="ankle inverted and neutral" />
                            {this.MatchBox(1)}
                        </div>
                        <div className="matchGroup">
                            <img className="matchImg" src="../../img/jenny/ex-1c.svg" alt="ankle inverted and dorsiflexed" />
                            {this.MatchBox(0)}
                        </div>
                    </div>
                </div>
                

                <ul id="choicesMatch">
                    <div id='choice-dragcontainer' style={{position:'relative'}}>
                        {choices}
                    </div>
                </ul>

                <SubmissionArea
                    onSubmissionClick={this.SubmitAnswer} 
                    onClearClick={this.Clear}
                />
            </div>                  
        );
    }

}

export default connectInteractive(JennyEx1);
