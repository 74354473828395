export default {
    edl: {
        url: 'models/Jenny_Leg/ExtDigitorumLongus.obj',
        color: '0xe03e74',
        tooltip: 'ext. digitorum longus',
        isChoice: true
    },
    femur: {
        url: 'models/Jenny_Leg/Femur.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    fibula: {
        url: 'models/Jenny_Leg/Fibula.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    fibularisBrevis: {
        url: 'models/Jenny_Leg/FibularisBrevis.obj',
        color: '0xe03e74',
        tooltip: 'fibularis brevis',
        isChoice: true
    },
    fibularisLongus: {
        url: 'models/Jenny_Leg/FibularisLongus.obj',
        color: '0xe03e74',
        tooltip: 'fibularis longus',
        isChoice: true
    },
    foot: {
        url: 'models/Jenny_Leg/Foot.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    gastrocnemius: {
        url: 'models/Jenny_Leg/Gastrocnemius.obj',
        color: '0xe03e74',
        tooltip: 'gastrocnemius',
        isChoice: true
    },
    patella: {
        url: 'models/Jenny_Leg/Patella.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    soleus: {
        url: 'models/Jenny_Leg/Soleus.obj',
        color: '0xe03e74',
        tooltip: 'soleus',
        isChoice: true
    },
    tibia: {
        url: 'models/Jenny_Leg/Tibia.obj',
        color: '0xefe8dc',
        isChoice: false
    },
    tibialisAnterior: {
        url: 'models/Jenny_Leg/TibialisAnterior.obj',
        color: '0xe03e74',
        tooltip: 'tibialis anterior',
        isChoice: true
    },
};