export const EVENT_TYPES = {
    EVENT_GUESSING: "guessing",
    EVENT_FAILED: "failed",
    EVENT_SOLVED: "solved",
}

export const EXERCISE_TYPES = {
    CHECKBOX: 'checkbox',
    MULTIPLE_CHOICE: 'multiple_choice',
    DRAW_3D: 'draw_3d',
    CHOICE_3D: 'choice_3d',
}

export const VISUAL_ASSET_TYPES = {
    IMAGE: "image",
    VIDEO: "video",
    SLIDER: "slider"
}

